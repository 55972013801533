function BurgerIcon(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 12C21 12.5523 20.5523 13 20 13H4C3.44772 13 3 12.5523 3 12V12C3 11.4477 3.44772 11 4 11H20C20.5523 11 21 11.4477 21 12V12Z"
        fill="#FF9D25"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 7C21 7.55228 20.5523 8 20 8H4C3.44772 8 3 7.55228 3 7V7C3 6.44772 3.44772 6 4 6H20C20.5523 6 21 6.44772 21 7V7Z"
        fill="#FF9D25"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 17C21 17.5523 20.5523 18 20 18H4C3.44772 18 3 17.5523 3 17V17C3 16.4477 3.44772 16 4 16H20C20.5523 16 21 16.4477 21 17V17Z"
        fill="#FF9D25"
      />
    </svg>
  );
}
export default BurgerIcon;
