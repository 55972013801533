import Button from "../../../components/Button";
import Portal from "../../../components/Portal";

export default function CourseDetailExchangePointPopup({
  isShowing,
  hide,
  enroll,
  pointsToSpend,
}) {
  return isShowing ? (
    <Portal>
      <div
        onClick={hide}
        data-testid={"exchange-point-popup-backdrop"}
        className="fixed inset-0 z-50 bg-black bg-opacity-30 flex items-center justify-center"
      >
        <article
          onClick={(event) => event.stopPropagation()}
          className="w-72 p-5 bg-gradient-to-b from-orange-200 to-white rounded-xl flex flex-col items-center justify-center gap-4"
        >
          <button
            onClick={hide}
            data-testid="exchange-point-popup-close-button"
            className="p-1 rounded-md hover:bg-black hover:bg-opacity-10 relative left-28 text-gray-700"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              style={{ fill: "currentColor", transform: "", msfilter: "" }}
            >
              <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z" />
            </svg>
          </button>
          <svg
            width={160}
            height={160}
            viewBox="0 0 160 160"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask
              id="mask0_705_8666"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x={0}
              y={0}
              width={160}
              height={160}
            >
              <rect width={160} height={160} fill="#FF9D25" />
            </mask>
            <g mask="url(#mask0_705_8666)">
              <path
                d="M80.0001 -12.459L84.9074 52.7063L114.379 -5.62062L93.975 56.4622L143.524 13.8534L100.915 63.4022L162.998 42.9983L104.671 72.4698L169.836 77.3771L104.671 82.2844L162.998 111.756L100.915 91.352L143.524 140.901L93.975 98.292L114.379 160.375L84.9074 102.048L80.0001 167.213L75.0928 102.048L45.6214 160.375L66.0253 98.292L16.4764 140.901L59.0853 91.352L-2.99757 111.756L55.3294 82.2844L-9.83594 77.3771L55.3294 72.4698L-2.99757 42.9983L59.0853 63.4022L16.4764 13.8534L66.0253 56.4622L45.6214 -5.62062L75.0928 52.7063L80.0001 -12.459Z"
                fill="url(#paint0_radial_705_8666)"
              />
            </g>
            <path
              d="M71.4252 45.0686C87.9106 36.444 106.798 44.2255 113.603 62.4424C120.407 80.6592 112.556 102.415 96.0768 111.064C79.5981 119.713 60.7117 111.905 53.9055 93.6798C47.0992 75.4548 54.9563 53.6888 71.4252 45.0686Z"
              fill="#FFD75A"
            />
            <path
              d="M73.4522 50.4265C87.2517 43.2007 103.074 49.7132 108.77 64.981C114.467 80.2487 107.899 98.4673 94.0999 105.693C80.3004 112.919 64.4841 106.396 58.7795 91.1303C53.075 75.8647 59.6422 57.6462 73.4522 50.4265Z"
              fill="url(#paint1_linear_705_8666)"
            />
            <path
              d="M83.5765 40.648C76.9594 39.3815 70.1079 40.4354 64.1773 43.6321C47.692 52.2567 39.8431 74.0205 46.6554 92.2351C50.8216 103.406 59.5284 110.652 69.4253 112.595L76.6769 114.048C66.7674 112.09 58.0688 104.842 53.8987 93.6902C47.0925 75.4651 54.9375 53.7199 71.4185 45.0789C77.3531 41.8856 84.2057 40.8311 90.8259 42.0926L83.5765 40.648Z"
              fill="#FFB133"
            />
            <path
              d="M86.636 67.0397C86.2646 67.2416 85.8333 67.3041 85.4199 67.2159C85.0064 67.1278 84.6381 66.8947 84.3814 66.5589C83.3552 65.2638 77.7219 58.1645 77.7219 58.1645C76.3852 56.4675 74.0015 58.1382 74.2556 59.9753C74.4004 61.7006 74.8719 69.5832 75.0415 71.4342C75.2551 73.8114 71.8681 74.9306 69.9095 75.9494C68.7605 76.5659 65.878 77.8234 64.1901 78.9637C62.6963 79.9726 62.209 82.3347 64.5031 82.8314L72.659 84.5976C74.4813 84.8149 76.22 85.3457 76.3203 87.4975C76.4083 88.1883 76.927 98.7485 76.898 98.377C76.8917 98.7542 76.9983 99.1248 77.2041 99.4409C77.41 99.7571 77.7056 100.005 78.0532 100.151C78.4007 100.298 78.7842 100.338 79.1544 100.265C79.5246 100.192 79.8645 100.01 80.1305 99.7424C80.7718 98.7637 81.347 97.7432 81.8524 96.6879C82.965 95.5077 85.6277 87.0764 88.039 87.9121L97.9702 90.0494C99.7543 90.4533 101.26 88.0741 100.145 86.6441C99.8662 86.2954 91.4388 78.216 93.1257 75.2943L98.4711 65.1562C99.4644 63.2671 97.8044 61.154 96.0323 62.0787L86.636 67.0397Z"
              fill="#C54C17"
            />
            <path
              d="M87.428 67.4003C87.0549 67.6045 86.6208 67.6676 86.2049 67.5783C85.7891 67.4889 85.4193 67.2529 85.163 66.9135L78.5057 58.5274C77.169 56.8304 74.7831 58.4928 75.0312 60.3404C75.176 62.0657 75.6535 69.9379 75.8253 71.797C76.0389 74.1742 72.6519 75.2935 70.6911 76.304C69.5338 76.9228 66.6618 78.1863 64.9739 79.3266C63.4801 80.3354 62.9906 82.6893 65.2869 83.1942L73.4949 84.9906C75.3172 85.2079 77.0538 85.7305 77.1562 87.8905C77.2339 88.5753 77.7608 99.1333 77.734 98.77C77.7298 99.1461 77.8377 99.5149 78.0441 99.8293C78.2505 100.144 78.5459 100.389 78.8927 100.535C79.2394 100.681 79.6217 100.72 79.9907 100.647C80.3597 100.574 80.6987 100.393 80.9643 100.127C81.6128 99.1363 82.1934 98.1026 82.702 97.0331C83.8146 95.8529 86.469 87.4238 88.8864 88.2491L98.8198 90.3946C100.604 90.7986 102.108 88.4111 100.994 86.9893C100.716 86.6406 92.2885 78.5612 93.9671 75.6417L99.3207 65.5015C100.314 63.6124 98.6436 61.4932 96.882 62.4239L87.428 67.4003Z"
              fill="#FFD75A"
            />
            <path
              d="M101.049 53.5808C104.032 56.5294 106.327 60.1014 107.768 64.0408C113.456 79.2756 106.891 97.5375 93.0976 104.753C88.7305 107.138 83.7317 108.113 78.7884 107.542C73.845 106.971 69.1998 104.883 65.4912 101.565C69.1774 105.244 73.955 107.63 79.1099 108.368C84.2649 109.106 89.5202 108.156 94.0904 105.66C107.898 98.432 114.474 80.2112 108.761 64.9479C107.171 60.5785 104.522 56.6725 101.049 53.5808Z"
              fill="#C54C17"
            />
            <path
              d="M58.7695 91.1323C53.0738 75.8997 59.6405 57.646 73.4422 50.4285C76.6753 48.6696 80.2671 47.6714 83.9442 47.51C87.6212 47.3486 91.2867 48.0282 94.6615 49.497C91.2088 47.8509 87.4149 47.0472 83.5913 47.1518C79.7677 47.2563 76.0234 48.2662 72.6658 50.0985C58.8581 57.3264 52.2908 75.545 57.9954 90.8106C59.1865 94.2065 61.0495 97.3278 63.4732 99.9881C65.8969 102.648 68.8317 104.793 72.1025 106.294C65.859 103.162 61.0779 97.725 58.7695 91.1323Z"
              fill="white"
            />
            <defs>
              <radialGradient
                id="paint0_radial_705_8666"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(80.0001 77.3771) rotate(90) scale(71.2214)"
              >
                <stop stopColor="#FF9D25" stopOpacity="0.78" />
                <stop offset={1} stopColor="#FF9D25" stopOpacity={0} />
              </radialGradient>
              <linearGradient
                id="paint1_linear_705_8666"
                x1={-324868}
                y1={-163522}
                x2={-323268}
                y2={-160393}
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#FFB133" />
                <stop offset="0.31" stopColor="#FFBC4A" />
                <stop offset={1} stopColor="#FFB133" />
              </linearGradient>
            </defs>
          </svg>
          <h1 className="text-text text-xl text-center font-bold">
            Penukaran Poin
          </h1>
          <p className="text-center leading-5">
            Kamu akan menukar{" "}
            <span className="font-bold">{pointsToSpend} Poin</span> untuk
            mengikuti Course ini
          </p>
          <Button
            className="font-bold"
            onClick={() => {
              enroll();
              hide();
            }}
          >
            Tukar Poin
          </Button>
        </article>
      </div>
    </Portal>
  ) : (
    <></>
  );
}
