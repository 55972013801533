function HomeworkDetailTitle({children}) {
  return (
    <h1
      data-testid="homework-detail-title"
      className="py-4 text-2xl text-text font-bold "
    >
      {children}
    </h1>
  );
}
export default HomeworkDetailTitle;
