function SchedulesDayViewSkeleton(props) {
  return (
    <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 gap-4 overflow-visible animate-pulse">
      {Array(6)
        .fill(0)
        .map((x) => (
          <div className="h-28 p-4 rounded-md border flex justify-between gap-4">
            <div className="flex flex-1 flex-grow flex-col justify-between gap-2">
              <div className="flex flex-col gap-2">
                <div className="w-full h-4 bg-gray-100"></div>
                <div className="w-28 h-4 bg-gray-100"></div>
              </div>
              <div className="flex justify-between">
                <div className="w-14 h-3 bg-gray-100"></div>
                <div className="w-14 h-3 bg-gray-100"></div>
              </div>
            </div>
            <div className="w-20 h-20 flex-shrink-0 rounded-md bg-gray-100"></div>
          </div>
        ))}
    </div>
  );
}
export default SchedulesDayViewSkeleton;
