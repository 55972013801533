import { Navigate, useRoutes } from "react-router-dom";
import CourseDetail from "./screens/CourseDetail";
import Courses from "./screens/Courses";
import Dashboard from "./screens/Dashboard";
import HomeworkDetail from "./screens/HomeworkDetail";
import Homeworks from "./screens/Homeworks";
import Launchpad from "./screens/Launchpad";
import NotFound404 from "./screens/NotFound404";
import Survey from "./screens/Survey";
import SchedulesMonthView from "./screens/SchedulesMonthView";
import Forum from "./screens/Forum";
import Updates from "./screens/Updates";
import LaunchpadMissionDetail from "./screens/LaunchpadMissionDetail";
import ForumPost from "./screens/ForumPost";
import RequireAuth from "./components/RequireAuth";
import MyCourseDetail from "./screens/MyCourseDetail";
import SchedulesDayView from "./screens/SchedulesDayView";
import moment from "moment";
import SchedulesItemDetail from "./screens/SchedulesItemDetail";
import Logout from "./screens/Logout";
import LandingPageAboutProgram from "./screens/LandingPageAboutProgram";
import LandingPageMission from "./screens/LandingPageMission";
import LandingPageMain from "./screens/LandingPageMain";
import LandingPageLeaderboard from "./screens/LandingPageLeaderboard";
import LandingPageCourse from "./screens/LandingPageCourse";
import SurveyResults from "./screens/SurveyResults";

function AppRoutes() {
  return useRoutes([
    {
      path: "*",
      element: <NotFound404 />,
    },
    {
      path: "/",
      element: <LandingPageMain />,
    },
    {
      path: "/about",
      element: <LandingPageAboutProgram />,
    },
    {
      path: "/mission",
      element: <LandingPageMission />,
    },
    {
      path: "/about-courses",
      element: <LandingPageCourse />,
    },
    {
      path: "/leaderboard",
      element: <LandingPageLeaderboard />,
    },
    {
      path: "/logout",
      element: <Logout />,
    },
    {
      path: "/survey/results",
      element: <RequireAuth element={<SurveyResults />} />,
    },
    {
      path: "/survey/:step",
      element: <RequireAuth element={<Survey />} />,
    },
    {
      path: "/dashboard",
      element: <RequireAuth element={<Dashboard />} />,
    },
    {
      path: "/courses",
      element: <Navigate replace to="/courses/all" />,
    },
    {
      path: "/courses/all",
      element: <RequireAuth element={<Courses />} />,
    },
    {
      path: "/courses/:courseId",
      element: <RequireAuth element={<CourseDetail />} />,
    },
    {
      path: "/courses/me",
      element: <RequireAuth element={<Courses />} />,
    },
    {
      path: "/courses/me/:bloggerCourseId",
      element: <RequireAuth element={<MyCourseDetail />} />,
    },
    {
      path: "/courses/me/:bloggerCourseId/homeworks",
      element: <RequireAuth element={<Homeworks />} />,
    },
    {
      path: "/courses/me/:bloggerCourseId/homeworks/:bloggerCourseTaskId",
      element: <RequireAuth element={<HomeworkDetail />} />,
    },
    {
      path: "/launchpad",
      element: <RequireAuth element={<Launchpad />} />,
    },
    {
      path: "/launchpad/:bloggerLaunchpadId",
      element: (
        <RequireAuth
          element={<RequireAuth element={<LaunchpadMissionDetail />} />}
        />
      ),
    },
    {
      path: "/schedules",
      element: (
        <RequireAuth
          element={
            <Navigate
              replace
              to={`/schedules/${moment().format("YYYY")}/${moment().format(
                "MM"
              )}`}
            />
          }
        />
      ),
    },
    {
      path: "/schedules/:year/:month",
      element: <RequireAuth element={<SchedulesMonthView />} />,
    },
    {
      path: "/schedules/:year/:month/:day",
      element: <RequireAuth element={<SchedulesDayView />} />,
    },
    {
      path: "/schedules/details/:type/:scheduleId",
      element: <RequireAuth element={<SchedulesItemDetail />} />,
    },
    {
      path: "/forum",
      element: <RequireAuth element={<Forum />} />,
    },
    {
      path: "/forum/posts/:forumPostId",
      element: <RequireAuth element={<ForumPost />} />,
    },
    {
      path: "/updates",
      element: <RequireAuth element={<Updates />} />,
    },
  ]);
}
export default AppRoutes;
