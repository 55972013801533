import penakitaLaunchpadCardIllustration from "../../../assets/penakita-launchpad-card-illustration.png";
import penakitaLaunchpadCardBlob from "../../../assets/penakita-launchpad-card-blob.png";
import Button from "../../../components/Button";

function PenakitaLaunchpadCard({className = ""}) {
  return (
    <article
      className={`w-full h-44 bg-primary flex rounded-xl shadow-md overflow-clip ${className}`}
      style={{
        backgroundImage: `url(${penakitaLaunchpadCardBlob})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <div className="w-full flex justify-between">
        <div className="flex flex-col">
          <p className="mt-9 ml-4 text-white text-3xl font-bold">Penakita</p>
          <p className="ml-4 text-white text-base font-bold">Launchpad</p>
          <Button className="w-min mt-8 ml-4 py-1.5 px-6 border-white bg-white hover:bg-gray-100 text-primary text-sm">
            View
          </Button>
        </div>
        <img src={penakitaLaunchpadCardIllustration} alt="" className="h-32 flex-shrink-0 relative top-12" />
      </div>
    </article>
  );
}
export default PenakitaLaunchpadCard;
