import { Helmet } from "react-helmet";
import penakitaLogo from "../../assets/penakita-logo.png";
import ButtonLink from "../../components/ButtonLink";
import SurveyCourseCard from "../Survey/components/SurveyCourseCard";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { SurveyContext } from "../../contexts/SurveyContext";
import influencerImg from "../../assets/survey-illustration-jurnalistik.png"
import jurnalistikImg from "../../assets/survey-illustration-influencer.png"
import _ from "lodash";

function SurveyResults() {
  const { surveyIsError, surveyIsLoading, surveyIsCompleted, surveyResults } =
    useContext(SurveyContext);
  const navigate = useNavigate();
  const surveyResultProfile = _.orderBy(
    surveyResults,
    ["percentage"],
    ["desc"]
  )[0]?.title;

  useEffect(() => {
    if (!surveyIsCompleted) navigate("/survey/1", { replace: true });
  }, [surveyIsCompleted]);

  return (
    <>
      <Helmet>
        <title>Penakita Program Development</title>
      </Helmet>
      {!surveyIsLoading && !surveyIsError && surveyIsCompleted && (
        <article className="w-screen md:w-[720px] min-h-screen md:mx-auto md:my-6 md:rounded-md md:shadow-md bg-white flex flex-col">
          <header className="mb-4 p-4 flex items-center justify-end">
            <img
              src={penakitaLogo}
              alt="Penakita Logo"
              className="h-8 md:h-12"
            />
          </header>
          <div className="flex flex-col items-center justify-center">
            <p className="font-bold text-center">
              Dari hasil survey kami yakin kamu cocok dengan profil
            </p>
            <p className="mb-10 text-primary text-xl font-bold uppercase">
              {surveyResultProfile}
            </p>
            {surveyResultProfile == "Jurnalistik" && (
              <img
                src={jurnalistikImg}
                alt=""
                className="w-48 h-48 mb-10 object-cover"
              />
            )}
            {surveyResultProfile == "Influencer" && (
              <img
                src={influencerImg}
                alt=""
                className="w-48 h-48 mb-10 object-cover"
              />
            )}
            {surveyResultProfile == "Content Creator" && (
              <img
                src="https://picsum.photos/id/2/200/300"
                alt=""
                className="w-48 h-48 mb-10 object-cover"
              />
            )}
            {surveyResultProfile == "Blogger" && (
              <img
                src="https://picsum.photos/id/1/200/300"
                alt=""
                className="w-48 h-48 mb-10 object-cover"
              />
            )}
          </div>
          <p className="mb-6 text-sm font-bold text-center">
            Rekomendasi Course yang cocok untuk kamu
          </p>
          <div className="flex flex-wrap gap-4 items-center justify-center">
            {Array(3)
              .fill(0)
              .map((item) => (
                <SurveyCourseCard
                  data={{
                    courseId: 0,
                    title: "iusto quis iusto non atque et dolores",
                    description:
                      "Quaerat itaque modi repellendus. Voluptatem debitis porro in. Quia non voluptatem. Sequi et qui qui blanditiis rerum eius. Dignissimos ab excepturi voluptate vitae culpa. Sunt hic voluptas et.",
                    rating: 4.872579379176167,
                    followers: 8461908,
                    image: "https://picsum.photos/id/0/200/300",
                  }}
                />
              ))}
          </div>
          <section className="mt-4 p-4 bg-white border-t sticky bottom-0 flex items-center justify-end">
            <ButtonLink reloadDocument to={`/dashboard`} className="text-sm">
              Lewati
            </ButtonLink>
          </section>
        </article>
      )}
    </>
  );
}
export default SurveyResults;
