import CourseDetailHomeworkWidgetCard from "./CourseDetailHomeworkWidgetCard";

function CourseDetailHomeworkWidget({ tasks }) {
  return tasks ? (
    <article className="h-min p-4 bg-white rounded-lg shadow-md flex flex-col gap-2">
      <header className="flex items-center justify-between">
        <h3 className="text-lg text-gray-800 font-bold">Homework</h3>
      </header>
      <div className="flex flex-col gap-4">
        {tasks.map((task, i) => (
          <CourseDetailHomeworkWidgetCard key={i} task={task} />
        ))}
      </div>
    </article>
  ) : (
    <></>
  );
}
export default CourseDetailHomeworkWidget;
