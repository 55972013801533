import { useState } from "react";
import SearchBox from "../../../components/SearchBox";

function UpdatesHeader(props) {
  const [keyword, setKeyword] = useState("");
  const [searchBoxIsFocus, setSearchBoxIsFocus] = useState(false);

  return (
    <header className="mb-4 flex items-center justify-between">
      <h1
        className={`${
          searchBoxIsFocus ? "hidden md:block" : ""
        } text-2xl font-bold`}
      >
        Updates
      </h1>
    </header>
  );
}
export default UpdatesHeader;
