function StarIcon(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9456 9.5465C11.7729 9.71384 11.6936 9.95584 11.7329 10.1932L12.3256 13.4732C12.3756 13.7512 12.2583 14.0325 12.0256 14.1932C11.7976 14.3598 11.4943 14.3798 11.2456 14.2465L8.29292 12.7065C8.19026 12.6518 8.07626 12.6225 7.95959 12.6192H7.77892C7.71626 12.6285 7.65493 12.6485 7.59893 12.6792L4.64559 14.2265C4.49959 14.2998 4.33426 14.3258 4.17226 14.2998C3.77759 14.2252 3.51426 13.8492 3.57893 13.4525L4.17226 10.1725C4.21159 9.93317 4.13226 9.68984 3.95959 9.51984L1.55226 7.1865C1.35093 6.99117 1.28093 6.69784 1.37293 6.43317C1.46226 6.16917 1.69026 5.9765 1.96559 5.93317L5.27892 5.4525C5.53093 5.4265 5.75226 5.27317 5.86559 5.0465L7.32559 2.05317C7.36026 1.9865 7.40493 1.92517 7.45893 1.87317L7.51892 1.8265C7.55026 1.79184 7.58626 1.76317 7.62626 1.73984L7.69893 1.71317L7.81226 1.6665H8.09293C8.34359 1.6925 8.56426 1.8425 8.67959 2.0665L10.1589 5.0465C10.2656 5.2645 10.4729 5.41584 10.7123 5.4525L14.0256 5.93317C14.3056 5.97317 14.5396 6.1665 14.6323 6.43317C14.7196 6.7005 14.6443 6.99384 14.4389 7.1865L11.9456 9.5465Z"
        fill="#FF9D25"
      />
    </svg>
  );
}
export default StarIcon;
