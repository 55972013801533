import moment from "moment";
import emptyProfilePicture from "../../../assets/empty-profile-picture.svg";

function ForumPostUser({ post }) {
  return (
    <header className="mb-4 flex items-center justify-start gap-4">
      <img
        src={post.photoProfile ?? emptyProfilePicture}
        alt={`Profile picture of ${post.bloggerName}`}
        className="w-8 h-8 bg-disable overflow-hidden rounded-full flex-shrink-0"
      />
      <div className="w-full flex flex-col lg:flex-row lg:items-center lg:gap-2">
        <p className="text-sm font-bold line-clamp-1">{post.bloggerName}</p>
        <p className="text-disable text-xs">
          {moment(post.createdAt).fromNow()}
        </p>
      </div>
    </header>
  );
}
export default ForumPostUser;
