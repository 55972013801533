import { Link, useParams } from "react-router-dom";

export default function SchedulesItemDetailTodayScheduleWidgetCard({ webinar = false, event = { id: 1 } }) {
  return (
    <>
      <article className="p-4 rounded-md shadow-md bg-white">
        <p className="mb-4 text-sm font-semibold">
          <Link to={`/schedules/${useParams().year}/${useParams().month}/${useParams().day}/${event.id}`} className="hover:underline">Submission “Nama Homework Lorem Ipsum”</Link>
        </p>
        <footer className="flex flex-col lg:flex-row gap-1 lg:gap-8 text-xs text-disable">
          <div className="inline-flex gap-2.5">
            <svg
              width={16}
              height={16}
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.00065 2.33333C4.87598 2.33333 2.33398 4.87533 2.33398 8C2.33398 11.1247 4.87598 13.6667 8.00065 13.6667C11.1253 13.6667 13.6673 11.1247 13.6673 8C13.6673 4.87533 11.1253 2.33333 8.00065 2.33333ZM8.00065 14.6667C4.32465 14.6667 1.33398 11.676 1.33398 8C1.33398 4.324 4.32465 1.33333 8.00065 1.33333C11.6767 1.33333 14.6673 4.324 14.6673 8C14.6673 11.676 11.6767 14.6667 8.00065 14.6667Z"
                fill="#FF9D25" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.2887 10.4616C10.2014 10.4616 10.1134 10.4389 10.0327 10.3916L7.51939 8.89227C7.36872 8.8016 7.27539 8.63827 7.27539 8.46227V5.23027C7.27539 4.95427 7.49939 4.73027 7.77539 4.73027C8.05206 4.73027 8.27539 4.95427 8.27539 5.23027V8.17827L10.5454 9.5316C10.7821 9.6736 10.8601 9.98027 10.7187 10.2176C10.6247 10.3743 10.4587 10.4616 10.2887 10.4616Z"
                fill="#FF9D25" />
            </svg>
            {!webinar && <span>10.00</span>}
            {webinar && <span>15.00 - 17.00</span>}
          </div>
          {webinar && (
            <div className="inline-flex gap-2 5">
              <svg
                width={16}
                height={16}
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <mask
                  id="mask0_939_6616"
                  style={{ maskType: "alpha" }}
                  maskUnits="userSpaceOnUse"
                  x={2}
                  y={9}
                  width={12}
                  height={6}
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.66602 9.66408H13.2259V14.5801H2.66602V9.66408Z"
                    fill="white" />
                </mask>
                <g mask="url(#mask0_939_6616)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.94668 10.6641C5.10602 10.6641 3.66602 11.1521 3.66602 12.1154C3.66602 13.0874 5.10602 13.5801 7.94668 13.5801C10.7867 13.5801 12.226 13.0921 12.226 12.1287C12.226 11.1567 10.7867 10.6641 7.94668 10.6641ZM7.94668 14.5801C6.64068 14.5801 2.66602 14.5801 2.66602 12.1154C2.66602 9.91808 5.68002 9.66408 7.94668 9.66408C9.25268 9.66408 13.226 9.66408 13.226 12.1287C13.226 14.3261 10.2127 14.5801 7.94668 14.5801Z"
                    fill="#FF9D25" />
                </g>
                <mask
                  id="mask1_939_6616"
                  style={{ maskType: "alpha" }}
                  maskUnits="userSpaceOnUse"
                  x={4}
                  y={1}
                  width={8}
                  height={8}
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.40625 1.3334H11.4863V8.41247H4.40625V1.3334Z"
                    fill="white" />
                </mask>
                <g mask="url(#mask1_939_6616)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.94694 2.28515C6.5196 2.28515 5.35827 3.44581 5.35827 4.87315C5.3536 6.29581 6.50627 7.45581 7.9276 7.46114L7.94694 7.93715V7.46114C9.3736 7.46114 10.5343 6.29981 10.5343 4.87315C10.5343 3.44581 9.3736 2.28515 7.94694 2.28515ZM7.94694 8.41248H7.9256C5.9776 8.40648 4.3996 6.81781 4.40627 4.87115C4.40627 2.92115 5.99427 1.33315 7.94694 1.33315C9.89894 1.33315 11.4863 2.92115 11.4863 4.87315C11.4863 6.82515 9.89894 8.41248 7.94694 8.41248Z"
                    fill="#FF9D25" />
                </g>
              </svg>
              <span>59 Participant</span>
            </div>
          )}
        </footer>
      </article>
    </>
  );
}
