import { Link } from "react-router-dom";
import StarIcon from "../../../icons/StarIcon";

function SurveyCourseCard({ data }) {
  return (
    <Link
      to={`/courses/${data.courseId}`}
      data-testid={`popular-course-card-${data.courseId}`}
      className="w-44 h-48 hover:bg-gray-50 rounded-md overflow-hidden shadow-md aspect-auto"
    >
      <img
        src={data.image}
        alt={"Thumbnail " + data.title}
        className="h-24 w-full bg-gray-200 object-cover"
      ></img>
      <div className="h-24 p-3 flex flex-col justify-between">
        <p className="text-xs">{data.title}</p>
        <div className="flex items-center">
          <StarIcon />
          <span className="pl-1 text-xs">
            {new Intl.NumberFormat("id-ID", {
              notation: "compact",
              maximumFractionDigits: 1,
            }).format(data.rating.toFixed(1))}
          </span>
          <span className="ml-auto text-gray-500 text-2xs">
            {`Diikuti ${new Intl.NumberFormat("id-ID", {
              notation: "compact",
              maximumFractionDigits: 1,
            }).format(data.followers)} Orang`}{" "}
          </span>
        </div>
      </div>
    </Link>
  );
}
export default SurveyCourseCard;
