import React from "react";

export default function SurveyProgress({ step }) {
  return (
    <div className="p-4 md:px-16 flex items-center justify-between gap-3">
      <div
        className={`w-8 h-8 rounded-full inline-flex items-center justify-center font-bold ${
          Number(step) === 1 ? "bg-primary text-white" : ""
        } ${Number(step) > 1 ? "bg-success text-white" : ""} ${
          Number(step) < 1 ? "bg-soft text-disable" : ""
        }`}
      >
        1
      </div>
      <div className="flex-1 rounded overflow-hidden bg-soft">
        <div
          className={`py-0.5 bg-primary ${
            Number(step) >= 2 ? "w-full" : "w-0"
          } ${
            Number(step) > 2 ? "bg-success" : "bg-primary"
          } transition-all duration-150`}
        ></div>
      </div>
      <div
        className={`w-8 h-8 rounded-full inline-flex items-center justify-center font-bold ${
          Number(step) === 2 ? "bg-primary text-white" : ""
        } ${Number(step) > 2 ? "bg-success text-white" : ""} ${
          Number(step) < 2 ? "bg-soft text-disable" : ""
        }`}
      >
        2
      </div>
      <div className="flex-1 rounded overflow-hidden bg-soft">
        <div
          className={`py-0.5 ${Number(step) >= 3 ? "w-full" : "w-0"} ${
            Number(step) > 3 ? "bg-success" : "bg-primary"
          } transition-all duration-150`}
        ></div>
      </div>
      <div
        className={`w-8 h-8 rounded-full inline-flex items-center justify-center font-bold ${
          Number(step) === 3 ? "bg-primary text-white" : ""
        } ${Number(step) > 3 ? "bg-success text-white" : ""} ${
          Number(step) < 3 ? "bg-soft text-disable" : ""
        }`}
      >
        3
      </div>
      <div className="flex-1 rounded overflow-hidden bg-soft">
        <div
          className={`py-0.5 bg-primary ${
            Number(step) >= 4 ? "w-full" : "w-0"
          } ${
            Number(step) > 4 ? "bg-success" : "bg-primary"
          } transition-all duration-150`}
        ></div>
      </div>
      <div
        className={`w-8 h-8 rounded-full inline-flex items-center justify-center font-bold ${
          Number(step) === 4 ? "bg-primary text-white" : ""
        } ${Number(step) > 4 ? "bg-success text-white" : ""} ${
          Number(step) < 4 ? "bg-soft text-disable" : ""
        }`}
      >
        4
      </div>
    </div>
  );
}
