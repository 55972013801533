import { useState } from "react";

function SearchBox({
  keyword,
  searchBoxIsFocus,
  setKeyword,
  setSearchBoxIsFocus,
  labelClassName = "",
  inputClassName = "",
  ...restOfProps
}) {
  return (
    <label className={`px-3 pl-4 py-2 ml-auto rounded-3xl border inline-flex items-center ${labelClassName}`}>
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="7.84394"
          cy="7.84442"
          r="5.99237"
          stroke="#AEAEAE"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.0117 12.3235L14.3611 14.6667"
          stroke="#AEAEAE"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>

      <input
        type="search"
        onChange={(e) => setKeyword(e.target.value)}
        onFocus={() => setSearchBoxIsFocus(true)}
        onBlur={() => setSearchBoxIsFocus(false)}
        className={`w-0 sm:w-full focus:w-full ml-2 outline-none ${inputClassName}`}
        {...restOfProps}
      />
    </label>
  );
}
export default SearchBox;
