function SchedulesMonthViewSkeleton(props) {
  return (
    <div className="pb-24 lg:pb-0 grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4 animate-pulse">
      {Array(8)
        .fill(0)
        .map((x, i) => (
          <article key={i} className="p-3 border rounded-md flex flex-col">
            <div className="h-6 w-1/3 bg-gray-100 rounded-md"></div>
            <div className="h-32 my-4 flex flex-col gap-2">
              <div className="w-9 h-2.5 bg-gray-100 rounded-md"></div>
              <div className="w-full h-4 bg-gray-100 rounded-md"></div>
              <div className="w-2/3 h-4 bg-gray-100 rounded-md"></div>
              <div className="mt-1.5 w-9 h-2.5 bg-gray-100 rounded-md"></div>
              <div className="w-full h-4 bg-gray-100 rounded-md"></div>
              <div className="w-2/3 h-4 bg-gray-100 rounded-md"></div>
            </div>
            <footer className="h-4 mt-auto flex items-center justify-end">
              <div className="w-16 h-4 bg-gray-100 rounded-md"></div>
            </footer>
          </article>
        ))}
    </div>
  );
}
export default SchedulesMonthViewSkeleton;
