function UpdatesTrendingWidget(props) {
  return (
    <article className="p-4 rounded-md bg-white shadow-md">
      <header>
        <h2 className="mb-2 font-bold">Trending</h2>
      </header>
      <section className="flex flex-col gap-4">
        <article className="py-4 pr-4 rounded-md bg-white shadow-md flex items-center gap-4">
          <div className="py-0.5 px-3 rounded-r-md bg-primary text-white font-bold">
            1
          </div>
          <p className="text-sm line-clamp-2">
            Capai 90% Ruas Tol Telaga Asih Hingga Muara Bakti Ditargetkan Lorem ipsum dolor sit amet
          </p>
        </article>
      </section>
    </article>
  );
}
export default UpdatesTrendingWidget;
