import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { getCourses } from "../../../services/courseService";
import StarIcon from "../../../icons/StarIcon";

function LandingPageCoursePopularCoursesSkeleton() {
  return (
    <div
      data-testid="popular-course-section-skeleton"
      className="w-40 flex-shrink-0 h-48 animate-pulse rounded-md overflow-hidden border aspect-auto"
    >
      <div className="h-24 w-full bg-gray-200 object-cover"></div>
      <div className="h-24 p-3 flex flex-col justify-between">
        <div className="flex flex-wrap gap-1">
          <span className="w-full p-2 bg-gray-200"></span>
          <span className="w-1/4 p-2 bg-gray-200"></span>
        </div>
      </div>
    </div>
  );
}

function LandingPageCoursePopularCoursesCard({ data }) {
  return (
    <Link
      to={`/courses/${data.courseId}`}
      data-testid={`popular-course-card-${data.courseId}`}
      className="w-40 lg:w-auto h-48 bg-white first:ml-9 last:mr-9 first:lg:m-0 last:lg:m-0 snap-start flex-shrink-0 hover:bg-gray-50 rounded-md overflow-hidden shadow-md aspect-auto"
    >
        <img
          src={data.image}
          alt={"Thumbnail " + data.title}
          className="h-24 w-full bg-gray-200 object-cover"
        ></img>
      <div className="h-24 p-3 flex flex-col justify-between">
        <p className="text-xs line-clamp-3">{data.title}</p>
        <div className="flex items-center">
          <StarIcon />
          <span className="pl-1 text-xs">
            {new Intl.NumberFormat("id-ID", {
              notation: "compact",
              maximumFractionDigits: 1,
            }).format(data.rating.toFixed(1))}
          </span>
          <span className="ml-auto text-gray-500 text-2xs">
            {`Diikuti ${new Intl.NumberFormat("id-ID", {
              notation: "compact",
              maximumFractionDigits: 1,
            }).format(data.followers)} Orang`}{" "}
          </span>
        </div>
      </div>
    </Link>
  );
}

function LandingPageCoursePopularCourses(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [popularCourseData, setPopularCourseData] = useState([]);
  const scrollArea = useRef(null);

  useEffect(() => {
    getPopularCourseData();
  }, [, scrollArea]);

  const getPopularCourseData = async () => {
    try {
      setIsLoading(true);
      setPopularCourseData(
        (await getCourses({ sortBy: "Popular", limit: 4 })).courses
      );
    } catch (error) {
      console.log(error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <section className="">
        <div className="max-w-full md:max-w-lg lg:max-w-5xl mx-auto">
          <div className="mb-4 flex items-center justify-center font-sans-landing">
            <h2 className="mb-4 text-text text-3xl text-center font-bold">
              Popular Course
            </h2>
          </div>
          <div className="mb-4 flex font-sans-landing">
            {isLoading && (
              <div className="w-full h-56 p-1 mb-4 flex overflow-x-scroll no-scrollbar gap-4">
                {[0, 0, 0, 0].map((item) => (
                  <LandingPageCoursePopularCoursesSkeleton />
                ))}
              </div>
            )}
            {!isError && !isLoading && (
              <div className="w-full flex items-stretch">
                <div className="h-52 w-0 overflow-visible lg:hidden">
                  <div className="w-8 h-full flex items-center relative z-30 justify-center">
                    <button
                      onClick={() => {
                        scrollArea.current.scroll({
                          top: 0,
                          left: scrollArea.current.scrollLeft - 176,
                          behavior: "smooth",
                        });
                      }}
                      className="rounded-full shadow-md border bg-white hover:bg-gray-100 text-gray-700"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        style={{
                          fill: "currentColor",
                          transform: "",
                          msfilter: "",
                        }}
                      >
                        <path d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z" />
                      </svg>
                    </button>
                  </div>
                </div>
                <div
                  ref={scrollArea}
                  className="w-full h-56 p-1 mb-4 scroll-px-8 snap-x flex-grow flex overflow-x-scroll no-scrollbar gap-4 lg:grid lg:grid-cols-4"
                >
                  {popularCourseData.map((data, i) => (
                    <LandingPageCoursePopularCoursesCard key={i} data={data} />
                  ))}
                </div>
                <div className="h-52 w-0 overflow-visible lg:hidden flex justify-end">
                  <div className="w-8 p-1 h-full flex items-center relative z-30 justify-center">
                    <button
                      onClick={() => {
                        scrollArea.current.scroll({
                          top: 0,
                          left: scrollArea.current.scrollLeft + 176,
                          behavior: "smooth",
                        });
                      }}
                      className="rounded-full shadow-md border bg-white hover:bg-gray-100 text-gray-700"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        style={{
                          fill: "currentColor",
                          transform: "",
                          msfilter: "",
                        }}
                      >
                        <path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            )}
            {isError && (
              <div className="h-56 mx-auto w-full flex items-center justify-center">
                <p className="text-center text-sm text-red-800">
                  Gagal Memperoleh Informasi
                </p>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
}
export default LandingPageCoursePopularCourses;
