function PictureIcon() {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1156_7892"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x={2}
        y={2}
        width={20}
        height={20}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 2.00012H21.953V21.954H2V2.00012Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_1156_7892)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.651 3.5C5.13 3.5 3.5 5.229 3.5 7.904V16.05C3.5 18.726 5.13 20.454 7.651 20.454H16.297C18.822 20.454 20.453 18.726 20.453 16.05V7.904C20.455 6.541 20.039 5.403 19.251 4.614C18.523 3.885 17.504 3.5 16.302 3.5H7.651ZM16.297 21.954H7.651C4.271 21.954 2 19.581 2 16.05V7.904C2 4.373 4.271 2 7.651 2H16.302C17.91 2 19.297 2.537 20.312 3.554C21.373 4.616 21.955 6.161 21.953 7.905V16.05C21.953 19.581 19.68 21.954 16.297 21.954Z"
          fill="#FF9D25"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.85574 7.68958C8.25174 7.68958 7.76074 8.18058 7.76074 8.78558C7.76074 9.38958 8.25174 9.88058 8.85674 9.88058C9.46074 9.88058 9.95274 9.38958 9.95274 8.78658C9.95174 8.18158 9.45974 7.69058 8.85574 7.68958ZM8.85674 11.3806C7.42474 11.3806 6.26074 10.2166 6.26074 8.78558C6.26074 7.35358 7.42474 6.18958 8.85674 6.18958C10.2877 6.19058 11.4517 7.35458 11.4527 8.78458V8.78558C11.4527 10.2166 10.2887 11.3806 8.85674 11.3806Z"
        fill="#FF9D25"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.74893 20.1113C3.62493 20.1113 3.49893 20.0803 3.38293 20.0153C3.02093 19.8123 2.89393 19.3553 3.09593 18.9943C3.15593 18.8863 4.59093 16.3493 6.16993 15.0493C7.42193 14.0193 8.76993 14.5853 9.85593 15.0423C10.4949 15.3113 11.0989 15.5653 11.6789 15.5653C12.2109 15.5653 12.8779 14.6253 13.4679 13.7963C14.2869 12.6403 15.2169 11.3323 16.5789 11.3323C18.7489 11.3323 20.6219 13.2683 21.6289 14.3083L21.7449 14.4283C22.0329 14.7253 22.0259 15.2003 21.7289 15.4893C21.4339 15.7783 20.9589 15.7713 20.6689 15.4733L20.5509 15.3513C19.6989 14.4703 18.1129 12.8323 16.5789 12.8323C15.9909 12.8323 15.3009 13.8053 14.6899 14.6643C13.8519 15.8443 12.9849 17.0653 11.6789 17.0653C10.7959 17.0653 9.98693 16.7253 9.27393 16.4243C8.13993 15.9463 7.62593 15.7933 7.12293 16.2073C5.75893 17.3313 4.41693 19.7053 4.40393 19.7283C4.26693 19.9733 4.01193 20.1113 3.74893 20.1113Z"
        fill="#FF9D25"
      />
    </svg>
  );
}

export default PictureIcon;
