function CourseDetailObjective({courseObjective}) {
  return (
    <div className="mb-4">
      <h2 className="mb-2 text-primary font-bold">Objective</h2>
      <article className="prose prose-neutral max-w-none leading-5">
        {courseObjective}
      </article>
    </div>
  );
}
export default CourseDetailObjective;
