import { Link } from "react-router-dom";

function ForumTermsAndConditionsWidget(props) {
  return (
    <article className="p-4 rounded-md shadow-md bg-white">
      <header>
        <h2 className="font-bold">Terms and Conditions</h2>
      </header>
      <section className="mt-2 flex flex-col gap-4">
        <article className="p-4 rounded-md shadow-md bg-white">
          <h3 className="text-sm font-bold">Penakita Admin</h3>
          <article className="mt-1 text-xs text-gray-600">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Vestibulum sed finibus purus. Sed ut porta mi. In aliquam, leo in
              maximus fringilla, turpis nisi laoreet erat, a sagittis ante nisl
              sit amet purus.
            </p>
          </article>
        </article>
      </section>
    </article>
  );
}
export default ForumTermsAndConditionsWidget;
