import moment from "moment";
import { Link } from "react-router-dom";

export function SchedulesMonthViewListCardEventItem({ item }) {
  return (
    <article>
      <div className="text-gray-400 text-2xs">
        {item.startTime && item.endTime ? (
          <>{`${item.startTime} - ${item.endTime}`}</>
        ) : (
          <>{moment(item.dueDate).format("hh.mm")}</>
        )}
      </div>
      <p className="text-xs line-clamp-2">
        <Link
          to={`/schedules/details/${item.type}/${item.scheduleId}`}
          className="hover:underline"
        >
          {item.title}
        </Link>
      </p>
    </article>
  );
}

function SchedulesMonthViewListCard({ scheduleItemData }) {
  return (
    <>
      <article className="p-3 shadow-md rounded-md flex flex-col">
        <h4 className="font-bold">
          <Link
            to={moment(scheduleItemData.key).format("DD")}
            className="hover:underline"
          >
            {moment(scheduleItemData.key).format("DD MMM")}
          </Link>
        </h4>
        <section className="h-32 my-2 flex flex-col gap-4">
          {scheduleItemData.children.map((item) => (
            <SchedulesMonthViewListCardEventItem item={item} />
          ))}
        </section>
        <footer className="h-4 mt-auto flex items-center justify-end">
          {scheduleItemData.more && (
            <Link
              to={moment(scheduleItemData.key).format("DD")}
              className="text-primary text-xs hover:underline"
            >
              {scheduleItemData.more ?? "more schedule"}
            </Link>
          )}
        </footer>
      </article>
    </>
  );
}
export default SchedulesMonthViewListCard;
