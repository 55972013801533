function ForumPostSkeleton(props) {
  return (
    <div className="flex flex-col animate-pulse">
      <div className="mt-2 w-48 h-4 mb-4 bg-gray-100"></div>
      <div className="w-36 h-6 my-4 bg-gray-100"></div>
      <div className="mb-4 flex items-center justify-start gap-4">
        <div className="w-8 h-8 rounded-full bg-gray-100 flex-shrink-0"></div>
        <div className="w-full flex flex-col">
          <div className="w-32 h-3 bg-gray-100"></div>
        </div>
      </div>
      <div className="mb-4 flex items-center gap-2 overflow-hidden">
        <div className="w-20 h-20 bg-gray-100 flex-shrink-0 rounded-md"></div>
        <div className="w-20 h-20 bg-gray-100 flex-shrink-0 rounded-md"></div>
        <div className="w-20 h-20 bg-gray-100 flex-shrink-0 rounded-md"></div>
        <div className="w-20 h-20 bg-gray-100 flex-shrink-0 rounded-md"></div>
        <div className="w-20 h-20 bg-gray-100 flex-shrink-0 rounded-md"></div>
      </div>
      <div className="mb-4">
        <div className="w-full h-4 mb-2 bg-gray-100"></div>
        <div className="w-full h-4 mb-2 bg-gray-100"></div>
        <div className="w-full h-4 mb-2 bg-gray-100"></div>
        <div className="w-full h-4 mb-2 bg-gray-100"></div>
      </div>
      <div className="w-[calc(100%+40px)] mt-4 p-4 border-t relative -left-5">
        <div className="h-32 p-4 rounded-md bg-gray-100 "></div>
      </div>
      <div className="mb-4 flex items-center justify-between">
        <div className="w-24 h-7 rounded-md bg-gray-100"></div>
      </div>
      <div className="">
        <div className="mb-4 flex items-center justify-start gap-4">
          <div className="w-8 h-8 rounded-full bg-gray-100 flex-shrink-0"></div>
          <div className="w-full flex flex-col">
            <div className="w-64 h-3 bg-gray-100"></div>
            <div className="w-16 h-3 mt-1.5 bg-gray-100"></div>
          </div>
        </div>
        <div className="mb-4">
          <div className="w-full h-4 mb-2 bg-gray-100"></div>
          <div className="w-full h-4 mb-2 bg-gray-100"></div>
        </div>
      </div>
    </div>
  );
}
export default ForumPostSkeleton;
