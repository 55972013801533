import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Badge from "../../../components/Badge";

const leadingZero = (num, places) => String(num).padStart(places, "0");

function HomeworksListCard({ task }) {
  const deadline = moment().add(task.duration, "s");
  const [diffHour, setDiffHour] = useState(deadline.diff(moment(), "h"));
  const [diffMinute, setDiffMinute] = useState(
    deadline.diff(moment(), "m") - deadline.diff(moment(), "h") * 60
  );
  const [diffSecond, setDiffSecond] = useState(
    deadline.diff(moment(), "s") - deadline.diff(moment(), "m") * 60
  );

  const statusTexts = {
    waiting: "Waiting",
    new: "New",
    ongoing: "Ongoing",
    onprogress: "Ongoing",
    done: "Done",
  };

  const statusStyles = {
    waiting: "badge-primary",
    new: "badge-primary",
    ongoing: "badge-primary",
    onprogress: "badge-primary",
    done: "badge-success",
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setDiffHour(deadline.diff(moment(), "h"));
      setDiffMinute(
        deadline.diff(moment(), "m") - deadline.diff(moment(), "h") * 60
      );
      setDiffSecond(
        deadline.diff(moment(), "s") - deadline.diff(moment(), "m") * 60
      );
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  return (
    <article
      className={`h-[130px] p-4 bg-white shadow-md rounded-md flex justify-between`}
    >
      <div className="flex flex-col justify-between">
        <h4 className="text-text text-xs font-bold leading-4 line-clamp-2">
          <Link to={`${task.bloggerCourseTaskId}`} className="hover:underline">
            {task.title}
          </Link>
        </h4>
        <p className="text-gray-600 text-xs">
          Deadline{" "}
          <span className="pl-2 text-error font-bold">
            <time dateTime={deadline.toISOString()}>
              {leadingZero(diffHour, 2)} : {leadingZero(diffMinute, 2)} :{" "}
              {leadingZero(diffSecond, 2)}
            </time>
          </span>
        </p>
        {task.status && (
          <Badge className={statusStyles[task.status]}>
            {statusTexts[task.status]}
          </Badge>
        )}
      </div>
      <div className="flex flex-col items-end justify-between">
        <svg
          width={16}
          height={16}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.00065 2.33325C4.87598 2.33325 2.33398 4.87525 2.33398 7.99992C2.33398 11.1246 4.87598 13.6666 8.00065 13.6666C11.1253 13.6666 13.6673 11.1246 13.6673 7.99992C13.6673 4.87525 11.1253 2.33325 8.00065 2.33325ZM8.00065 14.6666C4.32465 14.6666 1.33398 11.6759 1.33398 7.99992C1.33398 4.32392 4.32465 1.33325 8.00065 1.33325C11.6767 1.33325 14.6673 4.32392 14.6673 7.99992C14.6673 11.6759 11.6767 14.6666 8.00065 14.6666Z"
            fill="#FF9D25"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.99609 8.91524C7.72009 8.91524 7.49609 8.69124 7.49609 8.41524V5.46924C7.49609 5.19324 7.72009 4.96924 7.99609 4.96924C8.27209 4.96924 8.49609 5.19324 8.49609 5.46924V8.41524C8.49609 8.69124 8.27209 8.91524 7.99609 8.91524Z"
            fill="#FF9D25"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.00398 11.1971C7.63532 11.1971 7.33398 10.8991 7.33398 10.5304C7.33398 10.1618 7.62932 9.86377 7.99732 9.86377H8.00398C8.37265 9.86377 8.67065 10.1618 8.67065 10.5304C8.67065 10.8991 8.37265 11.1971 8.00398 11.1971Z"
            fill="#FF9D25"
          />
        </svg>
        <button
          data-testid="edit-evidence-button"
          className="p-1 rounded-md hover:bg-black hover:bg-opacity-5"
        >
          <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.577 22.3686H7.753C4.312 22.3686 2 19.9536 2 16.3596V8.04562C2 4.45162 4.312 2.03662 7.753 2.03662H11.492C11.906 2.03662 12.242 2.37262 12.242 2.78662C12.242 3.20062 11.906 3.53662 11.492 3.53662H7.753C5.169 3.53662 3.5 5.30662 3.5 8.04562V16.3596C3.5 19.0986 5.169 20.8686 7.753 20.8686H16.577C19.161 20.8686 20.831 19.0986 20.831 16.3596V12.3316C20.831 11.9176 21.167 11.5816 21.581 11.5816C21.995 11.5816 22.331 11.9176 22.331 12.3316V16.3596C22.331 19.9536 20.018 22.3686 16.577 22.3686Z"
              fill="#8D8D8D"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.86689 15.4282H11.8439C12.2239 15.4282 12.5799 15.2812 12.8489 15.0122L20.3579 7.50324C20.6659 7.19524 20.8359 6.78524 20.8359 6.34924C20.8359 5.91224 20.6659 5.50124 20.3579 5.19324L19.1409 3.97624C18.5039 3.34124 17.4679 3.34124 16.8299 3.97624L9.35689 11.4492C9.09789 11.7082 8.95089 12.0522 8.94189 12.4172L8.86689 15.4282ZM11.8439 16.9282H8.09789C7.89589 16.9282 7.70189 16.8462 7.56089 16.7012C7.41989 16.5572 7.34289 16.3622 7.34789 16.1592L7.44189 12.3802C7.46089 11.6282 7.76389 10.9212 8.29589 10.3882H8.29689L15.7699 2.91524C16.9919 1.69524 18.9789 1.69524 20.2009 2.91524L21.4179 4.13224C22.0109 4.72424 22.3369 5.51124 22.3359 6.34924C22.3359 7.18724 22.0099 7.97324 21.4179 8.56424L13.9089 16.0732C13.3579 16.6242 12.6239 16.9282 11.8439 16.9282Z"
              fill="#8D8D8D"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M19.7318 9.9166C19.5398 9.9166 19.3478 9.8436 19.2018 9.6966L14.6358 5.1306C14.3428 4.8376 14.3428 4.3626 14.6358 4.0696C14.9288 3.7766 15.4028 3.7766 15.6958 4.0696L20.2618 8.6366C20.5548 8.9296 20.5548 9.4036 20.2618 9.6966C20.1158 9.8436 19.9238 9.9166 19.7318 9.9166Z"
              fill="#8D8D8D"
            />
          </svg>
        </button>
      </div>
    </article>
  );
}
export default HomeworksListCard;
