import { Link, useLocation } from "react-router-dom";

function CoursesTab(props) {
  const location = useLocation();
  return (
    <nav className="w-[calc(100%+40px)] px-5 relative -left-5 mt-1 flex border-b">
      <div className="flex flex-col justify-end">
        <Link
          to="/courses/all"
          className={
            "px-4 pt-4 pb-3 block leading-none text-center text-text font-bold"
          }
        >
          Course
        </Link>
        <div
          className={`w-full h-1 rounded-t-sm ${
            location.pathname === "/courses/all"
              ? "bg-primary"
              : "bg-transparent"
          }`}
        ></div>
      </div>
      <div className="flex flex-col">
        <Link
          to="/courses/me"
          className={
            "px-4 pt-4 pb-3 block leading-none text-center text-text font-bold"
          }
        >
          My Course
        </Link>
        <div
          className={`w-full h-1 rounded-t-sm ${
            location.pathname === "/courses/me"
              ? "bg-primary"
              : "bg-transparent"
          }`}
        ></div>
      </div>
    </nav>
  );
}
export default CoursesTab;
