import LaunchpadIcon from "../icons/LaunchpadIcon";
import OnlineCourseIcon from "../icons/OnlineCourseIcon";
import ForumIcon from "../icons/ForumIcon";
import ScheduleIcon from "../icons/ScheduleIcon";
import UpdatesIcon from "../icons/UpdatesIcon";
import PointSystemIcon from "../icons/PointSystemIcon";
import { NavLink } from "react-router-dom";

function Nav({ className }) {
  const menu = [
    {
      title: "Online Course",
      icon: <OnlineCourseIcon />,
      selected: true,
      to: "/courses",
    },
    {
      title: "Launchpad",
      icon: <LaunchpadIcon />,
      selected: false,
      to: "/launchpad",
    },
    {
      title: "Forum",
      icon: <ForumIcon />,
      selected: false,
      to: "/forum",
    },
    {
      title: "Schedule",
      icon: <ScheduleIcon />,
      selected: false,
      to: "/schedules",
    },
    {
      title: "Updates",
      icon: <UpdatesIcon />,
      selected: false,
      to: "/updates",
    },
    {
      title: "Point System",
      icon: <PointSystemIcon />,
      selected: false,
      to: "/points",
    },
  ];

  return (
    <nav className={`p-4 rounded-lg shadow-md bg-primary text-white ${className}`}>
      <ul className="flex flex-col gap-1">
        {menu.map((item, i) => (
          <li className="overflow-hidden overflow-ellipsis whitespace-nowrap text-sm">
            <NavLink
              to={item.to}
              className={({ isActive }) =>
                `px-1.5 py-3 rounded-md hover:bg-orange-300 ${
                  isActive ? "bg-orange-300 font-bold" : ""
                } flex items-center gap-1.5`
              }
            >
              <span className="flex-shrink-0">{item.icon}</span>
              {item.title}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
}
export default Nav;
