import { useEffect, useRef, useState } from "react";
import { getAllBloggerTask } from "../../../services/bloggerCourseService";
import { TasksSectionCard } from "./TasksSectionCard";
import TasksSectionSkeleton from "./TasksSectionSkeleton";

function Tasks(props) {
  const [tasksData, setTasksData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setisError] = useState(false);
  const scrollArea = useRef(null);

  useEffect(() => {
    getTasksData();
  }, []);

  const getTasksData = async () => {
    try {
      setIsLoading(true);
      setTasksData((await getAllBloggerTask()).slice(0, 3));
    } catch (error) {
      setisError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <TasksSectionSkeleton />}
      {!isLoading && !isError && tasksData?.length > 0  ? (
        <div className="mb-12">
          <div className="mb-4 flex items-center justify-between">
            <h1 className="font-bold text-gray-900 text-xl">Tasks</h1>
          </div>
          <div className="w-full flex items-stretch">
            <div className="h-24 w-0 overflow-visible lg:hidden">
              <div className="w-8 h-full flex items-center relative z-30 justify-center">
                <button
                  onClick={() => {
                    scrollArea.current.scroll({
                      top: 0,
                      left: scrollArea.current.scrollLeft - 176,
                      behavior: "smooth",
                    });
                  }}
                  className="rounded-full shadow-md border bg-white hover:bg-gray-100 text-gray-700"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    style={{
                      fill: "currentColor",
                      transform: "",
                      msfilter: "",
                    }}
                  >
                    <path d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z" />
                  </svg>
                </button>
              </div>
            </div>
            <div
              ref={scrollArea}
              className="w-full p-1 mb-4 scroll-px-8 snap-x flex-grow flex items-stretch overflow-x-scroll no-scrollbar gap-4 lg:grid lg:grid-cols-3"
            >
              {tasksData?.map((data, i) => (
                <TasksSectionCard data={data} key={data.id} />
              ))}
            </div>
            <div className="h-24 w-0 overflow-visible lg:hidden flex justify-end">
              <div className="w-8 p-1 h-full flex items-center relative z-30 justify-center">
                <button
                  onClick={() => {
                    scrollArea.current.scroll({
                      top: 0,
                      left: scrollArea.current.scrollLeft + 176,
                      behavior: "smooth",
                    });
                  }}
                  className="rounded-full shadow-md border bg-white hover:bg-gray-100 text-gray-700"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    style={{
                      fill: "currentColor",
                      transform: "",
                      msfilter: "",
                    }}
                  >
                    <path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
export default Tasks;
