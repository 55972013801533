import penakitaCoursePageIllustration from "../../../assets/penakita-course-page-header-illustration.svg";
import bannerBg from "../../../assets/banner-bg.svg";

function CoursesBanner(props) {
  return (
    <header
      className="w-full h-44 bg-primary flex rounded-xl shadow-md overflow-clip"
      style={{
        backgroundImage: `url(${bannerBg})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <div className="w-0 h-0">
      </div>
      <div className="w-full h-full flex flex-col justify-end md:justify-start">
        <p className="ml-4 mt-4 md:mt-10 md:ml-10 text-white">Welcome to</p>
        <h1 className="ml-4 md:ml-10 text-3xl md:text-5xl text-white font-bold">
          Course
        </h1>
        <p className="ml-4 mb-4 md:mb-0 md:ml-10 text-sm md:text-base text-white">
          Start learning, and improve your skill!
        </p>
      </div>
      <div className="">
        <img
          src={penakitaCoursePageIllustration}
          className="h-44 w-96 relative z-30 top-5"
          alt="Online learning illustration"
        />
        {/* <div className="w-0 h-0">
          <svg
            className="relative h-96 bottom-56 -left-11 opacity-20"
            viewBox="0 0 200 200"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="white"
              d="M51.6,-66.3C67.3,-59.7,80.8,-45.1,80.9,-29.8C81.1,-14.5,67.9,1.7,57.4,13.9C46.8,26,38.9,34.3,29.7,39.3C20.6,44.3,10.3,46,-1.4,47.9C-13,49.8,-26.1,51.8,-39.1,48.1C-52.2,44.4,-65.3,34.9,-72.4,21.5C-79.5,8.1,-80.7,-9.2,-73.6,-21.7C-66.5,-34.2,-51.2,-41.9,-37.6,-49.1C-24,-56.4,-12,-63.4,3,-67.5C18,-71.6,35.9,-72.9,51.6,-66.3Z"
              transform="translate(100 100)"
            />
          </svg>
        </div> */}
      </div>
    </header>
  );
}
export default CoursesBanner;
