import landingPageHeroIllustration from "../../../assets/landing-page-hero-illustration.png";

function LandingPageMainHero(props) {
  return (
    <>
      <div className="max-w-full md:max-w-lg lg:max-w-5xl mx-auto overflow-visible">
        <div className="flex flex-col lg:flex-row-reverse items-center justify-start lg:justify-between">
          <div className="flex-shrink-0 flex flex-col items-center">
            <img
              src={landingPageHeroIllustration}
              alt="Landing page illustration"
              className="h-72 lg:h-[512px] transform-cpu scale-110 -translate-y-3 translate-x-4"
            />
          </div>
          <div className="p-6 text-center lg:text-left text-white font-sans-landing font-semibold flex flex-col items-center lg:items-start gap-4">
            <p className="lg:text-2xl">Selamat datang di</p>
            <h1 className="text-4xl">Penakita Development Program</h1>
            <p className="lg:text-sm font-normal">
              Program pengembangan menjadi blogger profesional
            </p>
            <button
              className="w-auto px-8 py-3 rounded-3xl bg-white hover:bg-gray-100 transition-colors duration-300 text-text disabled:bg-disable disabled:text-disable2 font-bold"
            >
              Get Started
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
export default LandingPageMainHero;
