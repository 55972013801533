import { useEffect, useState } from "react";
import LaunchpadMissionListCard from "./LaunchpadMissionListCard";
import LauncpadMissionListCardSkeleton from "./LauncpadMissionListCardSkeleton";
import Pagination from "../../../components/Pagination";
import { getLaunchpads } from "../../../services/launchpadService";
import useReactiveSearchParams from "../../../utils/useReactiveSearchParams";

function LaunchpadMissionList(props) {
  const [missionsData, setMissionsData] = useState([]);
  const [meta, setMeta] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [searchParams, setSearchParams] = useReactiveSearchParams();
  const page = Number.parseInt(searchParams.page || "1");

  const getMissionsData = async () => {
    try {
      setIsLoading(true);
      setMissionsData((await getLaunchpads({ page })).launchpads);
      setMeta((await getLaunchpads({ page })).meta);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getMissionsData();
  }, []);

  return (
    <>
      {isLoading && (
        <section className="mt-8 grid grid-cols-1 sm:grid-cols-2 gap-4 overflow-visible">
          {[0, 0, 0, 0, 0, 0, 0, 0, 0, 0].map((x, i) => (
            <LauncpadMissionListCardSkeleton key={i} />
          ))}
        </section>
      )}
      {!isLoading && !isError && (
        <>
          <section className="mt-8 grid grid-cols-1 sm:grid-cols-2 gap-4 overflow-visible">
            {missionsData.map((mission, i) => (
              <LaunchpadMissionListCard key={i} mission={mission} />
            ))}
          </section>
          {
            <Pagination
              page={page}
              maxPage={Math.ceil(meta.total / meta.limit)}
            />
          }
        </>
      )}
    </>
  );
}
export default LaunchpadMissionList;
