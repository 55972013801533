import axios from "axios";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Button from "../../../components/Button";

function CourseDetailEnrollButton({handleEnroll, ...restOfProps}) {
  return (
    <Button onClick={handleEnroll} {...restOfProps}>
      Enroll
    </Button>
  );
}
export default CourseDetailEnrollButton;
