function TasksSectionSkeleton(props) {
  return (
    <div className="mb-12 animate-pulse">
      <div className="w-14 h-6 mb-4 bg-gray-100"></div>
      <div className="w-full p-1 mb-4 flex flex-nowrap overflow-x-hidden gap-4">
        <div className="w-60 p-4 flex-shrink-0 h-28 border rounded-md flex flex-col justify-between">
          <div className="w-2/3 h-4 bg-gray-100"></div>
          <div className="flex items-center justify-between">
            <div className="w-20 h-6 rounded-2xl bg-gray-100"></div>
            <div className="w-16 h-4 bg-gray-100"></div>
          </div>
        </div>
        <div className="w-60 p-4 flex-shrink-0 h-28 border rounded-md flex flex-col justify-between">
          <div className="w-2/3 h-4 bg-gray-100"></div>
          <div className="flex items-center justify-between">
            <div className="w-20 h-6 rounded-2xl bg-gray-100"></div>
            <div className="w-16 h-4 bg-gray-100"></div>
          </div>
        </div>
        <div className="w-60 p-4 flex-shrink-0 h-28 border rounded-md flex flex-col justify-between">
          <div className="w-2/3 h-4 bg-gray-100"></div>
          <div className="flex items-center justify-between">
            <div className="w-20 h-6 rounded-2xl bg-gray-100"></div>
            <div className="w-16 h-4 bg-gray-100"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default TasksSectionSkeleton;
