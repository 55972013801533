import moment from "moment";
import { Link } from "react-router-dom";

function SchedulesDayViewList({ schedulesData = [] }) {
  return (
    <>
      <section className="mt-8 grid grid-cols-1 sm:grid-cols-2 gap-4 overflow-visible">
        {schedulesData[0]?.children?.map((item) => (
          <ScheduleDayViewListCard item={item} />
        ))}
      </section>
    </>
  );
}
export default SchedulesDayViewList;

function ScheduleDayViewListCard({ item }) {
  return (
    <article className="h-28 p-4 shadow-md rounded-md bg-white flex justify-between gap-4">
      <div className="flex flex-1 flex-grow flex-col gap-2">
        <Link
          to={`/schedules/details/${item.type}/${item.scheduleId}`}
          className="text-text font-bold hover:underline leading-4 line-clamp-[18.75px]"
        >
          {item.title}
        </Link>
        <footer className="mt-auto text-xs inline-flex justify-between text-disable">
          <div className="inline-flex gap-1.5">
            <svg
              width={16}
              height={16}
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.99967 2.33334C4.87501 2.33334 2.33301 4.87534 2.33301 8C2.33301 11.1247 4.87501 13.6667 7.99967 13.6667C11.1243 13.6667 13.6663 11.1247 13.6663 8C13.6663 4.87534 11.1243 2.33334 7.99967 2.33334ZM7.99967 14.6667C4.32367 14.6667 1.33301 11.676 1.33301 8C1.33301 4.324 4.32367 1.33334 7.99967 1.33334C11.6757 1.33334 14.6663 4.324 14.6663 8C14.6663 11.676 11.6757 14.6667 7.99967 14.6667Z"
                fill="#FF9D25"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.2868 10.4616C10.1994 10.4616 10.1114 10.4389 10.0308 10.3916L7.51744 8.89227C7.36677 8.8016 7.27344 8.63827 7.27344 8.46227V5.23027C7.27344 4.95427 7.49744 4.73027 7.77344 4.73027C8.0501 4.73027 8.27344 4.95427 8.27344 5.23027V8.17827L10.5434 9.5316C10.7801 9.6736 10.8581 9.98027 10.7168 10.2176C10.6228 10.3743 10.4568 10.4616 10.2868 10.4616Z"
                fill="#FF9D25"
              />
            </svg>

            <span>
              {item.startTime && item.endTime
                ? `${item.startTime}-${item.endTime}`
                : moment(item.dueDate).format("hh.mm")}
            </span>
          </div>
          {item.participant ? (
            <div className="inline-flex gap-1.5">
              <svg
                width={16}
                height={16}
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <mask
                  id="mask0_939_6581"
                  style={{ maskType: "alpha" }}
                  maskUnits="userSpaceOnUse"
                  x={2}
                  y={9}
                  width={12}
                  height={6}
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.66699 9.66408H13.2269V14.5801H2.66699V9.66408Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask0_939_6581)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.94766 10.6641C5.10699 10.6641 3.66699 11.1521 3.66699 12.1154C3.66699 13.0874 5.10699 13.5801 7.94766 13.5801C10.7877 13.5801 12.227 13.0921 12.227 12.1287C12.227 11.1567 10.7877 10.6641 7.94766 10.6641ZM7.94766 14.5801C6.64166 14.5801 2.66699 14.5801 2.66699 12.1154C2.66699 9.91808 5.68099 9.66408 7.94766 9.66408C9.25366 9.66408 13.227 9.66408 13.227 12.1287C13.227 14.3261 10.2137 14.5801 7.94766 14.5801Z"
                    fill="#FF9D25"
                  />
                </g>
                <mask
                  id="mask1_939_6581"
                  style={{ maskType: "alpha" }}
                  maskUnits="userSpaceOnUse"
                  x={4}
                  y={1}
                  width={8}
                  height={8}
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.40723 1.3334H11.4872V8.41247H4.40723V1.3334Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask1_939_6581)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.94791 2.28515C6.52058 2.28515 5.35925 3.44581 5.35925 4.87315C5.35458 6.29581 6.50725 7.45581 7.92858 7.46114L7.94791 7.93715V7.46114C9.37458 7.46114 10.5352 6.29981 10.5352 4.87315C10.5352 3.44581 9.37458 2.28515 7.94791 2.28515ZM7.94791 8.41248H7.92658C5.97858 8.40648 4.40058 6.81781 4.40725 4.87115C4.40725 2.92115 5.99525 1.33315 7.94791 1.33315C9.89991 1.33315 11.4872 2.92115 11.4872 4.87315C11.4872 6.82515 9.89991 8.41248 7.94791 8.41248Z"
                    fill="#FF9D25"
                  />
                </g>
              </svg>

              <span>
                {new Intl.NumberFormat("id-ID", {
                  notation: "compact",
                  maximumFractionDigits: 1,
                }).format(item.participant)}{" "}
                Participant
              </span>
            </div>
          ) : (
            <></>
          )}
        </footer>
      </div>
      {item.image && (
        <img
          src={item.image}
          alt=""
          className="w-20 h-20 flex-shrink-0 rounded-md object-cover"
        />
      )}
    </article>
  );
}
