import moment from "moment";
import { Link } from "react-router-dom";

export function HomeworkPreviewWidgetCourseItemTask({ task }) {
  const statusStyle = {
    waiting: "text-disable2",
    new: "text-primary",
    onprogress: "text-primary",
    done: "text-success",
  };

  const statusText = {
    waiting: "Waiting",
    new: "New",
    onprogress: moment().add(3600, "s").fromNow(true),
    done: "Done",
  };
  return (
    <div className="py-2 flex items-center justify-between gap-1">
      <p className="text-sm line-clamp-1">{task.title}</p>
      <div
        data-testid="task-status"
        className={`text-sm ${
          statusStyle[task.status] ?? "text-disable2"
        } flex-shrink-0`}
      >
        {statusText[task.status]}
      </div>
    </div>
  );
}

function HomeworkPreviewWidgetCourseItem({ courseItem }) {
  return (
    <article className="h-min p-4 bg-white rounded-lg shadow-md">
      <header className="py-2 mb-2 border-b flex items-center justify-between">
        <h4 className="text-md font-bold line-clamp-1">{courseItem.title}</h4>
        <div className="ml-2 text-md text-primary font-bold">
          {courseItem.overview}
        </div>
      </header>
      <main className="mb-4 flex flex-col">
        {courseItem.tasks.map((task) => (
          <HomeworkPreviewWidgetCourseItemTask task={task} />
        ))}
      </main>
      <div className="text-right">
        <Link
          to={`/courses/me/${courseItem.bloggerCourseId}/homeworks`}
          className="p-1 rounded-md hover:bg-gray-100 text-sm text-primary font-bold"
        >
          See All
        </Link>
      </div>
    </article>
  );
}
export default HomeworkPreviewWidgetCourseItem;
