import axios from "axios";
import {
  GET_FORUMS_API_ROUTE,
  GET_FORUM_DETAIL_API_ROUTE,
  POST_FORUM_API_ROUTE,
  POST_FORUM_COMMENT_API_ROUTE,
} from "../constants";

async function getForums({
  page = 1,
  limit = 10,
  sortBy = "new post",
  keyword = "",
}) {
  let forums = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: GET_FORUMS_API_ROUTE,
      method: "get",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      params: {
        page,
        limit,
        sortBy,
        keyword,
      },
    });
    forums = response.data.data;
  } catch (error) {
    throw error;
  }

  return forums;
}

async function getForumDetail(forumId) {
  let forumDetail = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: GET_FORUM_DETAIL_API_ROUTE(forumId),
      method: "get",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    });
    forumDetail = response.data.data;
  } catch (error) {
    throw error;
  }

  return forumDetail;
}

async function postForum({ message }) {
  let forum = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: POST_FORUM_API_ROUTE,
      method: "post",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      data: {
        message,
      },
    });
    forum = response.data.data;
  } catch (error) {
    throw error;
  }

  return forum;
}

async function postComment({ forumId, comment }) {
  let result = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: POST_FORUM_COMMENT_API_ROUTE(forumId),
      method: "post",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      data: {
        comment,
      },
    });
    result = response.data.data;
  } catch (error) {
    throw error;
  }

  return result;
}

export { getForums, getForumDetail, postForum, postComment };
