import SidebarLeft from "../../components/SidebarLeft";
import Main from "../../components/Main";
import SidebarRight from "../../components/SidebarRight";
import Header from "../../components/Header";
import Nav from "../../components/Nav";
import GridLayout from "../../components/GridLayout";
import CourseDetailTitle from "./components/CourseDetailTitle";
import CourseEnrollButton from "./components/CourseDetailEnrollButton";
import CourseDetailObjective from "./components/CourseDetailObjective";
import CourseDetailHomeworkList from "./components/CourseDetailHomeworkList";
import CourseDetailHomeworkWidget from "./components/CourseDetailHomeworkWidget";
import Breadcrumb from "../../components/Breadcrumb";
import { useState, useEffect } from "react";
import axios from "axios";
import { getCourseDetail } from "../../services/courseService";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import CourseDetailHeader from "./components/CourseDetailHeader";
import CourseDetailSkeleton from "./components/CourseDetailSkeleton";

function CourseDetail(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [courseDetailData, setCourseDetailData] = useState({});
  const { courseId } = useParams();

  useEffect(() => {
    getCourseDetailData();
  }, []);

  const getCourseDetailData = async () => {
    try {
      setIsError(false);
      setIsLoading(true);
      setCourseDetailData(await getCourseDetail(courseId));
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>{courseDetailData ? `Course: ${courseDetailData?.title}` : "Course Detail"}</title>
      </Helmet>
      <Header />
      <GridLayout>
        <SidebarLeft>
          <Nav />
        </SidebarLeft>
        <Main>
          {isLoading && <CourseDetailSkeleton />}
          {!isLoading && !isError && (
            <>
              <CourseDetailHeader data={courseDetailData} />
              <CourseDetailObjective
                courseObjective={courseDetailData.description}
              />
              <CourseDetailHomeworkList tasks={courseDetailData.tasks} />
            </>
          )}
          {isError && (
            <>
              <CourseDetailHeader data={courseDetailData} />
              <div className="w-full h-96 flex items-center justify-center text-red-800 text-sm text-center">
                Gagal memperoleh informasi
              </div>
            </>
          )}
        </Main>
        <SidebarRight>
          {!isLoading && !isError && (
            <CourseDetailHomeworkWidget tasks={courseDetailData.tasks} />
          )}
          {isError && <></>}
        </SidebarRight>
      </GridLayout>
    </>
  );
}
export default CourseDetail;
