import SidebarLeft from "../../components/SidebarLeft";
import Main from "../../components/Main";
import SidebarRight from "../../components/SidebarRight";
import GridLayout from "../../components/GridLayout";
import Header from "../../components/Header";
import Nav from "../../components/Nav";
import Helmet from "react-helmet";
import UpdatesHeader from "./components/UpdatesHeader";
import UpdatesFeed from "./components/UpdatesFeed";
import UpdatesTrendingWidget from "./components/UpdatesTrendingWidget";

function Updates(props) {
  return (
    <>
      <Helmet>
        <title>Updates</title>
      </Helmet>
      <Header />
      <GridLayout>
        <SidebarLeft>
          <Nav />
        </SidebarLeft>
        <Main>
          <UpdatesHeader />
          <UpdatesFeed />
        </Main>
        <SidebarRight>
          <UpdatesTrendingWidget />
        </SidebarRight>
      </GridLayout>
    </>
  );
}
export default Updates;
