import axios from "axios";
import {
  GET_BLOGGER_COURSES_API_ROUTE,
  GET_BLOGGER_COURSE_DETAIL_API_ROUTE,
  GET_BLOGGER_TASKS_PREVIEW_API_ROUTE,
  GET_BLOGGER_TASK_DETAIL_API_ROUTE,
  POST_BLOGGER_TASK_EVIDENCE_API_ROUTE,
  GET_BLOGGER_TASK_LIST,
} from "../constants";

async function getBloggerCourses({
  keyword = "",
  sortBy = "Latest",
  limit = 8,
  page = 1,
  ...restOfParams
}) {
  let bloggerCourses = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: GET_BLOGGER_COURSES_API_ROUTE,
      method: "GET",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      params: {
        keyword,
        sortBy,
        limit,
        page,
        ...restOfParams,
      },
    });
    bloggerCourses = response.data.data;
  } catch (error) {
    throw error;
  }

  return bloggerCourses;
}

async function getBloggerCourseDetail(bloggerCourseId) {
  let bloggerCourseItem = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: GET_BLOGGER_COURSE_DETAIL_API_ROUTE(bloggerCourseId),
      method: "GET",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    });
    bloggerCourseItem = response.data.data;
  } catch (error) {
    throw error;
  }

  return bloggerCourseItem;
}

async function getBloggerTaskPreview() {
  let bloggerTasks = {
    tasks: [
      {
        bloggerCourseId: 0,
        title: "",
        tasks: [
          {
            status: "",
            title: "",
            duration: 0,
          },
        ],
        overview: "",
      },
      {
        bloggerCourseId: 0,
        title: "",
        tasks: [
          {
            status: "",
            title: "",
            duration: 0,
          },
        ],
        overview: "",
      },
    ],
  };

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: GET_BLOGGER_TASKS_PREVIEW_API_ROUTE,
      method: "GET",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    });
    bloggerTasks = response.data.data;
  } catch (error) {
    throw error;
  }

  return bloggerTasks;
}

async function getAllBloggerTask() {
  let bloggerTasks = [];

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: GET_BLOGGER_TASKS_PREVIEW_API_ROUTE,
      method: "GET",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    });
    for (const course of response.data.data.tasks) {
      course.tasks.forEach((task) => {
        bloggerTasks.push(task);
      });
    }
  } catch (error) {
    throw error;
  }

  return bloggerTasks;
}

async function getBloggerTaskDetail(bloggerCourseTaskId) {
  let bloggerTask = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: GET_BLOGGER_TASK_DETAIL_API_ROUTE(bloggerCourseTaskId),
      method: "GET",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Access-Control-Allow-Origin": "*",
      },
    });
    bloggerTask = response.data.data;
  } catch (error) {
    throw error;
  }

  return bloggerTask;
}

async function postBloggerTaskEvidence(bloggerCourseTaskId) {
  let bloggerTaskEvidence = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: POST_BLOGGER_TASK_EVIDENCE_API_ROUTE(bloggerCourseTaskId),
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    });
    bloggerTaskEvidence = response.data.data;
  } catch (error) {
    throw error;
  }

  return bloggerTaskEvidence;
}

async function getBloggerTaskList({ bloggerCourseId }) {
  let result;

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: GET_BLOGGER_TASK_LIST(bloggerCourseId),
      method: "get",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    });
    result = response.data.data.tasks;
  } catch (error) {
    throw error;
  }

  return result;
}

export {
  getBloggerCourses,
  getBloggerCourseDetail,
  getBloggerTaskPreview,
  getAllBloggerTask,
  getBloggerTaskDetail,
  getBloggerTaskList,
  postBloggerTaskEvidence,
};
