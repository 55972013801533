import { Link } from "react-router-dom";
import penakitaLogoWhite from "../assets/penakita-logo-white.svg";
import landingPageFooterSocialLinksIconTwitter from "../assets/landing-page-footer-social-links-icon-twitter.svg";
import landingPageFooterSocialLinksIconYoutube from "../assets/landing-page-footer-social-links-icon-youtube.svg";
import landingPageFooterSocialLinksIconFacebook from "../assets/landing-page-footer-social-links-icon-facebook.svg";
import landingPageFooterSocialLinksIconInstagram from "../assets/landing-page-footer-social-links-icon-instagram.svg";

function LandingPageFooter({ className = "" }) {
  return (
    <footer className={`py-24 pb-4 text-white font-sans-landing  ${className}`}>
      <div className="max-w-full md:max-w-lg lg:max-w-5xl mx-auto flex flex-col lg:flex-row items-center justify-between gap-6 lg:gap-0">
        <Link to="/" className="clickable-area inline-flex">
          <img
            src={penakitaLogoWhite}
            alt="PenaKita logo"
            className="h-8 lg:h-14"
          />
        </Link>
        <div className="text-center flex flex-col lg:items-end gap-6">
          <div className="flex items-center gap-6">
            <a href="#" className="filter hover:brightness-90">
              <img src={landingPageFooterSocialLinksIconFacebook} alt="" />
            </a>
            <a href="#" className="filter hover:brightness-90">
              <img src={landingPageFooterSocialLinksIconTwitter} alt="" />
            </a>
            <a href="#" className="filter hover:brightness-90">
              <img src={landingPageFooterSocialLinksIconYoutube} alt="" />
            </a>
            <a href="#" className="filter hover:brightness-90">
              <img src={landingPageFooterSocialLinksIconInstagram} alt="" />
            </a>
          </div>
          <ul className="text-center flex flex-col lg:flex-row items-center gap-3">
            <li>
              <a href="#" className="hover:underline">
                Syarat dan Ketentuan
              </a>
            </li>
            <li>
              <a href="#" className="hover:underline">
                FAQ
              </a>
            </li>
            <li>
              <a href="#" className="hover:underline">
                Privacy {`&`} Policy
              </a>
            </li>
          </ul>
        </div>
      </div>
      <p className="mt-16 text-xs text-center text-white">
        Penakita © 2022 All rights reserved. Made with love in Jakarta
      </p>
    </footer>
  );
}
export default LandingPageFooter;
