import SidebarLeft from "../../components/SidebarLeft";
import Main from "../../components/Main";
import SidebarRight from "../../components/SidebarRight";
import Header from "../../components/Header";
import Nav from "../../components/Nav";
import GridLayout from "../../components/GridLayout";
import MyCourseDetailMaterials from "./components/MyCourseDetailMaterials";
import MyCourseDetailHomeworkWidget from "./components/MyCourseDetailHomeworkWidget";
import { useState, useEffect } from "react";
import { getBloggerCourseDetail } from "../../services/bloggerCourseService";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import MyCourseDetailHeader from "./components/MyCourseDetailHeader";
import MyCourseDetailSkeleton from "./components/MyCourseDetailSkeleton";

function MyCourseDetail(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [bloggerCourseDetailData, setBloggerCourseDetailData] = useState({});
  const { bloggerCourseId } = useParams();

  useEffect(() => {
    getBloggerCourseDetailData();
  }, []);

  const getBloggerCourseDetailData = async () => {
    try {
      setIsError(false);
      setIsLoading(true);
      setBloggerCourseDetailData(await getBloggerCourseDetail(bloggerCourseId));
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {bloggerCourseDetailData
            ? `Course: ${bloggerCourseDetailData?.title}`
            : "Course Detail"}
        </title>
      </Helmet>
      <Header />
      <GridLayout>
        <SidebarLeft>
          <Nav />
        </SidebarLeft>
        <Main>
          {isLoading && <MyCourseDetailSkeleton />}
          {!isLoading && !isError && (
            <>
              <MyCourseDetailHeader data={bloggerCourseDetailData} />
              <MyCourseDetailMaterials materials={bloggerCourseDetailData.materials} />
            </>
          )}
          {isError && (
            <>
              <MyCourseDetailHeader data={bloggerCourseDetailData} />
              <div className="w-full h-96 flex items-center justify-center text-red-800 text-sm text-center">
                Gagal memperoleh informasi
              </div>
            </>
          )}
        </Main>
        <SidebarRight>
          {!isLoading && !isError && (
            <MyCourseDetailHomeworkWidget tasks={bloggerCourseDetailData.tasks} />
          )}
          {isError && <></>}
        </SidebarRight>
      </GridLayout>
    </>
  );
}
export default MyCourseDetail;
