import SidebarLeft from "../../components/SidebarLeft";
import Main from "../../components/Main";
import SidebarRight from "../../components/SidebarRight";
import Header from "../../components/Header";
import Nav from "../../components/Nav";
import GridLayout from "../../components/GridLayout";
import SchedulesItemDetailHeader from "./components/SchedulesItemDetailHeader";
import SchedulesItemDetailDetails from "./components/SchedulesItemDetailDetails";
import SchedulesItemDetailDescription from "./components/SchedulesItemDetailDescription";
import SchedulesItemDetailTodayScheduleWidget from "./components/SchedulesItemDetailTodayScheduleWidget";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getSchedulesItemDetail } from "../../services/scheduleService";
import useError from "../../utils/useError";
import SchedulesItemDetailSkeleton from "./components/SchedulesItemDetailSkeleton";

function SchedulesItemDetail(props) {
  const { type, scheduleId } = useParams();
  const [scheduleItemDetailData, setScheduleItemDetailData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { error, setError, isError } = useError();

  const getSchedulesData = async () => {
    try {
      setIsLoading(true);
      setError({ status: "ok" });
      setScheduleItemDetailData(
        await getSchedulesItemDetail({ type, scheduleId })
      );
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getSchedulesData();
  }, []);

  return (
    <>
      <Header />
      <GridLayout>
        <SidebarLeft>
          <Nav />
        </SidebarLeft>
        {isLoading && (
          <Main>
            <SchedulesItemDetailSkeleton />
          </Main>
        )}
        {!isLoading && !isError && (
          <Main>
            <SchedulesItemDetailHeader data={scheduleItemDetailData} />
            <SchedulesItemDetailDetails data={scheduleItemDetailData} />
            <SchedulesItemDetailDescription data={scheduleItemDetailData} />
          </Main>
        )}
        <SidebarRight>
          <SchedulesItemDetailTodayScheduleWidget />
        </SidebarRight>
      </GridLayout>
    </>
  );
}
export default SchedulesItemDetail;
