function XIcon(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.364 5.63604C18.7545 6.02656 18.7545 6.65973 18.364 7.05025L7.05025 18.364C6.65973 18.7545 6.02656 18.7545 5.63604 18.364C5.24551 17.9734 5.24551 17.3403 5.63604 16.9497L16.9497 5.63604C17.3403 5.24552 17.9734 5.24552 18.364 5.63604Z"
        fill="#FF9D25"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.63604 5.56497C6.02656 5.17445 6.65973 5.17445 7.05025 5.56497L18.364 16.8787C18.7545 17.2692 18.7545 17.9024 18.364 18.2929C17.9734 18.6834 17.3403 18.6834 16.9497 18.2929L5.63604 6.97918C5.24551 6.58866 5.24551 5.9555 5.63604 5.56497Z"
        fill="#FF9D25"
      />
    </svg>
  );
}
export default XIcon;
