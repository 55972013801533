import Button from "../../../components/Button";
import ButtonLink from "../../../components/ButtonLink";

function SurveyNavigation({ step }) {
  return (
    <section className="mt-4 p-4 bg-white border-t sticky bottom-0 flex items-center justify-between">
      {Number(step) <= 1 || step === "recommendation" ? (
        <div></div>
      ) : (
        <ButtonLink
          to={`/survey/${(Number(step) - 1).toString()}`}
          className="bg-white text-primary text-sm"
        >
          Sebelumnya
        </ButtonLink>
      )}
      {Number(step) <= 4 && (
        <Button type="submit" className="text-sm">
          Selanjutnya
        </Button>
      )}
    </section>
  );
}
export default SurveyNavigation;
