import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { getCourses } from "../../../services/courseService";
import PopularCourseSectionCard from "./PopularCourseSectionCard";
import PopularCourseSectionSkeleton from "./PopularCourseSectionSkeleton";

function PopularCourseSection(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [popularCourseData, setPopularCourseData] = useState([]);
  const scrollArea = useRef(null);

  useEffect(() => {
    getPopularCourseData();
  }, [, scrollArea]);

  const getPopularCourseData = async () => {
    try {
      setIsLoading(true);
      setPopularCourseData((await getCourses({sortBy: "Popular", limit: 4,})).courses);
    } catch (error) {
      console.log(error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="mb-4 flex items-center justify-between">
        <h1 id="popular-course" className="font-bold text-gray-900 text-xl">
          Popular Course
        </h1>
        <Link
          to="/courses/all"
          className="px-2 py-1 rounded-md text-primary hover:bg-neutral-100"
        >
          View all course
        </Link>
      </div>
      <div className="mb-4 flex">
        {isLoading && (
          <div className="w-full h-56 p-1 mb-4 flex overflow-x-scroll no-scrollbar gap-4">
            {[0, 0, 0, 0].map((item) => (
              <PopularCourseSectionSkeleton />
            ))}
          </div>
        )}
        {!isError && !isLoading && (
          <div className="w-full flex items-stretch">
            <div className="h-52 w-0 overflow-visible lg:hidden">
              <div className="w-8 h-full flex items-center relative z-30 justify-center">
                <button
                  onClick={() => {
                    scrollArea.current.scroll({
                      top: 0,
                      left: scrollArea.current.scrollLeft - 176,
                      behavior: "smooth",
                    });
                  }}
                  className="rounded-full shadow-md border bg-white hover:bg-gray-100 text-gray-700"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    style={{
                      fill: "currentColor",
                      transform: "",
                      msfilter: "",
                    }}
                  >
                    <path d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z" />
                  </svg>
                </button>
              </div>
            </div>
            <div
              ref={scrollArea}
              className="w-full h-56 p-1 mb-4 scroll-px-8 snap-x flex-grow flex overflow-x-scroll no-scrollbar gap-4 lg:grid lg:grid-cols-4"
            >
              {popularCourseData.map((data, i) => (
                <PopularCourseSectionCard key={i} data={data} />
              ))}
            </div>
            <div className="h-52 w-0 overflow-visible lg:hidden flex justify-end">
              <div className="w-8 p-1 h-full flex items-center relative z-30 justify-center">
                <button
                  onClick={() => {
                    scrollArea.current.scroll({
                      top: 0,
                      left: scrollArea.current.scrollLeft + 176,
                      behavior: "smooth",
                    });
                  }}
                  className="rounded-full shadow-md border bg-white hover:bg-gray-100 text-gray-700"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    style={{
                      fill: "currentColor",
                      transform: "",
                      msfilter: "",
                    }}
                  >
                    <path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        )}
        {isError && (
          <div className="h-56 mx-auto w-full flex items-center justify-center">
            <p className="text-center text-sm text-red-800">
              Gagal Memperoleh Informasi
            </p>
          </div>
        )}
      </div>
    </>
  );
}
export default PopularCourseSection;
