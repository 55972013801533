import MyCourseDetailMaterialsCard from "./MyCourseDetailMaterialsCard";

function MyCourseDetailMaterials({ materials, disabled }) {
  return (
    <div className="flex flex-col gap-4">
      {materials && materials.map((material, i) => (
        <MyCourseDetailMaterialsCard key={i} material={material} disabled={disabled} />
      ))}
    </div>
  );
}
export default MyCourseDetailMaterials;
