import axios from "axios";
import {
  GET_SURVEY_QUESTIONS_API_ROUTE,
  POST_ANSWERS_API_ROUTE,
  GET_PROFILE_API_ROUTE
} from "../constants";

async function getSurveyIsComplete() {
  let result = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: GET_PROFILE_API_ROUTE,
      method: "get",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    });
    result = response.data;
  } catch (error) {
    throw error;
  }

  return result;
}

async function getSurveyQuestions() {
  let questions = [];

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: GET_SURVEY_QUESTIONS_API_ROUTE,
      method: "get",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    });
    questions = response.data.data.questions;
  } catch (error) {
    throw error;
  }

  return questions;
}

async function postAnswers({ answers }) {
  let result = {};
    console.log({ answers });

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: POST_ANSWERS_API_ROUTE,
      method: "post",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      data: { answers },
    });
    result = response.data.data;
  } catch (error) {
    throw error;
  }

  return result;
}

export { getSurveyQuestions, postAnswers, getSurveyIsComplete };
