import { Helmet } from "react-helmet";
import LandingPageFooter from "../../components/LandingPageFooter";
import LandingPageHeader from "../../components/LandingPageHeader";
import LandingPageLeaderboardFAQ from "../LandingPageMission/components/LandingPageLeaderboardFAQ";
import LandingPageLeaderboardHero from "./components/LandingPageLeaderboardHero";
import LandingPageLeaderboardTabelPoin from "./components/LandingPageLeaderboardTabelPoin";
import heroBg from "../../assets/landing-page-hero-background-blobs.svg";
import LandingPageRegisterPDPBanner from "../../components/LandingPageRegisterPDPBanner";

function LandingPageLeaderboard(props) {
  return (
    <>
      <Helmet>
        <style>
          {`
            body {
              background: url(../assets/landing-page-light-small-bg.svg);
              background-size: 100%;
            }
            @media (min-width: 1024px) { 
              body {
                background: url(../assets/landing-page-light-large-bg.svg);
                background-size: 100%;
              }
            }
          `}
        </style>
      </Helmet>
      <div
        style={{
          background: `url(${heroBg}), rgb(255 157 37)`,
          backgroundSize: "contain",
        }}
      >
        <LandingPageHeader />
        <LandingPageLeaderboardHero />
      </div>
      <svg
        viewBox="0 0 1080 71"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="-mb-24"
      >
        <path
          d="M0 0C0 0 189.5 71 540 71C890.5 71 1080 0 1080 0H0Z"
          fill="#FF9D25"
        />
      </svg>
      <LandingPageLeaderboardTabelPoin />
      <LandingPageLeaderboardFAQ />
      <LandingPageRegisterPDPBanner />
      <LandingPageFooter className="bg-primary" />
    </>
  );
}
export default LandingPageLeaderboard;
