import Breadcrumb from "../../../components/Breadcrumb";
import { useParams } from "react-router-dom";

function ForumPostHeader(props) {
  const { forumPostId } = useParams();
  return (
    <header className="mb-4">
      <div className="flex items-center justify-between">
        <Breadcrumb
          hierarchy={[
            {
              depth: 1,
              url: `/forum/`,
              isCurrent: false,
              name: "Forum",
            },
            {
              depth: 2,
              url: `/forum/posts/${forumPostId}`,
              isCurrent: true,
              name: "Forum Post",
            },
          ]}
        />
        <button className="p-1 rounded-md hover:bg-gray-100">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M15.9484 13.0137C15.3954 13.0137 14.9434 12.5667 14.9434 12.0137C14.9434 11.4607 15.3864 11.0137 15.9384 11.0137H15.9484C16.5014 11.0137 16.9484 11.4607 16.9484 12.0137C16.9484 12.5667 16.5014 13.0137 15.9484 13.0137Z"
              fill="#AEAEAE"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.9386 13.0137C11.3856 13.0137 10.9346 12.5667 10.9346 12.0137C10.9346 11.4607 11.3766 11.0137 11.9296 11.0137H11.9386C12.4916 11.0137 12.9386 11.4607 12.9386 12.0137C12.9386 12.5667 12.4916 13.0137 11.9386 13.0137Z"
              fill="#AEAEAE"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.9298 13.0137C7.3768 13.0137 6.9248 12.5667 6.9248 12.0137C6.9248 11.4607 7.3678 11.0137 7.9208 11.0137H7.9298C8.4828 11.0137 8.9298 11.4607 8.9298 12.0137C8.9298 12.5667 8.4828 13.0137 7.9298 13.0137Z"
              fill="#AEAEAE"
            />
          </svg>
        </button>
      </div>
      <h1 className="mt-4 text-2xl font-bold">Forum</h1>
    </header>
  );
}
export default ForumPostHeader;
