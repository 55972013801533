import { useEffect } from "react";
import { Navigate } from "react-router-dom";

function Logout(props) {
  useEffect(() => {
    window.localStorage.removeItem("token");
  }, []);

  return (
    <Navigate to="/" />
  );
}
export default Logout;
