import Button from "../../../components/Button";

function LandingPageMissionJenisProgram(props) {
  return (
    <section>
      <div className="p-6 max-w-full md:max-w-lg lg:max-w-5xl mx-auto">
        <h2 className="mb-10 text-4xl text-center text-white font-bold font-sans-landing">
          Jenis Program
        </h2>
        <div className="mb-24 p-8 flex flex-col lg:flex-row justify-center gap-32">
          <article className="w-96 p-4 shadow-md rounded-3xl bg-white font-sans-landing flex flex-col gap-4">
            <img
              src="https://picsum.photos/id/237/300/200"
              alt="Pena Launchpad 1.0"
              className=" h-48 object-cover rounded-xl"
            />
            <h3 className="text-text text-xl font-bold">Pena Launchpad 1.0</h3>
            <p className="text-sm">
              Program akselerasi pengembangan skill digital untuk blogger dan
              influencer
            </p>
            <Button>Ikuti</Button>
          </article>
          <article className="w-96 p-4 shadow-md rounded-3xl bg-white font-sans-landing flex flex-col gap-4">
            <img
              src="https://picsum.photos/id/237/300/200"
              alt="Pena Launchpad 1.0"
              className=" h-48 object-cover rounded-xl"
            />
            <h3 className="text-text text-xl font-bold">Pena Launchpad 1.0</h3>
            <p className="text-sm">
              Program akselerasi pengembangan skill digital untuk blogger dan
              influencer
            </p>
            <Button>Ikuti</Button>
          </article>
        </div>
      </div>
    </section>
  );
}
export default LandingPageMissionJenisProgram;
