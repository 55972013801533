import moment from "moment";

function UpdatesFeedCard({ feedItem }) {
  return (
    <a
      href={feedItem.url + "?utm_medium=update&utm_id=penakita" ?? "#"}
      className="p-4 rounded-md bg-white hover:bg-gray-100 shadow-md flex gap-4"
    >
      {feedItem.heroImage?.url && (
        <img
          src={feedItem.heroImage?.url ?? ""}
          alt={feedItem.heroImage?.alt ?? feedItem.title}
          className={`${
            feedItem.heroImage.url ? "w-24 h-24" : "w-0"
          }  rounded-md object-cover flex-shrink-0`}
        />
      )}
      <div className="flex flex-col">
        <h4 className="mb-1 font-bold line-clamp-3 leading-5">
          {feedItem.title ?? ""}
        </h4>
        <div className="flex flex-col lg:flex-row">
          <p className="text-2xs text-disable">
            {moment(feedItem.published_at).format("DD MMM YYYY")}
          </p>
          <p className="text-2xs text-disable">
            <span className="hidden lg:inline-block mx-2 select-none">•</span>
            <span className="text-primary font-bold">
              {feedItem.portals[0]?.title ?? ""}
            </span>
            <span className="mx-2 select-none">•</span>
            <span className="">{feedItem.createdBy?.full_name ?? ""}</span>
          </p>
        </div>
        <p className="hidden lg:block text-xs text-disable2 lg:line-clamp-2">
          {feedItem.excerpt ?? ""}
        </p>
      </div>
    </a>
  );
}
export default UpdatesFeedCard;
