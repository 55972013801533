function LaunchpadIcon(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_679_5485"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x={4}
        y={2}
        width={17}
        height={21}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 2.00049H20.9668V22.3561H4V2.00049Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_679_5485)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.4834 3.5C11.6354 3.5 6.28541 5.384 5.66841 5.899C5.48641 6.082 5.47941 6.4 5.51841 8.529C5.53641 9.555 5.56141 10.949 5.56141 12.879C5.56141 18.08 11.2834 20.389 12.4824 20.814C13.6804 20.387 19.4054 18.065 19.4054 12.879C19.4054 10.947 19.4304 9.552 19.4494 8.526C19.4874 6.399 19.4804 6.081 19.2874 5.889C18.6824 5.384 13.3314 3.5 12.4834 3.5ZM12.4834 22.356C12.4104 22.356 12.3374 22.346 12.2664 22.324C11.9314 22.223 4.06141 19.761 4.06141 12.879C4.06141 10.962 4.03641 9.575 4.01841 8.557C3.97241 6.014 3.96241 5.484 4.61941 4.828C5.40441 4.041 11.3494 2 12.4834 2C13.6164 2 19.5614 4.041 20.3484 4.828C21.0044 5.484 20.9944 6.014 20.9484 8.554C20.9304 9.572 20.9054 10.959 20.9054 12.879C20.9054 19.761 13.0354 22.223 12.7004 22.324C12.6294 22.346 12.5564 22.356 12.4834 22.356Z"
          fill="white"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7766 14.5194C11.5776 14.5194 11.3866 14.4404 11.2456 14.2994L9.35364 12.4054C9.06164 12.1124 9.06164 11.6364 9.35464 11.3444C9.64664 11.0514 10.1226 11.0514 10.4156 11.3444L11.7766 12.7074L15.1446 9.33938C15.4376 9.04638 15.9116 9.04638 16.2046 9.33938C16.4976 9.63238 16.4976 10.1074 16.2046 10.4004L12.3066 14.2994C12.1666 14.4404 11.9756 14.5194 11.7766 14.5194Z"
        fill="white"
      />
    </svg>
  );
}
export default LaunchpadIcon;
