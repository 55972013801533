import Breadcrumb from "../../../components/Breadcrumb";
import { useParams } from "react-router-dom";

function MyCourseDetailHeader({ data }) {
  const { bloggerCourseId } = useParams();

  return (
    <>
      <header className="">
        <Breadcrumb
          hierarchy={[
            {
              depth: 1,
              name: "My Course",
              url: "/courses/me",
              isCurrent: false,
            },
            {
              depth: 2,
              name: data?.title ?? "Course Detail",
              url: `/courses/me/${bloggerCourseId}`,
              isCurrent: true,
            },
          ]}
        />
        {data && <div className="flex items-center justify-between gap-3">
          <h1
            data-testid="course-detail-title"
            className="py-4 text-2xl text-text font-bold "
          >
            {data.title}
          </h1>
        </div>}
      </header>
    </>
  );
}
export default MyCourseDetailHeader;
