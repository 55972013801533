import { Link } from "react-router-dom";

function ButtonLink({ to, children, className, ...restOfProps }) {
  return (
    <Link
      to={to}
      className={`px-3 py-2 font-bold text-white hover:shadow-md disabled:text-neutral-400 disabled:bg-neutral-200 disabled:hover:shadow-none disabled:cursor-not-allowed rounded-3xl bg-primary border border-primary ${className}`}
      {...restOfProps}
    >
      {children}
    </Link>
  );
}
export default ButtonLink;
