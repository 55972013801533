import { Link, useParams } from "react-router-dom";
import moment from "moment";
import { useState } from "react";
import useError from "../utils/useError";
import { useEffect } from "react";
import { getBloggerTaskList } from "../services/bloggerCourseService";

function HomeworkWidgetCard({ task }) {
  const deadline = moment().add(task.duration, "s").fromNow(true);
  const { bloggerCourseId } = useParams();

  return (
    <Link
      to={`/courses/me/${bloggerCourseId}/homeworks/${task.bloggerCourseTaskId}`}
      className="h-min p-4 bg-white hover:bg-gray-100 rounded-lg shadow-md"
    >
      <h4
        data-testid={`homework-widget-card-${task.courseTaskId}`}
        className="pb-3 text-[14px] font-bold"
      >
        {task.title}
      </h4>
      <p className="text-[14px] text-disable2">
        Deadline:{" "}
        <span className="ml-2 text-primary font-bold">{deadline}</span>
      </p>
    </Link>
  );
}

function HomeworkWidget() {
  const [isLoading, setIsLoading] = useState();
  const [tasksData, setTasksData] = useState();
  const { error, isError, setError } = useError();
  const { bloggerCourseId } = useParams();

  useEffect(() => {
    getTasks();
  }, []);

  const getTasks = async () => {
    try {
      setError({ status: "ok" });
      setIsLoading(true);
      setTasksData(await getBloggerTaskList({ bloggerCourseId }));
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(true);
    }
  };

  return tasksData ? (
    <article className="h-min p-4 bg-white rounded-lg shadow-md flex flex-col gap-2">
      <header className="flex items-center justify-between">
        <h3 className="text-lg text-gray-800 font-bold">Homework</h3>
        <Link
          to={`/courses/me/${bloggerCourseId}/homeworks`}
          className="p-1 rounded-md hover:bg-gray-200 text-sm text-primary font-bold"
        >
          View All
        </Link>
      </header>
      <div className="flex flex-col gap-4">
        {tasksData.map((task, i) => (
          <HomeworkWidgetCard key={i} task={task} />
        ))}
      </div>
    </article>
  ) : (
    <></>
  );
}
export default HomeworkWidget;
