import axios from "axios";
import {
  GET_LAUNCHPADS_API_ROUTE,
  GET_LAUNCHPADS_SUBMISSIONS_API_ROUTE,
  GET_LAUNCHPAD_DETAIL_API_ROUTE,
  POST_LAUNCHPAD_EVIDENCE_API_ROUTE,
} from "../constants/index";

async function getLaunchpads({ page = 1, limit = 10 }) {
  let launchpads = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: GET_LAUNCHPADS_API_ROUTE,
      method: "GET",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      params: {
        page,
        limit,
      },
    });
    launchpads = response.data.data;
  } catch (error) {
    throw error;
  }

  return launchpads;
}

async function getLaunchpadsSubmissions() {
  let launchpadsSubmissions = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: GET_LAUNCHPADS_SUBMISSIONS_API_ROUTE,
      method: "get",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    });
    launchpadsSubmissions = response.data.data;
  } catch (error) {
    throw error;
  }

  return launchpadsSubmissions;
}

async function getLaunchpadDetail(bloggerLaunchpadId) {
  let launchpadDetail = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: GET_LAUNCHPAD_DETAIL_API_ROUTE(bloggerLaunchpadId),
      method: "get",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    });
    launchpadDetail = response.data.data;
  } catch (error) {
    throw error;
  }

  return launchpadDetail;
}

async function postLaunchpadEvidence(bloggerLaunchpadId) {
  let launchpadDetail = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: POST_LAUNCHPAD_EVIDENCE_API_ROUTE(bloggerLaunchpadId),
      method: "get",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    });
    launchpadDetail = response.data.data;
  } catch (error) {
    throw error;
  }

  return launchpadDetail;
}

export {
  getLaunchpads,
  getLaunchpadsSubmissions,
  getLaunchpadDetail,
  postLaunchpadEvidence,
};
