import landingPageAboutIllustration1 from "../../../assets/landing-page-about-illustration-1.svg";

function LandingPageMainAbout(props) {
  return (
    <>
      <div className="max-w-full md:max-w-lg lg:max-w-5xl mx-auto">
        <div className="mb-14 p-6 text-text text-center lg:text-left font-sans-landing flex flex-col lg:flex-row-reverse items-center">
          <img
            src={landingPageAboutIllustration1}
            alt=""
            className="h-96 flex-shrink-0"
          />
          <div className="">
            <h2 className="mb-4 text-4xl font-bold">About Program</h2>
            <p className="lg:text-xl">
              Blogger Development Program merupakan program pengembangan menjadi
              blogger profesional, yang berfokus pada peningkatan dan penguatan
              tulisan blogger serta model bisnis blogger untuk menjadikan
              blogger sebagai agen pembawa viral
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
export default LandingPageMainAbout;
