import { useState } from "react";
import { NavLink } from "react-router-dom";
import penakitaLogo from "../assets/penakita-logo.png";
import BurgerIcon from "../icons/BurgerIcon";
import XIcon from "../icons/XIcon";
import Nav from "./Nav";
import Portal from "./Portal";

function Header(props) {
  const [navIsOpen, setNavIsOpen] = useState(false);
  return (
    <header className="h-20 px-4 bg-white shadow-md sticky top-0 z-40">
      <div className="mx-auto max-w-4xl h-20 flex flex-row justify-between items-center">
        <button
          className="p-4 rounded-md hover:bg-gray-200 lg:hidden"
          onClick={() => setNavIsOpen((x) => !x)}
        >
          {navIsOpen ? <XIcon /> : <BurgerIcon />}
        </button>
        <NavLink
          to="/"
          className="p-2 rounded-md flex justify-center hover:bg-gray-200 items-center"
        >
          <img src={penakitaLogo} alt="Penakita Logo" className="h-12" />
        </NavLink>
        <div className="hidden lg:flex h-20  ">
          <nav className="">
            <ul className="h-20 flex flex-row gap-8 justify-end items-center">
              <li>
                <NavLink
                  to="/about"
                  className="hover:border-b-2 border-b-black"
                >
                  About Us
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/mission"
                  className="hover:border-b-2 border-b-black"
                >
                  Mission
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/about-courses"
                  className="hover:border-b-2 border-b-black"
                >
                  Course
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/leaderboard"
                  className="hover:border-b-2 border-b-black"
                >
                  Leaderboard
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/dashboard"
                  className={({ isActive }) =>
                    `${
                      isActive ? "text-primary border-b-primary font-bold" : ""
                    } hover:border-b-2 border-b-black`
                  }
                >
                  Dashboard
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/logout"
                  className="hover:border-b-2 border-b-black"
                >
                  Log Out
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      {navIsOpen && (
        <Portal>
          <div
            onClick={() => setNavIsOpen((x) => !x)}
            className="p-4 pt-24 lg:hidden fixed inset-0 z-30 bg-black bg-opacity-20"
          >
            <div
              onClick={(event) => event.stopPropagation()}
              className="h-16 px-4 bg-white rounded-t-md flex items-center justify-between"
            >
              <div>
                <NavLink
                  className="p-1 rounded-md hover:bg-gray-200"
                  to="/dashboard"
                >
                  Dashboard
                </NavLink>
              </div>
              <button
                onClick={() => {}}
                className="p-1 rounded-md hover:bg-gray-200 text-disable2 hover:text-black"
              >
                Log Out
              </button>
            </div>
            <Nav
              onClick={(event) => event.stopPropagation()}
              className={`rounded-t-none`}
            />
          </div>
        </Portal>
      )}
    </header>
  );
}

export default Header;
