import axios from "axios";
import {
  GET_COURSE_DETAIL_API_ROUTE,
  GET_COURSES_API_ROUTE,
  ENROLL_COURSE_DETAIL_API_ROUTE,
  GET_COURSE_CATEGORIES
} from "../constants";

async function getCourses({
  keyword = "",
  sortBy = "Popular",
  limit = 8,
  page = 1,
  ...restOfParams
}) {
  let courses = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: GET_COURSES_API_ROUTE,
      method: "GET",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      params: {
        keyword,
        sortBy,
        limit,
        page,
        ...restOfParams,
      },
    });
    courses = response.data.data;
    if (!courses) throw "response data is null or undefined";
  } catch (error) {
    throw error;
  }

  return courses;
}

async function getCourseDetail(courseId) {
  let courseItem = {
    courseId: 0,
    title: "",
    description: "",
    rating: 0.0,
    followers: 0,
    image: "",
    videos: [""],
    tasks: [
      {
        courseTaskId: 0,
        title: "",
        description: "",
        duration: 0,
        type: "",
      },
    ],
  };

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: GET_COURSE_DETAIL_API_ROUTE(courseId),
      method: "GET",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    });
    courseItem = response.data.data;
    if (!courseItem) throw "response data is null or undefined";
  } catch (error) {
    throw error;
  }

  return courseItem;
}

async function enrollCourse(courseId) {
  let result = {
    bloggerCourseId: 1,
  };

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: ENROLL_COURSE_DETAIL_API_ROUTE(courseId),
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    });

    result = response.data.data;
    if (!result) throw "response data is null or undefined";
  } catch (error) {
    throw error;
  }

  return result;
}

async function getCourseCategories() {
  let result = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: GET_COURSE_CATEGORIES,
      method: "get",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    });
    result = response.data.data.categories;
  } catch (error) {
    throw error;
  }

  return result;
}

export { getCourses, getCourseDetail, enrollCourse, getCourseCategories };
