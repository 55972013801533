import SidebarLeft from "../../components/SidebarLeft";
import Main from "../../components/Main";
import SidebarRight from "../../components/SidebarRight";
import GridLayout from "../../components/GridLayout";
import Header from "../../components/Header";
import Nav from "../../components/Nav";
import Helmet from "react-helmet";
import { useEffect, useState } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import { useParams } from "react-router-dom";
import ForumPostHeader from "./components/ForumPostHeader";
import ForumPostUser from "./components/ForumPostUser";
import ForumPostContent from "./components/ForumPostContent";
import ForumPostCommentBox from "./components/ForumPostCommentBox";
import ForumPostRepliesSection from "./components/ForumPostRepliesSection";
import ForumPostSkeleton from "./components/ForumPostSkeleton";
import { getForumDetail } from "../../services/forumService";

function ForumPost(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [forumPostData, setForumPostData] = useState({
    forumId: 0,
    message: "",
    images: [""],
    bloggerName: "",
    photoProfile: "",
    totalComment: 0,
    comments: [],
    createdAt: "",
  });

  const { forumPostId } = useParams();

  const getForumPostData = async () => {
    try {
      setIsLoading(true);
      setForumPostData((await getForumDetail(forumPostId)).forum);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getForumPostData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Forum</title>
      </Helmet>
      <Header />
      <GridLayout>
        <SidebarLeft>
          <Nav />
        </SidebarLeft>
        {isLoading && (
          <>
            <Main>
              <ForumPostSkeleton />
            </Main>
          </>
        )}
        {!isLoading && !isError && (
          <Main>
            <ForumPostHeader />
            <ForumPostUser post={forumPostData} />
            <ForumPostContent post={forumPostData} />
            <ForumPostCommentBox {...{ getForumPostData }} />
            <ForumPostRepliesSection post={forumPostData} />
          </Main>
        )}
        {isError && (
          <Main>
            <Breadcrumb
              hierarchy={[
                {
                  depth: 1,
                  url: `/forum/`,
                  isCurrent: false,
                  name: "Forum",
                },
                {
                  depth: 2,
                  url: `/forum/posts/${forumPostId}`,
                  isCurrent: true,
                  name: "Forum Post",
                },
              ]}
            />
            <div className="h-52 flex items-center justify-center">
              <p className="text-sm text-red-800 text-center">
                Gagal Memperoleh Informasi
              </p>
            </div>
          </Main>
        )}
        <SidebarRight></SidebarRight>
      </GridLayout>
    </>
  );
}
export default ForumPost;
