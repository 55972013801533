function ForumIcon(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.887 12.1463C17.519 12.1463 17.198 11.8753 17.145 11.5003C17.088 11.0893 17.373 10.7103 17.784 10.6533C18.824 10.5073 19.609 9.60526 19.611 8.55326C19.611 7.51026 18.863 6.62826 17.832 6.45926C17.424 6.39226 17.147 6.00626 17.214 5.59726C17.282 5.18826 17.665 4.91426 18.076 4.97926C19.834 5.26826 21.111 6.77226 21.111 8.55526C21.107 10.3503 19.766 11.8903 17.991 12.1393C17.956 12.1443 17.921 12.1463 17.887 12.1463Z"
        fill="white"
      />
      <mask
        id="mask0_679_5488"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x={18}
        y={14}
        width={5}
        height={5}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.9785 14.0015H22.7725V18.531H18.9785V14.0015Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_679_5488)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.8618 18.531C20.5598 18.531 20.2748 18.347 20.1608 18.048C20.0128 17.661 20.2078 17.227 20.5948 17.08C21.2728 16.822 21.2728 16.537 21.2728 16.4C21.2728 15.962 20.7158 15.656 19.6178 15.492C19.2088 15.43 18.9258 15.049 18.9868 14.638C19.0478 14.228 19.4278 13.955 19.8408 14.008C22.2638 14.371 22.7728 15.509 22.7728 16.4C22.7728 16.944 22.5588 17.937 21.1288 18.482C21.0408 18.515 20.9508 18.531 20.8618 18.531Z"
          fill="white"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8867 15.9141C9.51073 15.9141 6.67773 16.2061 6.67773 17.5961C6.67773 18.9971 9.51073 19.2911 11.8867 19.2911C14.2627 19.2911 17.0947 19.0001 17.0947 17.6131C17.0947 16.2091 14.2627 15.9141 11.8867 15.9141ZM11.8867 20.7911C10.2277 20.7911 5.17773 20.7911 5.17773 17.5961C5.17773 14.4141 10.2277 14.4141 11.8867 14.4141C13.5457 14.4141 18.5947 14.4141 18.5947 17.6131C18.5947 20.7911 13.7217 20.7911 11.8867 20.7911Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8864 5.5C10.1944 5.5 8.8184 6.877 8.8184 8.569C8.8154 9.389 9.1294 10.156 9.7044 10.735C10.2794 11.314 11.0454 11.635 11.8614 11.638L11.8864 12.388V11.638C13.5784 11.638 14.9554 10.262 14.9554 8.569C14.9554 6.877 13.5784 5.5 11.8864 5.5ZM11.8864 13.138H11.8594C10.6394 13.134 9.4964 12.656 8.6404 11.792C7.7824 10.929 7.3134 9.783 7.3184 8.566C7.3184 6.05 9.3674 4 11.8864 4C14.4064 4 16.4554 6.05 16.4554 8.569C16.4554 11.088 14.4064 13.138 11.8864 13.138Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.88609 12.1465C5.85209 12.1465 5.81709 12.1445 5.78209 12.1395C4.00709 11.8905 2.66709 10.3505 2.66309 8.55745C2.66309 6.77245 3.94009 5.26845 5.69809 4.97945C6.11909 4.91345 6.49209 5.19045 6.56009 5.59745C6.62709 6.00645 6.35009 6.39245 5.94209 6.45945C4.91109 6.62845 4.16309 7.51045 4.16309 8.55545C4.16509 9.60545 4.95009 10.5085 5.98909 10.6535C6.40009 10.7105 6.68509 11.0894 6.62809 11.5005C6.57509 11.8755 6.25409 12.1465 5.88609 12.1465Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.912 18.5313C2.823 18.5313 2.733 18.5153 2.645 18.4823C1.214 17.9363 1 16.9433 1 16.4003C1 15.5103 1.509 14.3713 3.933 14.0083C4.346 13.9563 4.724 14.2283 4.786 14.6383C4.847 15.0493 4.564 15.4303 4.155 15.4923C3.057 15.6563 2.5 15.9623 2.5 16.4003C2.5 16.5373 2.5 16.8213 3.179 17.0803C3.566 17.2273 3.761 17.6613 3.613 18.0483C3.499 18.3473 3.214 18.5313 2.912 18.5313Z"
        fill="white"
      />
    </svg>
  );
}
export default ForumIcon;
