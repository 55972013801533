import { Link } from "react-router-dom";

function Breadcrumb({
  hierarchy = [{ depth: 1, name: "", url: "", isCurrent: false }],
}) {
  return (
    <nav className="flex text-xs">
      <ol className="inline-flex items-center">
        {hierarchy.map((item, i) => {
          return (
            <>
              {!item.isCurrent && (
                <li className={``}>
                  <Link
                    to={item.url}
                    className="max-w-[128px] h-6 p-1 rounded-md hover:bg-gray-100 inline-flex items-center text-gray-400 truncate"
                  >
                    {item.name}
                  </Link>
                </li>
              )}
              {item.isCurrent && (
                <li className={`max-w-[128px] truncate text-black font-bold`}>
                  {item.name}
                </li>
              )}
              {!item.isCurrent && (
                <div className="flex items-center">
                  <svg
                    className="w-4 h-4 text-black"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
              )}
            </>
          );
        })}
      </ol>
    </nav>
  );
}
export default Breadcrumb;
