import { Link } from "react-router-dom";

function ForumPinnedPostsItem() {
  return (
    <article className="p-4 rounded-md shadow-md bg-white">
      <h3 className="text-sm font-bold">Penakita Admin</h3>
      <article className="mt-1 text-xs text-gray-600">
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum
          sed finibus purus. Sed ut porta mi. In aliquam, leo in maximus
          fringilla, turpis nisi laoreet erat, a sagittis ante nisl sit amet
          purus.
        </p>
      </article>
    </article>
  );
}

function ForumPinnedPostsWidget(props) {
  
  return (
    <article className="p-4 rounded-md shadow-md bg-white">
      <header>
        <h2 className="font-bold">Pinned Post</h2>
      </header>
      <section className="mt-2 flex flex-col gap-4">
        {[0, 0, 0].map((post, i) =>
          i <= 4 ? <ForumPinnedPostsItem /> : <></>
        )}
      </section>
      <footer className="mt-4">
        {/* <Link
          to="#"
          className="p-1 rounded-md hover:bg-gray-100 text-primary text-sm font-bold flex items-center justify-center"
        >
          See All
        </Link> */}
      </footer>
    </article>
  );
}
export default ForumPinnedPostsWidget;
