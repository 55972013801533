import SidebarLeft from "../../components/SidebarLeft";
import Main from "../../components/Main";
import SidebarRight from "../../components/SidebarRight";
import Header from "../../components/Header";
import Nav from "../../components/Nav";
import GridLayout from "../../components/GridLayout";
import CoursesBanner from "./components/CoursesBanner";
import CoursesTab from "./components/CoursesTab";
import CoursesAllCourseList from "./components/CoursesAllCourseList";
import HomeworkPreviewWidget from "../../components/HomeworkPreviewWidget";
import { Navigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import CoursesMyCourseList from "./components/CoursesMyCourseList";

/**
 * a screen that shows courses that can be enrolled, and courses that have already been enrolled
 * @returns {JSX.Element} courses screen
 */
function Courses() {
  const location = useLocation();

  return (
    <>
      <Helmet>
        <title>Courses</title>
      </Helmet>
      <Header />
      <GridLayout>
        <SidebarLeft>
          <Nav />
        </SidebarLeft>
        <Main>
          <CoursesBanner />
          <CoursesTab />
          {location.pathname === "/courses/all" && <CoursesAllCourseList />}
          {location.pathname === "/courses/me" && <CoursesMyCourseList />}
          {location.pathname === "/courses/expired" && <></>}
        </Main>
        <SidebarRight>
          <HomeworkPreviewWidget />
        </SidebarRight>
      </GridLayout>
    </>
  );
}
export default Courses;
