import SidebarLeft from "../../components/SidebarLeft";
import Main from "../../components/Main";
import SidebarRight from "../../components/SidebarRight";
import GridLayout from "../../components/GridLayout";
import Header from "../../components/Header";
import Nav from "../../components/Nav";
import LeaderboardWidget from "./components/LeaderboardWidget";
import PenakitaLaunchpadCard from "./components/PenakitaLaunchpadCard";
import TaskSection from "./components/TasksSection";
import ForumSection from "./components/ForumSection";
import PopularCourseSection from "./components/PopularCourseSection";
import UpcomingScheduleWidget from "../../components/UpcomingScheduleWidget";
import Helmet from "react-helmet";

/**
 * a screen that shows the general overview of PDP, such as popular courses, tasks, forum, upcoming schedules, and leaderboard
 * @returns {JSX.Element} dashboard screen
 */
function Dashboard() {
  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <Header />
      <GridLayout>
        <PenakitaLaunchpadCard className="mb-4 md:hidden" />
        <SidebarLeft>
          <Nav />
        </SidebarLeft>
        <Main>
          <PopularCourseSection />
          <TaskSection />
          <ForumSection />
        </Main>
        <SidebarRight>
          <PenakitaLaunchpadCard className="hidden md:block" />
          <UpcomingScheduleWidget />
          <LeaderboardWidget />
        </SidebarRight>
      </GridLayout>
    </>
  );
}
export default Dashboard;
