import { Link } from "react-router-dom";
import SchedulesMonthViewListCard from "./SchedulesMonthViewListCard";

function SchedulesMonthViewList({ schedulesData = [] }) {
  return schedulesData.length > 0 ? (
    <section className="pb-24 lg:pb-0 grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
      {schedulesData.map((scheduleItemData) => (
        <SchedulesMonthViewListCard scheduleItemData={scheduleItemData} />
      ))}
    </section>
  ) : (
    <>
      <section className="h-64 flex items-center justify-center text-center">
        <p className="text-text">Tidak ada agenda bulan ini</p>
      </section>
    </>
  );
}
export default SchedulesMonthViewList;
