import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CourseDetailExchangePointPopup from "./CourseDetailExchangePointPopup";
import Breadcrumb from "../../../components/Breadcrumb";
import Button from "../../../components/Button";
import { enrollCourse } from "../../../services/courseService";
import useModal from "../../../utils/useModal";
import useError from "../../../utils/useError";
import CourseDetailNotEnoughPointPopup from "./CourseDetailNotEnoughPointPopup";

function CourseDetailHeader({ data }) {
  const [isLoading, setIsLoading] = useState(false);
  const { error, setError, isError } = useError();
  const { isShowing: exchangePopupIsShowing, toggle: toggleExchangePopup } =
    useModal(false);
  const {
    isShowing: notEnoughPointPopupIsShowing,
    toggle: toggleNotEnoughPointPopupIsShowing,
  } = useModal(false);
  const { courseId } = useParams();
  const navigate = useNavigate();

  const handleEnroll = async () => {
    try {
      setError({ status: "ok" });
      setIsLoading(true);
      let { bloggerCourseId } = await enrollCourse(courseId);
      if (!bloggerCourseId) {
        throw "bloggerCourseId is required";
      } else {
        navigate(`/courses/me/${bloggerCourseId}`);
      }
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
      if (error.response.status === 422) toggleNotEnoughPointPopupIsShowing();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <header className="">
        <Breadcrumb
          hierarchy={[
            {
              depth: 1,
              name: "Course",
              url: "/courses/all",
              isCurrent: false,
            },
            {
              depth: 2,
              name: data?.title ?? "Course Detail",
              url: `/courses/${courseId}`,
              isCurrent: true,
            },
          ]}
        />
        {data && (
          <div className="flex items-center justify-between gap-3">
            <h1
              data-testid="course-detail-title"
              className="py-4 text-2xl text-text font-bold "
            >
              {data.title}
            </h1>
            <Button onClick={toggleExchangePopup} disabled={isLoading}>
              Enroll
            </Button>
          </div>
        )}
        {isError && (
          <p className="mb-4 text-red-800 text-sm">{error.message}</p>
        )}
      </header>
      <CourseDetailExchangePointPopup
        hide={toggleExchangePopup}
        enroll={handleEnroll}
        isShowing={exchangePopupIsShowing}
        pointsToSpend={data.price}
      />
      <CourseDetailNotEnoughPointPopup isShowing={notEnoughPointPopupIsShowing} hide={toggleNotEnoughPointPopupIsShowing} />
    </>
  );
}
export default CourseDetailHeader;
