import { useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useState } from "react";
import penakitaLogoWhite from "../assets/penakita-logo-white.svg";
import bgBlobs from "../assets/landing-page-mobile-nav-bg.svg";
import { AuthContext } from "../contexts/AuthContext";
import Portal from "./Portal";

function LandingPageHeader() {
  const { isAuth, authIsLoading } = useContext(AuthContext);
  const [navIsOpen, setNavIsOpen] = useState(false);
  const navigate = useNavigate();

  const mode = process.env.REACT_APP_MODE;

  let registerLink, loginLink;

  if (mode === 'development') {
    registerLink = "https://bloggerdev.penakita.id/register";
    loginLink = "https://bloggerdev.penakita.id";
  } else {
    registerLink = "https://blogger.penakita.id/register";
    loginLink = "https://blogger.penakita.id";
  }

  return (
    <>
      <div className="max-w-full md:max-w-lg lg:max-w-5xl mx-auto">
        <header>
          <nav className="px-9 py-6 relative z-40 text-white font-sans-landing flex items-center justify-between gap-4">
            <NavLink to="/" className="clickable-area inline-flex">
              <img
                src={penakitaLogoWhite}
                alt="PenaKita logo"
                className="h-8 lg:h-14"
              />
            </NavLink>
            <button
              onClick={() => setNavIsOpen((x) => !x)}
              className="lg:hidden clickable-area"
            >
              <svg
                width={32}
                height={32}
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M28 22.6667C28 23.403 27.403 24 26.6667 24H5.33333C4.59695 24 4 23.403 4 22.6667C4 21.9303 4.59695 21.3333 5.33333 21.3333H26.6667C27.403 21.3333 28 21.9303 28 22.6667ZM28 16C28 16.7364 27.403 17.3333 26.6667 17.3333H5.33333C4.59695 17.3333 4 16.7364 4 16C4 15.2636 4.59695 14.6667 5.33333 14.6667H26.6667C27.403 14.6667 28 15.2636 28 16ZM28 9.33333C28 10.0697 27.403 10.6667 26.6667 10.6667H5.33333C4.59695 10.6667 4 10.0697 4 9.33333C4 8.59695 4.59695 8 5.33333 8H26.6667C27.403 8 28 8.59695 28 9.33333Z"
                  fill="white"
                />
              </svg>
            </button>
            <ul className="hidden lg:flex items-center justify-end gap-10">
              <li>
                <NavLink
                  to="/about"
                  className={({ isActive }) =>
                    `${isActive ? "font-bold" : ""} clickable-area`
                  }
                >
                  About us
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/mission"
                  className={({ isActive }) =>
                    `${isActive ? "font-bold" : ""} clickable-area`
                  }
                >
                  Mission
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/about-courses"
                  className={({ isActive }) =>
                    `${isActive ? "font-bold" : ""} clickable-area`
                  }
                >
                  Course
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/leaderboard"
                  className={({ isActive }) =>
                    `${isActive ? "font-bold" : ""} clickable-area`
                  }
                >
                  Leaderboard
                </NavLink>
              </li>
              {authIsLoading && "Loading..."}
              {!authIsLoading && !isAuth && (
                <li className="ml-8 -mr-4">
                  <a
                    href={registerLink}
                    className="clickable-area"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Daftar
                  </a>
                </li>
              )}
              {!authIsLoading && (
                <button
                  onClick={() => {
                    if (isAuth) {
                      navigate("/dashboard");
                    } else {
                      window.open(loginLink, "_blank", "noopener, noreferrer");
                    }
                  }}
                  disabled={authIsLoading}
                  className="w-auto px-8 py-3 rounded-3xl border border-white hover:bg-gray-100 transition-colors duration-300 text-white hover:text-black disabled:bg-disable disabled:text-disable2 font-bold"
                >
                  {isAuth ? "Go to Dashboard" : "Login"}
                </button>
              )}
            </ul>
          </nav>
        </header>
      </div>
      {navIsOpen && (
        <Portal>
          <div className="w-full h-screen lg:hidden z-40 fixed top-0 right-0 bg-black bg-opacity-60 font-sans-landing">
            <div
              style={{
                background: `url(${bgBlobs}) bottom no-repeat`,
                backgroundColor: "white",
              }}
              className="w-72 h-screen pt-16 pb-12 px-8 z-50 fixed top-0 right-0 bg-white flex flex-col items-stretch"
            >
              <div className="mb-10 flex items-center justify-between">
                <h3 className="text-2xl text-primary font-bold">Menu</h3>
                <button
                  onClick={() => setNavIsOpen((x) => !x)}
                  className="clickable-area"
                >
                  <svg
                    width={32}
                    height={32}
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17.8164 16.1407C17.7792 16.1036 17.7584 16.0533 17.7584 16.0009C17.7584 15.9484 17.7792 15.8981 17.8164 15.861L26.3199 7.35832C26.7826 6.89493 26.7821 6.14416 26.3187 5.68142C25.8553 5.21869 25.1045 5.21922 24.6418 5.68261L16.1391 14.1821C16.102 14.2193 16.0517 14.2402 15.9993 14.2402C15.9468 14.2402 15.8965 14.2193 15.8594 14.1821L7.35672 5.68261C6.89398 5.21965 6.14356 5.21948 5.68061 5.68221C5.21766 6.14495 5.21748 6.89537 5.68021 7.35832L14.1821 15.861C14.2193 15.8981 14.2402 15.9484 14.2402 16.0009C14.2402 16.0533 14.2193 16.1036 14.1821 16.1407L5.68021 24.6442C5.38088 24.9437 5.26405 25.3801 5.37374 25.7891C5.48342 26.198 5.80296 26.5174 6.21199 26.6269C6.62101 26.7364 7.05738 26.6194 7.35672 26.3199L15.8594 17.8164C15.8965 17.7793 15.9468 17.7584 15.9993 17.7584C16.0517 17.7584 16.102 17.7793 16.1391 17.8164L24.6418 26.3199C25.1045 26.7829 25.8549 26.783 26.3179 26.3203C26.7809 25.8576 26.781 25.1071 26.3183 24.6442L17.8164 16.1407Z"
                      fill="#263238"
                    />
                  </svg>
                </button>
              </div>
              <ul className="mb-auto flex flex-col items-start justify-start gap-10 text-text">
                <li>
                  <NavLink
                    to="/about"
                    className={({ isActive }) =>
                      `${isActive ? "font-bold" : ""} clickable-area`
                    }
                  >
                    About us
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/mission"
                    className={({ isActive }) =>
                      `${isActive ? "font-bold" : ""} clickable-area`
                    }
                  >
                    Mission
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/about-courses"
                    className={({ isActive }) =>
                      `${isActive ? "font-bold" : ""} clickable-area`
                    }
                  >
                    Course
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/leaderboard"
                    className={({ isActive }) =>
                      `${isActive ? "font-bold" : ""} clickable-area`
                    }
                  >
                    Leaderboard
                  </NavLink>
                </li>
              </ul>
              <div className="flex flex-col items-stretch gap-4">
                {authIsLoading && "Loading..."}
                {!authIsLoading && !isAuth && (
                  <a
                    href={registerLink}
                    className="w-full px-8 py-3 rounded-3xl bg-primary text-white text-center"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Daftar
                  </a>
                )}
                {!authIsLoading && (
                  <button
                    onClick={() => {
                      if (isAuth) {
                        navigate("/dashboard");
                      } else {
                        window.open(
                          loginLink,
                          "_blank",
                          "noopener, noreferrer"
                        );
                      }
                    }}
                    disabled={authIsLoading}
                    className="w-full px-8 py-3 rounded-3xl bg-primary text-white"
                  >
                    {isAuth ? "Go to Dashboard" : "Login"}
                  </button>
                )}
              </div>
            </div>
          </div>
        </Portal>
      )}
    </>
  );
}
export default LandingPageHeader;
