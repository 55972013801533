function LaunchpadMissionDetailSkeleton(props) {
  return (
    <div className="flex flex-col animate-pulse">
      <div className="flex items-center justify-between">
        <div className="mt-2 w-48 h-4 mb-4 bg-gray-100"></div>
        <div className="mt-2 w-24 h-4 mb-4 bg-gray-100"></div>
      </div>
      <div className="w-24 h-8 mb-5 bg-gray-100"></div>
      <div className="max-w-[36rem] mx-auto overflow-visible">
        <div className="w-[36rem] h-80 mb-5 bg-gray-100 rounded-md"></div>
        <div className="mb-4">
          <div className="w-24 h-6 mb-3 bg-gray-100"></div>
          <div className="w-full h-4 mb-2 bg-gray-100"></div>
          <div className="w-full h-4 mb-2 bg-gray-100"></div>
          <div className="w-full h-4 mb-2 bg-gray-100"></div>
          <div className="w-full h-4 mb-2 bg-gray-100"></div>
          <div className="w-full h-4 mb-2 bg-gray-100"></div>
          <div className="w-2/3 h-4 mb-2 bg-gray-100"></div>
        </div>
      </div>
    </div>
  );
}
export default LaunchpadMissionDetailSkeleton;
