import { createContext, useEffect, useState } from "react";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import axios from "axios";

const setToken = (token) => window.localStorage.setItem("token", token);
const getToken = () => JSON.parse(window.localStorage.getItem("token"));

const AuthContext = createContext();

export function AuthProvider(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [authIsLoading, setAuthIsLoading] = useState(true);
  const [isAuth, setIsAuth] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setAuthIsLoading(true);
    const tokenFromURL = searchParams.get("token");
    if (tokenFromURL) setToken(JSON.stringify(tokenFromURL));
    checkToken();
    
    // After check token, delete "token" from URL and reload page
    if (tokenFromURL) {
      searchParams.delete("token"); 
      navigate(
        {
          pathname: window.location.pathname,
          search: `?${createSearchParams(searchParams)}`,
        },
        { replace: true }
      );
      window.location.reload()
    }
  }, [, window.localStorage.getItem("token")]);

  const checkToken = async () => {
    try {
      setAuthIsLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/blogger/courses?page=1&limit=1&sortBy=Popular`,
        {
          headers: {
            Authorization: `Bearer ${getToken() ?? ""}`,
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        }
      );
      setIsAuth(true);
    } catch (error) {
      setIsAuth(false);
    } finally {
      setAuthIsLoading(false);
    }
  };

  return (
    <AuthContext.Provider value={{ isAuth, authIsLoading }}>
      {props.children}
    </AuthContext.Provider>
  );
}

export { AuthContext };
