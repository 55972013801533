import SidebarLeft from "../../components/SidebarLeft";
import Main from "../../components/Main";
import SidebarRight from "../../components/SidebarRight";
import Header from "../../components/Header";
import Nav from "../../components/Nav";
import GridLayout from "../../components/GridLayout";
import HomeworkDetailHomeworkWidget from "./components/HomeworkDetailHomeworkWidget";
import Breadcrumb from "../../components/Breadcrumb";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import HomeworkDetailTitle from "./components/HomeworkDetailTitle";
import Button from "../../components/Button";
import HomeworkDetailObjective from "./components/HomeworkDetailObjective";
import HomeworkDetailSubmitEvidenceSection from "./components/HomeworkDetailSubmitEvidenceSection";
import HomeworkDetailSubmitEvidenceModal from "./components/HomeworkDetailSubmitEvidenceModal";
import { Helmet } from "react-helmet";
import {
  getBloggerCourseDetail,
  getBloggerTaskDetail,
} from "../../services/bloggerCourseService";
import useError from "../../utils/useError";
import MultiLines from "../../components/MultiLines";
import HomeworkWidget from "../../components/HomeworkWidget";

function HomeworkDetail() {
  const [bloggerCourseDetailData, setBloggerCourseDetailData] = useState();
  const [bloggerCourseTaskDetailData, setBloggerCourseTaskDetailData] =
    useState();
  const [
    bloggerCourseDetailDataIsLoading,
    setBloggerCourseDetailDataIsLoading,
  ] = useState(false);
  const [
    bloggerCourseTaskDetailDataIsLoading,
    setBloggerCourseTaskDetailDataIsLoading,
  ] = useState(false);
  const {
    error: bloggerCourseDetailError,
    isError: bloggerCourseDetailIsError,
    setError: setBloggerCourseDetailError,
  } = useError();
  const {
    error: bloggerCourseTaskDetailError,
    isError: bloggerCourseTaskDetailIsError,
    setError: setBloggerCourseTaskDetailError,
  } = useError();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { bloggerCourseId, bloggerCourseTaskId } = useParams();

  const getBloggerCourseDetailData = async () => {
    try {
      setBloggerCourseDetailError({ status: "ok" });
      setBloggerCourseDetailDataIsLoading(true);
      setBloggerCourseDetailData(await getBloggerCourseDetail(bloggerCourseId));
    } catch (error) {
      setBloggerCourseDetailError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setBloggerCourseDetailDataIsLoading(false);
    }
  };

  const getBloggerCourseTaskDetailData = async () => {
    try {
      setBloggerCourseTaskDetailError({ status: "ok" });
      setBloggerCourseTaskDetailDataIsLoading(true);
      setBloggerCourseTaskDetailData(
        await getBloggerTaskDetail(bloggerCourseTaskId)
      );
    } catch (error) {
      setBloggerCourseTaskDetailError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setBloggerCourseTaskDetailDataIsLoading(false);
    }
  };

  const handleHomeworkDetailSubmitEvidenceModal = () => {
    setModalIsOpen((x) => !x);
  };

  useEffect(() => {
    getBloggerCourseDetailData();
    getBloggerCourseTaskDetailData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Homework Detail</title>
      </Helmet>
      <Header />
      <GridLayout>
        <SidebarLeft>
          <Nav />
        </SidebarLeft>
        {!bloggerCourseDetailDataIsLoading &&
        !bloggerCourseTaskDetailDataIsLoading &&
        !bloggerCourseDetailIsError &&
        !bloggerCourseTaskDetailIsError ? (
          <Main>
            <Breadcrumb
              hierarchy={[
                {
                  depth: 1,
                  name: "My Course",
                  url: "/courses/me",
                  isCurrent: false,
                },
                {
                  depth: 2,
                  name: bloggerCourseDetailData?.title,
                  url: `/courses/me/${bloggerCourseId}`,
                  isCurrent: false,
                },
                {
                  depth: 3,
                  name: bloggerCourseTaskDetailData?.title,
                  url: `/courses/${bloggerCourseId}/homeworks/${bloggerCourseTaskId}`,
                  isCurrent: true,
                },
              ]}
            />
            <div className="flex gap-4 items-start">
              <HomeworkDetailTitle>
                {bloggerCourseTaskDetailData?.title}
              </HomeworkDetailTitle>
              <Button className={`ml-auto my-4`}>Done</Button>
            </div>
            <HomeworkDetailObjective
              homeworkObjective={
                <MultiLines
                  inputString={bloggerCourseTaskDetailData?.description}
                />
              }
            />
            <hr className="w-[calc(100%+40px)] my-6 px-5 relative -left-5 mt-1 flex border-b"></hr>
            <HomeworkDetailSubmitEvidenceSection
              handleModal={handleHomeworkDetailSubmitEvidenceModal}
            />
            {modalIsOpen && (
              <HomeworkDetailSubmitEvidenceModal
                handleClose={handleHomeworkDetailSubmitEvidenceModal}
              />
            )}
          </Main>
        ) : (
          <Main />
        )}
        <SidebarRight>
          <HomeworkWidget />
        </SidebarRight>
      </GridLayout>
    </>
  );
}
export default HomeworkDetail;
