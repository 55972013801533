import React from "react";
import ReactDOM from "react-dom";
import "./styles/styles.css";
import AppRoutes from "./AppRoutes";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import favicon from "./assets/favicon.ico";
import { Helmet } from "react-helmet";
import SurveyContextProvider from "./contexts/SurveyContext";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <Helmet>
          <title>Penakita Program Development</title>
          <link rel="shortcut icon" href={favicon} type="image/x-icon" />
        </Helmet>
        <SurveyContextProvider>
          <AppRoutes />
        </SurveyContextProvider>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
