import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LocationIcon from "../icons/LocationIcon";
import TimeIcon from "../icons/TimeIcon";
import { getUpcomingSchedules } from "../services/scheduleService";
import useError from "../utils/useError";

function UpcomingScheduleWidget({ limit = 2 }) {
  const [isLoading, setIsLoading] = useState(false);
  const { error, setError, isError } = useError();
  const [upcomingScheduleData, setUpcomingScheduleData] = useState([]);

  const getUpcomingScheduleData = async () => {
    try {
      setIsLoading(true);
      setUpcomingScheduleData(
        (await getUpcomingSchedules()).slice(0, limit ?? 2)
      );
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUpcomingScheduleData();
  }, []);

  return (
    <>
      <article className="h-min p-4 bg-white rounded-lg shadow-md">
        <h1 className="mb-4 font-bold text-gray-900 text-md">
          Upcoming Schedule
        </h1>
        {isLoading && (
          <>
            <div className="flex flex-col gap-3 animate-pulse">
              {Array(limit)
                .fill(0)
                .map((x) => (
                  <article className="w-full h-24 rounded-lg grid grid-cols-4 border overflow-hidden">
                    <div className="bg-gray-100 text-white flex flex-initial flex-col items-center justify-center"></div>
                    <div className="col-span-3 p-3">
                      <div className="w-full h-4 mb-3 bg-gray-100"></div>
                      <div className="w-2/3 h-3 mb-2 bg-gray-100"></div>
                      <div className="w-1/3 h-3 mb-2 bg-gray-100"></div>
                    </div>
                  </article>
                ))}
            </div>
          </>
        )}
        {!isLoading && !isError && (
          <div className="flex flex-col gap-3">
            {upcomingScheduleData.map((schedule) => (
              <article className="h-24 bg-white rounded-lg grid grid-cols-4 border overflow-hidden">
                <div className="bg-primary text-white flex flex-initial flex-col items-center justify-center">
                  <span className="text-2xl mb-2">
                    {moment(schedule.dueDate).format("DD")}
                  </span>
                  <span className="text-xl">
                    {moment(schedule.dueDate).format("MMM")}
                  </span>
                </div>
                <div className="col-span-3 p-3">
                  <p className="mb-1 text-xs line-clamp-2">{schedule.title}</p>
                  <div className="flex gap-1">
                    <LocationIcon />
                    <p className="text-gray-500 mb-0.5 text-xs">
                      {schedule.location}
                    </p>
                  </div>
                  <div className="flex gap-1">
                    <TimeIcon />
                    <p className="text-gray-500 mb-0.5 text-xs">
                      {moment(schedule.dueDate).format("HH.MM")}
                    </p>
                  </div>
                </div>
              </article>
            ))}
          </div>
        )}
        {isError && (
          <>
            <div className="w-full py-24 text-sm text-red-800 text-center">
              Gagal Memperoleh Informasi
            </div>
          </>
        )}

        <div className="mt-4 flex items-center justify-center">
          <Link
            to="/schedules"
            className="font-bold text-primary clickable-area text-sm text-center"
          >
            See All
          </Link>
        </div>
      </article>
    </>
  );
}
export default UpcomingScheduleWidget;
