import { useState } from "react";
import Breadcrumb from "../../../components/Breadcrumb";
import { useParams } from "react-router-dom";
import moment from "moment";

function SchedulesDayViewHeader(props) {
  return (
    <header className="">
      <Breadcrumb
        hierarchy={[
          {
            depth: 1,
            isCurrent: false,
            name: "Schedules",
            url: "/schedules",
          },
          {
            depth: 2,
            isCurrent: false,
            name: moment(
              `${useParams().year} ${useParams().month}`,
              "YYYY MM"
            ).format("MMMM YYYY"),
            url: `/schedules/${useParams().year}/${useParams().month}`,
          },
          {
            depth: 3,
            isCurrent: true,
            name: "Day",
            url: window.location.pathname,
          },
        ]}
      />
      <h1 className="mt-4 text-text text-2xl font-bold">
        {moment(`${useParams().year} ${useParams().month} ${useParams().day}`, "YYYY MM DD")
          .format("DD MMM YYYY")}
      </h1>
    </header>
  );
}
export default SchedulesDayViewHeader;
