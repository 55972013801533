export default function PopularCourseSectionSkeleton() {
  return (
    <div
      data-testid="popular-course-section-skeleton"
      className="w-40 flex-shrink-0 h-48 animate-pulse rounded-md overflow-hidden border aspect-auto"
    >
      <div className="h-24 w-full bg-gray-200 object-cover"></div>
      <div className="h-24 p-3 flex flex-col justify-between">
        <div className="flex flex-wrap gap-1">
          <span className="w-full p-2 bg-gray-200"></span>
          <span className="w-1/4 p-2 bg-gray-200"></span>
        </div>
      </div>
    </div>
  );
}
