import axios from "axios";
import { useEffect, useState } from "react";

function LeaderboardWidget(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [leaderboardData, setLeaderboardData] = useState([]);

  useEffect(() => {
    getLeaderboardData();
  }, []);

  const getLeaderboardData = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/dashboard/leaderboard`
      );
      const data = response.data;
      setLeaderboardData(data);
    } catch (error) {
      console.log(error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <article className="h-min p-4 col-span-4 bg-white rounded-md shadow-md">
      <div className="p-4 bg-primary rounded-md text-center text-lg text-white font-bold">
        Leaderboard
      </div>
      {!isError && (
        <ul className="p-6 flex flex-col gap-6">
          {leaderboardData.map((item, i) => (
            <li
              data-testid={`leaderboard-position-${item.position}`}
              key={i}
              className="font-bold"
            >
              <span
                data-testid={`leaderboard-position-number-${item.position}`}
                className={`pr-2 font-bold ${
                  item.position <= 3 ? "text-primary" : "text-gray-500"
                }`}
              >
                #{item.position}
              </span>
              <span> {item.name}</span>
            </li>
          ))}
        </ul>
      )}
      {isError && (
        <>
          <div className="w-full py-24 text-sm text-red-800 text-center">
            Gagal Memperoleh Informasi
          </div>
        </>
      )}
    </article>
  );
}

export default LeaderboardWidget;
