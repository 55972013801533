import SidebarLeft from "../../components/SidebarLeft";
import Main from "../../components/Main";
import SidebarRight from "../../components/SidebarRight";
import Header from "../../components/Header";
import Nav from "../../components/Nav";
import GridLayout from "../../components/GridLayout";
import LaunchpadTopBanner from "./components/LaunchpadTopBanner";
import LaunchpadMissionList from "./components/LaunchpadMissionList";
import UpcomingScheduleWidget from "../../components/UpcomingScheduleWidget";
import LaunchpadSubmissionWidget from "./components/LaunchpadSubmissionWidget";

function Launchpad(props) {
  return (
    <>
      <Header />
      <GridLayout>
        <SidebarLeft>
          <Nav />
        </SidebarLeft>
        <Main>
          <LaunchpadTopBanner />
          <LaunchpadMissionList />
        </Main>
        <SidebarRight>
          <LaunchpadSubmissionWidget />
          <UpcomingScheduleWidget />
        </SidebarRight>
      </GridLayout>
    </>
  );
}
export default Launchpad;
