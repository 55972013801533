function LandingPageAboutProgramVisiMisi(props) {
  return (
    <section className="p-4">
      <div className="max-w-full md:max-w-lg lg:max-w-5xl mx-auto flex flex-col lg:flex-row justify-center gap-6 lg:gap-0">
        <article className="lg:w-96 h-min lg:-mr-14 p-8 lg:pr-16 shadow-md rounded-3xl bg-white font-sans-landing">
          <h2 className="mb-6 text-2xl text-primary font-bold font-sans-landing">
            Visi
          </h2>
          <ul className="list-disc list-inside text-text">
            <li>Lorem ipsum dolor sit amet consectetur, adipisicing elit. </li>
            <li>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Lorem
              ipsum dolor sit.
            </li>
            <li>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Lorem
              ipsum dolor sit amet consectetur adipisicing.
            </li>
            <li>Lorem ipsum dolor sit amet consectetur, adipisicing elit.</li>
          </ul>
        </article>
        <article className="lg:w-96 h-min lg:mt-14 p-8 lg:pr-16 shadow-md rounded-3xl bg-white font-sans-landing">
          <h2 className="mb-6 text-2xl text-primary font-bold font-sans-landing">
            Misi
          </h2>
          <ul className="list-disc list-inside text-text">
            <li>Lorem ipsum dolor sit amet consectetur, adipisicing elit. </li>
            <li>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Lorem
              ipsum dolor sit.
            </li>
            <li>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Lorem
              ipsum dolor sit amet consectetur adipisicing.
            </li>
            <li>Lorem ipsum dolor sit amet consectetur, adipisicing elit.</li>
          </ul>
        </article>
      </div>
    </section>
  );
}
export default LandingPageAboutProgramVisiMisi;
