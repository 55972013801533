import { Form, Formik } from "formik";
import _ from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { useContext } from "react";
import SurveyNavigation from "./SurveyNavigation";
import SurveyProgress from "./SurveyProgress";
import { SurveyContext } from "../../../contexts/SurveyContext";

export function SurveyQuestionItem({ questionItem, formProps }) {
  return (
    <li className="list-item">
      <span data-testid="question-text">{questionItem.question}</span>
      <div data-testid="answer-option" className="my-2 flex flex-col">
        {_.orderBy(questionItem.answerOptions, "answerId", "asc").map(
          (answer, i) => (
            <label className="inline-flex gap-2" key={i}>
              <input
                type="radio"
                name={questionItem.questionId}
                id={`question-${questionItem.questionId}-answer-${answer.answerId}`}
                value={answer.answerId}
                onChange={formProps.handleChange}
                defaultChecked={
                  formProps.initialValues[questionItem.questionId] ===
                  answer.answerId
                }
                required
              />
              <span data-testid="answer-option-text">{answer.answer}</span>
            </label>
          )
        )}
      </div>
    </li>
  );
}

function SurveyQuestions({ questions, hidden }) {
  const navigate = useNavigate();
  const { step } = useParams();
  const initialValues = {};
  const { captureAnswers, surveyAnswers, submitSurveyAnswers } =
    useContext(SurveyContext);
  questions.forEach((question) => {
    const answerIndex = surveyAnswers.findIndex(
      (answer) => answer.questionId === question.questionId
    );
    initialValues[question.questionId] =
      surveyAnswers[answerIndex]?.answerId[0];
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        captureAnswers(values);
        if (step == 4) submitSurveyAnswers()
          navigate(
            step != 4 ? `/survey/${Number(step) + 1}` : "/survey/results"
          );
      }}
    >
      {(props) => {
        return (
          <Form>
            <section className="px-8 py-4 flex-grow">
              <SurveyProgress step={step} />
              <ol
                start={questions[0].order + 1}
                className="list-decimal text-sm"
              >
                {_.orderBy(questions, ["order"], ["asc"]).map(
                  (questionItem, i) => (
                    <SurveyQuestionItem
                      questionItem={questionItem}
                      formProps={props}
                      key={i}
                    />
                  )
                )}
              </ol>
            </section>
            <SurveyNavigation step={step} />
          </Form>
        );
      }}
    </Formik>
  );
}
export default SurveyQuestions;
