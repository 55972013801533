import { useState, useEffect } from "react";
import Portal from "../../../components/Portal";
import ForumCreatePostPopup from "./ForumCreatePostPopup";
import { getProfile } from "../../../services/profileService";
import useError from "../../../utils/useError";
import emptyProfilePicture from "../../../assets/empty-profile-picture.svg";

function ForumCreatePostButton(props) {
  const [createPostPopupOpen, setCreatePostPopupOpen] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { setError, isError } = useError();

  useEffect(() => {
    getProfileData();
  }, []);

  const getProfileData = async () => {
    try {
      setIsLoading(true);
      setProfileData(await getProfile());
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="mb-6 p-4 border rounded-md shadow-md flex items-center gap-4">
        {isLoading && (
          <div className="w-10 h-10 rounded-full bg-gray-100 animate-pulse flex-shrink-0"></div>
        )}
        {!isLoading && !isError && (
          <img
            src={profileData?.photo_profile ?? emptyProfilePicture}
            alt="Profile Picture"
            className="w-10 h-10 rounded-full flex-shrink-0"
          />
        )}
        <button
          onClick={() => setCreatePostPopupOpen((x) => !x)}
          className="px-4 w-full h-10 rounded-3xl bg-soft hover:bg-gray-300 text-disable2 text-left"
        >
          Create Post
        </button>
      </div>
      <Portal>
        <ForumCreatePostPopup
          createPostPopupOpen={createPostPopupOpen}
          toggleOpen={() => setCreatePostPopupOpen((x) => !x)}
          photoProfile={profileData?.photo_profile}
        />
      </Portal>
    </>
  );
}
export default ForumCreatePostButton;
