import moment from "moment";
import { Link } from "react-router-dom";

export default function MyCourseDetailHomeworkWidgetCard({ task }) {
  const deadline = moment().add(task.duration, "s").fromNow(true);

  return (
    <Link
      to={`homeworks/${task.courseTaskId}`}
      className="h-min p-4 bg-white hover:bg-gray-100 rounded-lg shadow-md"
    >
      <h4
        data-testid={`homework-widget-card-${task.courseTaskId}`}
        className="pb-3 text-[14px] font-bold"
      >
        {task.title}
      </h4>
      <p className="text-[14px] text-disable2">
        Deadline:{" "}
        <span className="ml-2 text-primary font-bold">{deadline}</span>
      </p>
    </Link>
  );
}
