import _ from "lodash";
import moment from "moment";
import { Link, useNavigate, useParams } from "react-router-dom";

function SchedulesMonthViewHeader(props) {
  const navigate = useNavigate();
  const { year, month } = useParams();
  const thisMonth = moment(`${year} ${month}`, "YYYY MM");

  return (
    <header>
      <h1 className="mb-6 text-2xl font-bold">Schedule</h1>
      <div className="mb-4 flex items-center justify-between">
        <h1 className="text-2xl text-text font-bold uppercase">
          {moment(month, "MM").format("MMMM")}{" "}
          {moment(year, "YYYY").format("YYYY")}
        </h1>
        <div className="flex items-center justify-end gap-4">
          <label>
            <span className="mr-4 hidden lg:inline-block text-sm">
              Pilih Bulan
            </span>
            <select
              name="month"
              id="month-selector"
              onChange={(event) =>
                navigate(
                  `/schedules/${moment(event.target.value, "MMMM-YYYY").format(
                    "YYYY"
                  )}/${moment(event.target.value, "MMMM-YYYY").format("MM")}`
                )
              }
              className="h-8 p-1.5 md:py-1 md:px-3 text-sm text-disable2 bg-white border border-gray-300 rounded-md"
            >
              {_.range(-12, 13).map((monthDiff) => (
                <option
                  value={moment(thisMonth)
                    .add(monthDiff, "month")
                    .format("MMMM-YYYY")}
                  selected={monthDiff === 0}
                  onChange
                >
                  {moment(thisMonth)
                    .add(monthDiff, "month")
                    .format("MMMM YYYY")}
                </option>
              ))}
              {/* TODO: opsi untuk bulan lainnya */}
            </select>
          </label>
          <div className="p-4 md:p-0 fixed md:static bottom-0 right-0 left-0 bg-white border-t md:border-none flex justify-end gap-2">
            <Link
              to={`/schedules/${moment(thisMonth)
                .add(-1, "month")
                .format("YYYY")}/${moment(thisMonth)
                .add(-1, "month")
                .format("MM")}`}
              className="p-1 rounded-full border hover:bg-gray-200 text-primary disabled:cursor-not-allowed disabled:text-gray-200 disabled:hover:text-current"
            >
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.0303 4.46967C16.2966 4.73594 16.3208 5.1526 16.1029 5.44621L16.0303 5.53033L9.561 12L16.0303 18.4697C16.2966 18.7359 16.3208 19.1526 16.1029 19.4462L16.0303 19.5303C15.7641 19.7966 15.3474 19.8208 15.0538 19.6029L14.9697 19.5303L7.96967 12.5303C7.7034 12.2641 7.6792 11.8474 7.89705 11.5538L7.96967 11.4697L14.9697 4.46967C15.2626 4.17678 15.7374 4.17678 16.0303 4.46967Z"
                  fill="currentColor"
                />
              </svg>
            </Link>
            <Link
              to={`/schedules/${moment(thisMonth)
                .add(1, "month")
                .format("YYYY")}/${moment(thisMonth)
                .add(1, "month")
                .format("MM")}`}
              className="p-1 rounded-full border hover:bg-gray-200 text-primary disabled:cursor-not-allowed disabled:text-gray-200 disabled:hover:text-current"
            >
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.96967 19.5303C7.7034 19.2641 7.6792 18.8474 7.89705 18.5538L7.96967 18.4697L14.439 12L7.96967 5.53033C7.7034 5.26406 7.6792 4.8474 7.89705 4.55379L7.96967 4.46967C8.23594 4.2034 8.6526 4.1792 8.94621 4.39705L9.03033 4.46967L16.0303 11.4697C16.2966 11.7359 16.3208 12.1526 16.1029 12.4462L16.0303 12.5303L9.03033 19.5303C8.73744 19.8232 8.26256 19.8232 7.96967 19.5303Z"
                  fill="currentColor"
                />
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
}
export default SchedulesMonthViewHeader;
