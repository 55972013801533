import SchedulesItemDetailTodayScheduleWidgetCard from "./SchedulesItemDetailTodayScheduleWidgetCard";

function SchedulesItemDetailTodayScheduleWidget(props) {
  return (
    <article className="p-4 rounded-md shadow-md bg-white">
      <header>
        <h3 className="mb-4 font-bold">12 Jan 2022 Schedule</h3>
        <div className="flex flex-col gap-3">
          {[1, 2].map((event) => (
            <>
              <SchedulesItemDetailTodayScheduleWidgetCard
                webinar
                event={{ id: event }}
              />
              <SchedulesItemDetailTodayScheduleWidgetCard
                event={{ id: event + 3 }}
              />
            </>
          ))}
        </div>
      </header>
    </article>
  );
}
export default SchedulesItemDetailTodayScheduleWidget;
