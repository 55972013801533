import CourseDetailHomeworkListCard from "./CourseDetailHomeworkListCard";

function CourseDetailHomeworkList({ tasks, disabled }) {
  return (
    <div className="flex flex-col gap-4">
      {tasks && tasks.map((task, i) => (
        <CourseDetailHomeworkListCard key={i} task={task} disabled={disabled} />
      ))}
    </div>
  );
}
export default CourseDetailHomeworkList;
