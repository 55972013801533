function HomeworkDetailEvidenceCard({ data }) {
  return (
    <article data-testid="evidence-card" className="p-4 rounded-md shadow-md flex gap-4">
      {data.img && (
        <img
          src={data.img}
          alt={data.altImg}
          className="w-20 h-20 flex-shrink-0 bg-gray-200 rounded-sm"
        />
      )}
      <div className="w-full flex flex-col justify-between">
        <p className="text-[14px] text-gray-400">
          Link:{" "}
          <a href={data.url} className="text-blue-500 underline">
            {data.url}
          </a>
        </p>
        <div className="w-4/5 grid grid-cols-2 gap-x-16">
          <div className="inline-flex items-center justify-between">
            <span className="text-[14px] text-gray-400">Interaction:</span>
            <span className="text-[14px] ">
              {new Intl.NumberFormat("id-ID").format(data.interaction)}
            </span>
          </div>
          <div className="inline-flex items-center justify-between">
            <span className="text-[14px] text-gray-400">Account Reach:</span>
            <span className="text-[14px] ">
              {new Intl.NumberFormat("id-ID").format(data.accountReach)}
            </span>
          </div>
          <div className="inline-flex items-center justify-between">
            <span className="text-[14px] text-gray-400">Likes:</span>
            <span className="text-[14px] ">
              {new Intl.NumberFormat("id-ID").format(data.likes)}
            </span>
          </div>
          <div className="inline-flex items-center justify-between">
            <span className="text-[14px] text-gray-400">Impression:</span>
            <span className="text-[14px] ">
              {new Intl.NumberFormat("id-ID").format(data.impression)}
            </span>
          </div>
        </div>
      </div>
      <div className="flex flex-col flex-shrink-0 ">
        <button className="p-1  rounded-md hover:bg-gray-200">
          <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M20.751 21.9397H13.498C13.084 21.9397 12.748 21.6037 12.748 21.1897C12.748 20.7757 13.084 20.4397 13.498 20.4397H20.751C21.165 20.4397 21.501 20.7757 21.501 21.1897C21.501 21.6037 21.165 21.9397 20.751 21.9397Z"
              fill="#FF9D25"
            />
            <mask
              id="mask0_823_6749"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x={2}
              y={3}
              width={18}
              height={19}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 3.00024H19.1806V21.9398H2V3.00024Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask0_823_6749)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.1103 5.01689L3.69527 16.7919C3.52427 17.0059 3.46127 17.2819 3.52427 17.5469L4.20527 20.4319L7.24427 20.3939C7.53327 20.3909 7.80027 20.2619 7.97727 20.0419C11.1943 16.0169 17.3273 8.34289 17.5013 8.11789C17.6653 7.85189 17.7293 7.47589 17.6433 7.11389C17.5553 6.74289 17.3243 6.42789 16.9913 6.22689C16.9203 6.17789 15.2353 4.86989 15.1833 4.82889C14.5493 4.32089 13.6243 4.40889 13.1103 5.01689ZM3.61327 21.9399C3.26627 21.9399 2.96427 21.7019 2.88327 21.3629L2.06427 17.8919C1.89527 17.1729 2.06327 16.4309 2.52427 15.8549L11.9443 4.07289C11.9483 4.06889 11.9513 4.06389 11.9553 4.05989C12.9883 2.82489 14.8563 2.64289 16.1163 3.65389C16.1663 3.69289 17.8393 4.99289 17.8393 4.99289C18.4473 5.35489 18.9223 6.00189 19.1023 6.76789C19.2813 7.52589 19.1513 8.30789 18.7343 8.96889C18.7033 9.01789 18.6763 9.05989 9.14827 20.9799C8.68927 21.5519 8.00127 21.8849 7.26227 21.8939L3.62327 21.9399H3.61327Z"
                fill="#FF9D25"
              />
            </g>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.2234 11.6849C16.0634 11.6849 15.9034 11.6339 15.7664 11.5299L10.3144 7.34187C9.98642 7.08987 9.92442 6.61987 10.1764 6.28987C10.4294 5.96187 10.8994 5.90087 11.2284 6.15287L16.6814 10.3399C17.0094 10.5919 17.0714 11.0629 16.8184 11.3919C16.6714 11.5839 16.4484 11.6849 16.2234 11.6849Z"
              fill="#FF9D25"
            />
          </svg>
        </button>
      </div>
    </article>
  );
}
export default HomeworkDetailEvidenceCard;
