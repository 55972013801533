import { useState } from "react";

function TextField({
  className,
  label,
  type,
  disabled,
  placeholder,
  ...restOfProps
}) {
  const [isFocus, setIsFocus] = useState(false);
  const [isError, setIsError] = useState(false);

  return (
    <>
      <label
        className={`h-14 px-3 py-2 rounded-md flex flex-col-reverse justify-center cursor-text hover:bg-gray-50 transition-colors duration-75 border ${
          isFocus ? "border-primary" : "border-gray-500"
        } ${isError? "border-error" : ""} ${className}`}
      >
        <input
          className="peer bg-transparent h-5 focus:h-5 placeholder-shown:h-0 outline-none placeholder-transparent transition-[height] duration-150"
          type={type}
          placeholder={placeholder}
          onFocus={() => setIsFocus(true)}
          onBlur={() => setIsFocus(false)}
          onInvalid={() => setIsError(true)}
          {...restOfProps}
        />
        <span className="text-gray-500 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:peer-focus:text-sm transition-all duration-150 peer-required:after:content-['*'] peer-required:after:text-error peer-required:after:ml-1">
          {label}
        </span>
      </label>
    </>
  );
}
export default TextField;
