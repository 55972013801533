import React from "react";
import HomeworksListCard from "./HomeworksListCard";

export default function HomeworksList({ tasks }) {
  return (
    <section className="grid grid-cols-3 gap-4">
      {tasks?.map((task, i) => (
        <HomeworksListCard key={i} task={task} />
      ))}
    </section>
  );
}
