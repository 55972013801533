import axios from "axios";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import GridLayout from "../../components/GridLayout";
import Header from "../../components/Header";
import Main from "../../components/Main";
import SidebarLeft from "../../components/SidebarLeft";
import Nav from "../../components/Nav";
import SidebarRight from "../../components/SidebarRight";
import HomeworksList from "./components/HomeworksList";
import {
  getBloggerCourseDetail,
  getBloggerTaskList,
} from "../../services/bloggerCourseService";

export default function Homeworks() {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [bloggerCourseTaskData, setBloggerCourseTaskData] = useState();
  const [bloggerCourseDetailData, setBloggerCourseDetailData] = useState({});

  const { bloggerCourseId } = useParams();

  useEffect(() => {
    getBloggerCourseTaskData();
    getBloggerCourseDetailData();
  }, []);

  const getBloggerCourseDetailData = async () => {
    try {
      setIsError(false);
      setIsLoading(true);
      setBloggerCourseDetailData(await getBloggerCourseDetail(bloggerCourseId));
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const getBloggerCourseTaskData = async () => {
    try {
      setIsLoading(true);
      console.log("bebek");
      setBloggerCourseTaskData(await getBloggerTaskList({ bloggerCourseId }));
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Homeworks</title>
      </Helmet>
      <Header />
      <GridLayout>
        <SidebarLeft>
          <Nav />
        </SidebarLeft>
        <Main>
          {!isLoading && !isError && (
            <>
              <Breadcrumb
                hierarchy={[
                  {
                    depth: 1,
                    name: "My Course",
                    url: "/courses/me/all",
                    isCurrent: false,
                  },
                  {
                    depth: 2,
                    name: bloggerCourseDetailData?.title,
                    url: `/courses/me/${bloggerCourseId}`,
                    isCurrent: false,
                  },
                  {
                    depth: 3,
                    name: "Home work",
                    url: `/courses/me/${bloggerCourseId}/homeworks`,
                    isCurrent: true,
                  },
                ]}
              />
              <h1 className="my-4 text-2xl text-text font-bold">Home Work</h1>
              <HomeworksList tasks={bloggerCourseTaskData} />
            </>
          )}
        </Main>
        <SidebarRight></SidebarRight>
      </GridLayout>
    </>
  );
}
