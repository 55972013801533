function SchedulesItemDetailSkeleton(props) {
  return (
    <div className="animate-pulse">
      <div className="mt-2 w-48 h-4 mb-4 bg-gray-100"></div>
      <div className="w-24 h-8 mb-5 bg-gray-100"></div>
      <div className="mb-4 flex items-stretch gap-4">
        <div className="w-28 h-28 lg:w-40 lg:h-40 flex-shrink-0 rounded-md bg-gray-100"></div>
        <div className="py-2 flex flex-col justify-between">
          <div className="w-32 h-4 bg-gray-100"></div>
          <div className="w-32 h-4 bg-gray-100"></div>
          <div className="w-32 h-4 bg-gray-100"></div>
          <div className="w-32 h-4 bg-gray-100"></div>
        </div>
      </div>
      <div className="w-24 h-6 mt-4 mb-5 bg-gray-100"></div>
      <div className="flex flex-col gap-4">
        <div className="w-full h-4 bg-gray-100"></div>
        <div className="w-full h-4 bg-gray-100"></div>
        <div className="w-full h-4 bg-gray-100"></div>
        <div className="w-full h-4 bg-gray-100"></div>
      </div>
    </div>
  );
}
export default SchedulesItemDetailSkeleton;
