import { Field, Form, Formik } from "formik";
import { useState, useEffect } from "react";
import Button from "../../../components/Button";
import { postForum } from "../../../services/forumService";
import { useNavigate } from "react-router-dom";
import emptyProfilePicture from "../../../assets/empty-profile-picture.svg";
import { useDropzone } from "react-dropzone";
import PictureIcon from "../../../icons/PictureIcon";
import XIcon from "../../../icons/XIcon";
import _ from "lodash";

function ForumCreatePostPopup({
  createPostPopupOpen = false,
  toggleOpen = () => {},
  photoProfile,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles((prevFile) => [
        ...prevFile,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    },
  });
  const navigate = useNavigate();

  const initialValues = {
    postContent: "",
  };

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  const validate = (values) => {
    const errors = {};

    if (!values.postContent) {
      errors.postContent = "Comment cannot be empty.";
    }

    return errors;
  };

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    let forumId;
    try {
      setIsError(false);
      setIsLoading(true);
      forumId = (await postForum({ message: values.postContent })).forumId;
      resetForm();
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
      navigate(`/forum/posts/${forumId}`);
      toggleOpen();
    }
  };

  return createPostPopupOpen ? (
    <Formik {...{ initialValues, validate, onSubmit }}>
      {() => (
        <div
          onClick={toggleOpen}
          className="w-screen h-screen fixed inset-0 z-50 bg-black bg-opacity-40 flex items-end sm:items-center justify-center"
        >
          <article
            onClick={(event) => {
              event.stopPropagation();
            }}
            className="w-full sm:w-[640px] p-4 sm:rounded-2xl shadow-md bg-white flex flex-col gap-4"
          >
            <header className="flex items-center gap-4">
              <img
                className="w-10 h-10 rounded-full"
                src={photoProfile ?? emptyProfilePicture}
                alt="Profile Picture"
              />
              <h3 className="mr-auto text-2xl text-text font-bold">
                Create Post
              </h3>
              <button
                onClick={toggleOpen}
                className="p-1 rounded-md bg-white hover:bg-gray-200"
              >
                <XIcon />
              </button>
            </header>
            <Form className="rounded-xl border border-soft overflow-hidden flex flex-col">
              <Field name="postContent">
                {({ field, form: { touched, errors }, meta }) => (
                  <textarea
                    {...field}
                    placeholder="Tell your story"
                    disabled={isLoading}
                    className="h-64 p-4 flex-shrink-0 bg-soft outline-none"
                  ></textarea>
                )}
              </Field>
              <div className="p-2 flex items-center justify-between gap-4">
                <div className="flex items-center overflow-x-auto">
                  <div className="flex items-start flex-nowrap gap-4">
                    <div
                      {...getRootProps({
                        className: "p-1 rounded-md bg-white hover:bg-gray-200",
                      })}
                    >
                      <input {...getInputProps()} />
                      <PictureIcon />
                    </div>
                    {files.map((file, i) => {
                      console.log({ file });
                      return (
                        <>
                          <div className="flex">
                            <img
                              key={i}
                              src={file.preview}
                              className="w-20 h-20 bg-disable flex-shrink-0 rounded-md object-cover"
                            />
                            <div className="w-0 h-0 overflow-visible">
                              <button
                                onClick={() =>
                                  setFiles((prevFiles) =>
                                    prevFiles.filter((x, j) => i != j)
                                  )
                                }
                                className="-ml-8 mt-2 p-1 relative bg-text text-white rounded-full"
                              >
                                <XIcon className="w-4 h-4 stroke-white fill-white" />
                              </button>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
                <Button
                  type="submit"
                  className="text-sm flex-shrink-0"
                  disabled={isLoading}
                >
                  Post
                </Button>
              </div>
            </Form>
          </article>
        </div>
      )}
    </Formik>
  ) : (
    <></>
  );
}
export default ForumCreatePostPopup;
