import LandingPageLeaderboardFAQQuestion from "./LandingPageLeaderboardFAQQuestion";

function LandingPageLeaderboardFAQ(props) {
  return (
    <>
      <section className="">
        <div className="max-w-full md:max-w-lg lg:max-w-5xl mx-auto p-6">
          <article className="py-6 shadow-md rounded-3xl bg-white font-sans-landing flex flex-col items-center gap-8">
            <h1 className="text-2xl text-text font-bold">FAQ Leaderboard</h1>
            <div className="w-full flex flex-col">
              <LandingPageLeaderboardFAQQuestion
                question="Question 1"
                answer="Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores corrupti deleniti reprehenderit facere quidem nesciunt nobis architecto aperiam! Excepturi quidem assumenda debitis nesciunt, aliquam ipsa, in magnam cupiditate nobis voluptate, repellendus asperiores! Quod saepe amet iusto. Nobis, doloremque expedita. Cumque?"
              />
              <LandingPageLeaderboardFAQQuestion
                question="Question 1"
                answer="Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores corrupti deleniti reprehenderit facere quidem nesciunt nobis architecto aperiam! Excepturi quidem assumenda debitis nesciunt, aliquam ipsa, in magnam cupiditate nobis voluptate, repellendus asperiores! Quod saepe amet iusto. Nobis, doloremque expedita. Cumque?"
              />
              <LandingPageLeaderboardFAQQuestion
                question="Question 1"
                answer="Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores corrupti deleniti reprehenderit facere quidem nesciunt nobis architecto aperiam! Excepturi quidem assumenda debitis nesciunt, aliquam ipsa, in magnam cupiditate nobis voluptate, repellendus asperiores! Quod saepe amet iusto. Nobis, doloremque expedita. Cumque?"
              />
              <LandingPageLeaderboardFAQQuestion
                question="Question 1"
                answer="Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores corrupti deleniti reprehenderit facere quidem nesciunt nobis architecto aperiam! Excepturi quidem assumenda debitis nesciunt, aliquam ipsa, in magnam cupiditate nobis voluptate, repellendus asperiores! Quod saepe amet iusto. Nobis, doloremque expedita. Cumque?"
              />
              <LandingPageLeaderboardFAQQuestion
                question="Question 1"
                answer="Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores corrupti deleniti reprehenderit facere quidem nesciunt nobis architecto aperiam! Excepturi quidem assumenda debitis nesciunt, aliquam ipsa, in magnam cupiditate nobis voluptate, repellendus asperiores! Quod saepe amet iusto. Nobis, doloremque expedita. Cumque?"
              />
              <LandingPageLeaderboardFAQQuestion
                question="Question 1"
                answer="Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores corrupti deleniti reprehenderit facere quidem nesciunt nobis architecto aperiam! Excepturi quidem assumenda debitis nesciunt, aliquam ipsa, in magnam cupiditate nobis voluptate, repellendus asperiores! Quod saepe amet iusto. Nobis, doloremque expedita. Cumque?"
              />
            </div>
          </article>
        </div>
      </section>
    </>
  );
}
export default LandingPageLeaderboardFAQ;
