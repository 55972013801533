function OnlineCourseIcon(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7161 16.9736H8.49609C8.08209 16.9736 7.74609 16.6376 7.74609 16.2236C7.74609 15.8096 8.08209 15.4736 8.49609 15.4736H15.7161C16.1301 15.4736 16.4661 15.8096 16.4661 16.2236C16.4661 16.6376 16.1301 16.9736 15.7161 16.9736"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7161 12.7871H8.49609C8.08209 12.7871 7.74609 12.4511 7.74609 12.0371C7.74609 11.6231 8.08209 11.2871 8.49609 11.2871H15.7161C16.1301 11.2871 16.4661 11.6231 16.4661 12.0371C16.4661 12.4511 16.1301 12.7871 15.7161 12.7871"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2501 8.61035H8.49512C8.08112 8.61035 7.74512 8.27435 7.74512 7.86035C7.74512 7.44635 8.08112 7.11035 8.49512 7.11035H11.2501C11.6641 7.11035 12.0001 7.44635 12.0001 7.86035C12.0001 8.27435 11.6641 8.61035 11.2501 8.61035"
        fill="white"
      />
      <mask
        id="mask0_679_5482"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x={3}
        y={2}
        width={19}
        height={20}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 2H21.1647V21.9098H3V2Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_679_5482)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.909 3.5L8.22 3.504C5.892 3.518 4.5 4.958 4.5 7.357V16.553C4.5 18.968 5.905 20.41 8.256 20.41L15.945 20.407C18.273 20.393 19.665 18.951 19.665 16.553V7.357C19.665 4.942 18.261 3.5 15.909 3.5ZM8.257 21.91C5.113 21.91 3 19.757 3 16.553V7.357C3 4.124 5.047 2.023 8.215 2.004L15.908 2H15.909C19.053 2 21.165 4.153 21.165 7.357V16.553C21.165 19.785 19.118 21.887 15.95 21.907L8.257 21.91Z"
          fill="white"
        />
      </g>
    </svg>
  );
}
export default OnlineCourseIcon;
