import moment from "moment";

function SchedulesItemDetailDetails({data}) {
  return (
    <section className="mb-4 flex items-start gap-4">
      {data.image && (
        <img
          src={data.image}
          alt=""
          className="w-28 h-28 lg:w-40 lg:h-40 flex-shrink-0 rounded-md shadow-md object-cover"
        />
      )}
      <div className="flex flex-col gap-2">
        <div className="inline-flex items-center gap-2.5 text-sm">
          <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M20.6663 9.9043H2.84229C2.42829 9.9043 2.09229 9.5683 2.09229 9.1543C2.09229 8.7403 2.42829 8.4043 2.84229 8.4043H20.6663C21.0803 8.4043 21.4163 8.7403 21.4163 9.1543C21.4163 9.5683 21.0803 9.9043 20.6663 9.9043Z"
              fill="#FF9D25"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.2013 13.8096C15.7873 13.8096 15.4473 13.4736 15.4473 13.0596C15.4473 12.6456 15.7783 12.3096 16.1923 12.3096H16.2013C16.6153 12.3096 16.9513 12.6456 16.9513 13.0596C16.9513 13.4736 16.6153 13.8096 16.2013 13.8096Z"
              fill="#FF9D25"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.7638 13.8096C11.3498 13.8096 11.0098 13.4736 11.0098 13.0596C11.0098 12.6456 11.3408 12.3096 11.7548 12.3096H11.7638C12.1778 12.3096 12.5138 12.6456 12.5138 13.0596C12.5138 13.4736 12.1778 13.8096 11.7638 13.8096Z"
              fill="#FF9D25"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.31701 13.8096C6.90301 13.8096 6.56201 13.4736 6.56201 13.0596C6.56201 12.6456 6.89401 12.3096 7.30801 12.3096H7.31701C7.73101 12.3096 8.06701 12.6456 8.06701 13.0596C8.06701 13.4736 7.73101 13.8096 7.31701 13.8096Z"
              fill="#FF9D25"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.2013 17.6963C15.7873 17.6963 15.4473 17.3603 15.4473 16.9463C15.4473 16.5323 15.7783 16.1963 16.1923 16.1963H16.2013C16.6153 16.1963 16.9513 16.5323 16.9513 16.9463C16.9513 17.3603 16.6153 17.6963 16.2013 17.6963Z"
              fill="#FF9D25"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.7638 17.6963C11.3498 17.6963 11.0098 17.3603 11.0098 16.9463C11.0098 16.5323 11.3408 16.1963 11.7548 16.1963H11.7638C12.1778 16.1963 12.5138 16.5323 12.5138 16.9463C12.5138 17.3603 12.1778 17.6963 11.7638 17.6963Z"
              fill="#FF9D25"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.31701 17.6963C6.90301 17.6963 6.56201 17.3603 6.56201 16.9463C6.56201 16.5323 6.89401 16.1963 7.30801 16.1963H7.31701C7.73101 16.1963 8.06701 16.5323 8.06701 16.9463C8.06701 17.3603 7.73101 17.6963 7.31701 17.6963Z"
              fill="#FF9D25"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.7935 5.791C15.3795 5.791 15.0435 5.455 15.0435 5.041V1.75C15.0435 1.336 15.3795 1 15.7935 1C16.2075 1 16.5435 1.336 16.5435 1.75V5.041C16.5435 5.455 16.2075 5.791 15.7935 5.791Z"
              fill="#FF9D25"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.71533 5.791C7.30133 5.791 6.96533 5.455 6.96533 5.041V1.75C6.96533 1.336 7.30133 1 7.71533 1C8.12933 1 8.46533 1.336 8.46533 1.75V5.041C8.46533 5.455 8.12933 5.791 7.71533 5.791Z"
              fill="#FF9D25"
            />
            <mask
              id="mask0_1319_13162"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x={2}
              y={2}
              width={20}
              height={21}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 2.5791H21.5V22.5H2V2.5791Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask0_1319_13162)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.521 4.0791C4.928 4.0791 3.5 5.4621 3.5 7.9731V17.0221C3.5 19.5881 4.928 21.0001 7.521 21.0001H15.979C18.572 21.0001 20 19.6141 20 17.0981V7.9731C20.004 6.7381 19.672 5.7781 19.013 5.1181C18.335 4.4381 17.29 4.0791 15.988 4.0791H7.521ZM15.979 22.5001H7.521C4.116 22.5001 2 20.4011 2 17.0221V7.9731C2 4.6451 4.116 2.5791 7.521 2.5791H15.988C17.697 2.5791 19.11 3.0911 20.075 4.0581C21.012 4.9991 21.505 6.3521 21.5 7.9751V17.0981C21.5 20.4301 19.384 22.5001 15.979 22.5001Z"
                fill="#FF9D25"
              />
            </g>
          </svg>
          <span>{moment(data.dueDate).format("D MMMM YYYY")}</span>
        </div>
        <div className="inline-flex items-center gap-2.5 text-sm">
          <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 3.5C7.313 3.5 3.5 7.313 3.5 12C3.5 16.687 7.313 20.5 12 20.5C16.687 20.5 20.5 16.687 20.5 12C20.5 7.313 16.687 3.5 12 3.5ZM12 22C6.486 22 2 17.514 2 12C2 6.486 6.486 2 12 2C17.514 2 22 6.486 22 12C22 17.514 17.514 22 12 22Z"
              fill="#FF9D25"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.4311 15.6924C15.3001 15.6924 15.1681 15.6584 15.0471 15.5874L11.2771 13.3384C11.0511 13.2024 10.9111 12.9574 10.9111 12.6934V7.8454C10.9111 7.4314 11.2471 7.0954 11.6611 7.0954C12.0761 7.0954 12.4111 7.4314 12.4111 7.8454V12.2674L15.8161 14.2974C16.1711 14.5104 16.2881 14.9704 16.0761 15.3264C15.9351 15.5614 15.6861 15.6924 15.4311 15.6924Z"
              fill="#FF9D25"
            />
          </svg>
          {data.startTime && data.endTime ? (
            <span>{`${data.startTime} - ${data.endTime}`}</span>
          ) : (
            <span>{moment(data.dueDate).format("hh.mm")}</span>
          )}
        </div>
        {data.location && <div className="inline-flex items-center gap-2.5 text-sm">
          <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.2505 8.5C11.2855 8.5 10.5005 9.285 10.5005 10.251C10.5005 11.216 11.2855 12 12.2505 12C13.2155 12 14.0005 11.216 14.0005 10.251C14.0005 9.285 13.2155 8.5 12.2505 8.5ZM12.2505 13.5C10.4585 13.5 9.00049 12.043 9.00049 10.251C9.00049 8.458 10.4585 7 12.2505 7C14.0425 7 15.5005 8.458 15.5005 10.251C15.5005 12.043 14.0425 13.5 12.2505 13.5Z"
              fill="#FF9D25"
            />
            <mask
              id="mask0_1319_13159"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x={4}
              y={2}
              width={17}
              height={20}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 2H20.4995V21.5H4V2Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask0_1319_13159)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.2495 3.5C8.52751 3.5 5.49951 6.557 5.49951 10.313C5.49951 15.092 11.1235 19.748 12.2495 19.996C13.3755 19.747 18.9995 15.091 18.9995 10.313C18.9995 6.557 15.9715 3.5 12.2495 3.5ZM12.2495 21.5C10.4555 21.5 3.99951 15.948 3.99951 10.313C3.99951 5.729 7.70051 2 12.2495 2C16.7985 2 20.4995 5.729 20.4995 10.313C20.4995 15.948 14.0435 21.5 12.2495 21.5Z"
                fill="#FF9D25"
              />
            </g>
          </svg>

          <span>{data.location}</span>
        </div>}
        {data.participants && <div className="inline-flex items-center gap-2.5 text-sm">
          <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask
              id="mask0_1319_13165"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x={4}
              y={14}
              width={16}
              height={8}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 14.4961H19.8399V21.8701H4V14.4961Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask0_1319_13165)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.921 15.9961C7.66 15.9961 5.5 16.7281 5.5 18.1731C5.5 19.6311 7.66 20.3701 11.921 20.3701C16.181 20.3701 18.34 19.6381 18.34 18.1931C18.34 16.7351 16.181 15.9961 11.921 15.9961ZM11.921 21.8701C9.962 21.8701 4 21.8701 4 18.1731C4 14.8771 8.521 14.4961 11.921 14.4961C13.88 14.4961 19.84 14.4961 19.84 18.1931C19.84 21.4891 15.32 21.8701 11.921 21.8701Z"
                fill="#FF9D25"
              />
            </g>
            <mask
              id="mask1_1319_13165"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x={6}
              y={2}
              width={12}
              height={11}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.60986 2.0001H17.2299V12.6187H6.60986V2.0001Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask1_1319_13165)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.9209 3.4277C9.77989 3.4277 8.03789 5.1687 8.03789 7.3097C8.03089 9.4437 9.75989 11.1837 11.8919 11.1917L11.9209 11.9057V11.1917C14.0609 11.1917 15.8019 9.4497 15.8019 7.3097C15.8019 5.1687 14.0609 3.4277 11.9209 3.4277ZM11.9209 12.6187H11.8889C8.9669 12.6097 6.59989 10.2267 6.60989 7.3067C6.60989 4.3817 8.99189 1.9997 11.9209 1.9997C14.8489 1.9997 17.2299 4.3817 17.2299 7.3097C17.2299 10.2377 14.8489 12.6187 11.9209 12.6187Z"
                fill="#FF9D25"
              />
            </g>
          </svg>
          <span>
            
            {new Intl.NumberFormat("id-ID", {
              notation: "compact",
              maximumFractionDigits: 1,
            }).format(data.participants)}{" "}
            Participants
          </span>
        </div>}
      </div>
    </section>
  );
}
export default SchedulesItemDetailDetails;
