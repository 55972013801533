import LandingPageHeader from "../../../components/LandingPageHeader";

function LandingPageAboutProgramHero(props) {
  return (
    <section className="">
      <div className="px-4 py-16 max-w-full md:max-w-lg lg:max-w-5xl mx-auto flex flex-col-reverse text-center lg:text-left lg:flex-row justify-center gap-16 lg:gap-4">
        <article className="text-white flex flex-col justify-center">
          <h1 className="mb-10 text-2xl lg:text-4xl font-bold font-sans-landing">
            About Program
          </h1>
          <p className="text-sm lg:text-base font-sans-landing">
            Blogger Development Program merupakan program pengembangan menjadi
            blogger profesional, yang berfokus pada peningkatan dan penguatan
            tulisan blogger serta model bisnis blogger untuk menjadikan blogger
            sebagai agen pembawa viral
          </p>
        </article>
        <iframe
          src="https://www.youtube-nocookie.com/embed/dQw4w9WgXcQ"
          title="YouTube video player"
          frameBorder={0}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="w-full lg:w-auto lg:h-80 flex-1 flex-shrink-0 aspect-video border-8 border-white rounded-2xl"
        />
      </div>
    </section>
  );
}
export default LandingPageAboutProgramHero;
