import axios from "axios";
import { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import useReactiveSearchParams from "../../../utils/useReactiveSearchParams";
import UpdatesFeedCard from "./UpdatesFeedCard";
import UpdatesFeedSkeleton from "./UpdatesFeedSkeleton";

function UpdatesFeed(props) {
  const [meta, setMeta] = useState({
    total: 0,
    perPage: 0,
    page: 0,
    lastPage: 0,
  });
  const [feedData, setFeedData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [page, setPage] = useState(1);

  const getFeedData = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        "https://dev-api-cms.kmbmedia.id/api/v1/public/posts",
        {
          params: {
            page,
            perPage: 10,
          },
        }
      );
      const { data } = response.data;
      setFeedData((prevData) => [...prevData, ...data]);
      setMeta({
        total: response.data.total,
        perPage: response.data.perPage,
        page: response.data.page,
        lastPage: response.data.lastPage,
      });
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getFeedData();
  }, [, page]);

  return (
    <>
      <InfiniteScroll
        dataLength={feedData.length}
        next={() => setPage((x) => x + 1)}
        hasMore={page !== meta.lastPage}
        loader={
          <>
            <UpdatesFeedSkeleton />
            <UpdatesFeedSkeleton />
            <UpdatesFeedSkeleton />
            <UpdatesFeedSkeleton />
          </>
        }
        className="p-2 flex flex-col gap-4"
      >
        {feedData.map((feedItem, i) => (
          <UpdatesFeedCard key={i} feedItem={feedItem} />
        ))}
      </InfiniteScroll>
    </>
  );
}
export default UpdatesFeed;
