import { Link } from "react-router-dom";
import MyCourseDetailHomeworkWidgetCard from "./MyCourseDetailHomeworkWidgetCard";

function MyCourseDetailHomeworkWidget({ tasks }) {
  return tasks ? (
    <article className="h-min p-4 bg-white rounded-lg shadow-md flex flex-col gap-2">
      <header className="flex items-center justify-between">
        <h3 className="text-lg text-gray-800 font-bold">Homework</h3>
        <Link
          to="homeworks"
          className="p-1 rounded-md hover:bg-gray-200 text-sm text-primary font-bold"
        >
          View All
        </Link>
      </header>
      <div className="flex flex-col gap-4">
        {tasks.map((task, i) => (
          <MyCourseDetailHomeworkWidgetCard key={i} task={task} />
        ))}
      </div>
    </article>
  ) : (
    <></>
  );
}
export default MyCourseDetailHomeworkWidget;
