import Button from "./Button";
import bannerBg from "../assets/landing-page-register-pdp-banner-bg.svg";

function LandingPageRegisterPDPBanner(props) {
  return (
    <section className="">
      <div className="p-6 max-w-full md:max-w-lg lg:max-w-5xl mx-auto">
        <article
          className="mb-12 p-8 lg:p-16 rounded-3xl flex flex-col lg:flex-row items-center justify-between gap-24 lg:gap-32 font-sans-landing"
          style={{
            backgroundImage: `url(${bannerBg}), linear-gradient(154.44deg, #F47902 -9.21%, #FF9026 16.15%, #FFA045 52.04%, #FFAA18 82.67%)`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "140%",
          }}
        >
          <p className="text-2xl text-white font-bold">
            Tingkatkan skill digital kamu sekarang dengan mengikuti program PDP
          </p>
          <Button className="border-white bg-white text-text font-bold font-sans-landing flex-shrink-0">
            Daftar Sekarang
          </Button>
        </article>
      </div>
    </section>
  );
}
export default LandingPageRegisterPDPBanner;
