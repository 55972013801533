import _ from "lodash";
import { createContext, useState, useEffect } from "react";
import { getSurveyQuestions, postAnswers } from "../services/surveyService";
import { getProfile } from "../services/profileService";
import useError from "../utils/useError";
import { useNavigate } from "react-router-dom";

export const SurveyContext = createContext();

const SurveyContextProvider = (props) => {
  const [surveyQuestionsData, setSurveyQuestionsData] = useState();
  const [surveyAnswers, setSurveyAnswers] = useState([]);
  const [surveyResults, setSurveyResults] = useState();
  const [surveyResultProfile, setSurveyResultProfile] = useState();
  const [surveyIsCompleted, setSurveyIsCompleted] = useState(false);
  const [courseRecommendations, setCourseRecommendations] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { error, setError, isError } = useError();
  const navigate = useNavigate();

  const getSurveyQuestionsData = async () => {
    try {
      setIsLoading(true);
      setSurveyQuestionsData(await getSurveyQuestions());
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const submitSurveyAnswers = async () => {
    try {
      setIsLoading(true);
      setSurveyResults(
        (await postAnswers({ answers: surveyAnswers })).suggestions
      );
      setSurveyIsCompleted(true);
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const checkSurveyIsComplete = async () => {
    try {
      setIsLoading(true);
      setSurveyIsCompleted((await getProfile()).data.is_complete_survey);
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const captureAnswers = (answers) => {
    setSurveyAnswers((prevAnswers) => [
      ...(prevAnswers ?? []).filter(
        (answer) =>
          // If item with the same questionId exists remove the previous element and replace it with newest element
          prevAnswers.findIndex((e) => e.questionId === Number(answer[0])) !==
          -1
      ),
      ...Object.entries(answers).map((answer) => ({
        questionId: Number(answer[0]),
        answerId: [Number(answer[1])],
      })),
    ]);
  };

  useEffect(() => {
    checkSurveyIsComplete();
  }, []);

  return (
    <SurveyContext.Provider
      value={{
        getSurveyQuestionsData,
        surveyQuestions: surveyQuestionsData,
        surveyError: error,
        surveyIsError: isError,
        surveyIsLoading: isLoading,
        surveyResults,
        surveyResultProfile,
        surveyAnswers,
        captureAnswers,
        surveyIsCompleted,
        submitSurveyAnswers,
        courseRecommendations,
      }}
    >
      {props.children}
    </SurveyContext.Provider>
  );
};

export default SurveyContextProvider;
