import SidebarLeft from "../../components/SidebarLeft";
import Main from "../../components/Main";
import SidebarRight from "../../components/SidebarRight";
import Header from "../../components/Header";
import Nav from "../../components/Nav";
import GridLayout from "../../components/GridLayout";
import LaunchpadMissionDetailHeader from "./components/LaunchpadMissionDetailHeader";
import { useParams } from "react-router-dom";
import LaunchpadMissionDetailContent from "./components/LaunchpadMissionDetailContent";
import LaunchpadMissionDetailEvidenceSubmissionWidget from "./components/LaunchpadMissionDetailEvidenceSubmissionWidget";
import { useEffect, useState } from "react";
import { getLaunchpadDetail } from "../../services/launchpadService";
import LaunchpadMissionDetailSkeleton from "./components/LaunchpadMissionDetailSkeleton";

function LaunchpadMissionDetail(props) {
  const { bloggerLaunchpadId } = useParams();
  const [launchpadDetailData, setLaunchpadDetailData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    getLaunchpadDetailData();
  }, []);

  const getLaunchpadDetailData = async () => {
    try {
      setIsLoading(true);
      setLaunchpadDetailData(
        (await getLaunchpadDetail(bloggerLaunchpadId)).launchpad
      );
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Header />
      <GridLayout>
        <SidebarLeft>
          <Nav />
        </SidebarLeft>
        {isLoading && (
          <Main>
            <LaunchpadMissionDetailSkeleton />
          </Main>
        )}
        {!isLoading && !isError && (
          <Main>
            <LaunchpadMissionDetailHeader data={launchpadDetailData} />
            <LaunchpadMissionDetailContent content={launchpadDetailData} />
          </Main>
        )}
        {isError && (
          <Main>
            <LaunchpadMissionDetailHeader />
            <div className="h-48 text-red-800 text-sm flex items-center justify-center">Gagal memperoleh informasi</div>
          </Main>
        )}
        <SidebarRight>
          {!isLoading && !isError && (
            <LaunchpadMissionDetailEvidenceSubmissionWidget
              evidence={launchpadDetailData.evidence}
              type={launchpadDetailData.type}
            />
          )}
        </SidebarRight>
      </GridLayout>
    </>
  );
}
export default LaunchpadMissionDetail;
