import Button from "../../../components/Button";

const SubmissionIllustration = () => (
  <svg
    width={160}
    height={160}
    viewBox="0 0 160 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M148.627 35.7056L147.726 34.223C140.13 21.7031 126.393 14.1569 111.909 15.0753C110.049 15.1932 108.2 15.4465 106.376 15.8332C93.2094 18.6548 81.7573 28.6076 77.0049 41.3598C74.2387 48.8617 73.431 57.4978 68.1531 63.4507C61.2542 71.196 49.5421 71.4394 39.2463 71.1186C28.9504 70.7977 17.2328 71.0688 10.3726 78.8639C6.07394 83.738 4.70744 90.6646 5.05045 97.1873C5.96882 114.459 18.0018 129.9 33.31 137.574C48.6181 145.247 66.6317 145.939 83.3838 142.553C106.847 137.823 129.164 124.849 142.581 104.767C155.997 84.684 159.36 57.365 148.627 35.7056Z"
      fill="#FFEAD0"
    />
    <path
      d="M45.3611 53.3429C51.4048 53.3429 56.3042 48.4435 56.3042 42.3998C56.3042 36.3561 51.4048 31.4567 45.3611 31.4567C39.3174 31.4567 34.418 36.3561 34.418 42.3998C34.418 48.4435 39.3174 53.3429 45.3611 53.3429Z"
      fill="#FFEAD0"
    />
    <path
      d="M47.036 134.664C45.5388 134.658 44.1049 134.059 43.0483 132.998C41.9917 131.937 41.3984 130.501 41.3984 129.004V79.7988C41.4417 78.5568 41.9755 77.3825 42.8829 76.5333C43.7902 75.684 44.9971 75.2289 46.2393 75.2678H50.8644C51.7744 75.2859 52.6713 75.0488 53.4536 74.5833C54.2358 74.1179 54.8721 73.4428 55.2903 72.6343L56.2806 70.615C56.6968 69.8108 57.3287 69.1385 58.1056 68.6733C58.8824 68.2082 59.7735 67.9687 60.6789 67.9816H82.637C83.4621 67.9709 84.2767 68.168 85.0057 68.5547C85.7346 68.9414 86.3546 69.5053 86.8084 70.1945L87.8872 71.904C88.3402 72.5928 88.9592 73.1565 89.6872 73.5432C90.4152 73.93 91.2289 74.1273 92.0532 74.117H120.047C121.291 74.0767 122.499 74.5321 123.407 75.3828C124.315 76.2336 124.848 77.4101 124.888 78.6536V128.998C124.888 130.5 124.292 131.939 123.23 133C122.169 134.062 120.729 134.658 119.228 134.658L47.036 134.664Z"
      fill="#FF9D25"
    />
    <g opacity="0.3">
      <path
        opacity="0.3"
        d="M47.036 134.664C45.5388 134.658 44.1049 134.059 43.0483 132.998C41.9917 131.937 41.3984 130.501 41.3984 129.004V79.7988C41.4417 78.5568 41.9755 77.3825 42.8829 76.5333C43.7902 75.684 44.9971 75.2289 46.2393 75.2678H50.8644C51.7744 75.2859 52.6713 75.0488 53.4536 74.5833C54.2358 74.1179 54.8721 73.4428 55.2903 72.6343L56.2806 70.615C56.6968 69.8108 57.3287 69.1385 58.1056 68.6733C58.8824 68.2082 59.7735 67.9687 60.6789 67.9816H82.637C83.4621 67.9709 84.2767 68.168 85.0057 68.5547C85.7346 68.9414 86.3546 69.5053 86.8084 70.1945L87.8872 71.904C88.3402 72.5928 88.9592 73.1565 89.6872 73.5432C90.4152 73.93 91.2289 74.1273 92.0532 74.117H120.047C121.291 74.0767 122.499 74.5321 123.407 75.3828C124.315 76.2336 124.848 77.4101 124.888 78.6536V128.998C124.888 130.5 124.292 131.939 123.23 133C122.169 134.062 120.729 134.658 119.228 134.658L47.036 134.664Z"
        fill="black"
      />
    </g>
    <path
      d="M89.2433 74.1447L71.429 74.106L69.8412 71.1295C69.3013 70.1633 68.5105 69.3607 67.5523 68.8066C66.5941 68.2526 65.5041 67.9675 64.3973 67.9816H46.9868C43.8887 67.9816 41.377 70.3328 41.377 73.2374V128.694C41.377 132.014 44.2317 134.664 47.7503 134.664H118.465C121.99 134.664 124.839 131.992 124.839 128.694V78.2221C124.839 77.1407 124.409 76.1036 123.645 75.3389C122.88 74.5743 121.843 74.1447 120.761 74.1447H89.2433Z"
      fill="#FF9D25"
    />
    <path
      d="M98.2222 116.169H90.3386C90.1918 116.169 90.0511 116.111 89.9474 116.007C89.8436 115.903 89.7853 115.762 89.7853 115.616C89.7853 115.469 89.8436 115.328 89.9474 115.224C90.0511 115.121 90.1918 115.062 90.3386 115.062H98.2222C100.267 115.066 102.232 114.272 103.699 112.847C105.165 111.423 106.018 109.482 106.074 107.439C106.13 105.395 105.386 103.41 104 101.908C102.614 100.405 100.695 99.5031 98.6538 99.3946C98.5357 99.3888 98.4227 99.3455 98.3311 99.2708C98.2395 99.1961 98.1742 99.0941 98.1448 98.9797C97.5624 96.7306 96.3659 94.6878 94.6892 93.0797C93.0124 91.4715 90.9214 90.3615 88.6499 89.8735C86.3785 89.3856 84.0161 89.5391 81.8268 90.3167C79.6376 91.0944 77.7077 92.4656 76.2531 94.2771C76.1939 94.3517 76.1164 94.4097 76.0282 94.4455C75.94 94.4814 75.8441 94.4939 75.7496 94.4818C75.3533 94.4306 74.9542 94.4048 74.5546 94.4043C71.8573 94.4668 69.2915 95.5821 67.4059 97.5119C65.5203 99.4416 64.4646 102.033 64.4646 104.731C64.4646 107.429 65.5203 110.02 67.4059 111.949C69.2915 113.879 71.8573 114.994 74.5546 115.057H80.9445C81.0913 115.057 81.232 115.115 81.3357 115.219C81.4395 115.323 81.4978 115.463 81.4978 115.61C81.4978 115.757 81.4395 115.898 81.3357 116.001C81.232 116.105 81.0913 116.163 80.9445 116.163H74.527C71.4926 116.163 68.5825 114.958 66.4369 112.812C64.2913 110.667 63.0859 107.757 63.0859 104.722C63.0859 101.688 64.2913 98.7779 66.4369 96.6323C68.5825 94.4867 71.4926 93.2813 74.527 93.2813C74.867 93.2814 75.2068 93.2999 75.5449 93.3366C77.1337 91.4377 79.2094 90.0069 81.5493 89.1977C83.8893 88.3884 86.4053 88.2312 88.8278 88.7429C91.2503 89.2545 93.4879 90.4158 95.3007 92.1022C97.1136 93.7886 98.4333 95.9365 99.1185 98.3158C101.417 98.5295 103.543 99.6227 105.055 101.367C106.566 103.111 107.345 105.372 107.23 107.677C107.114 109.982 106.112 112.154 104.434 113.738C102.756 115.322 100.53 116.197 98.2222 116.18V116.169Z"
      fill="white"
    />
    <path
      d="M85.4751 121.214C85.3284 121.214 85.1877 121.156 85.0839 121.052C84.9802 120.949 84.9219 120.808 84.9219 120.661V106.277C84.9219 106.13 84.9802 105.99 85.0839 105.886C85.1877 105.782 85.3284 105.724 85.4751 105.724C85.6218 105.724 85.7626 105.782 85.8663 105.886C85.9701 105.99 86.0284 106.13 86.0284 106.277V120.661C86.0284 120.808 85.9701 120.949 85.8663 121.052C85.7626 121.156 85.6218 121.214 85.4751 121.214Z"
      fill="white"
    />
    <path
      d="M89.6802 112.003C89.5934 112.004 89.5076 111.984 89.4297 111.946C89.3519 111.907 89.2842 111.851 89.2321 111.782L85.5696 106.869C85.5562 106.853 85.5392 106.84 85.5201 106.831C85.5009 106.823 85.48 106.819 85.459 106.819C85.439 106.82 85.4193 106.824 85.4012 106.833C85.3831 106.842 85.3669 106.854 85.3539 106.869L81.4812 111.643C81.388 111.759 81.2529 111.832 81.1056 111.848C80.9582 111.863 80.8108 111.82 80.6956 111.726C80.5804 111.633 80.5069 111.498 80.4914 111.351C80.4758 111.204 80.5194 111.056 80.6126 110.941L84.4853 106.166C84.607 106.021 84.7593 105.903 84.9314 105.823C85.1035 105.743 85.2912 105.701 85.4811 105.702C85.6701 105.704 85.8561 105.75 86.0248 105.835C86.1935 105.921 86.3406 106.043 86.4548 106.194L90.1283 111.112C90.1725 111.171 90.2045 111.238 90.2226 111.309C90.2407 111.38 90.2444 111.454 90.2336 111.527C90.2228 111.599 90.1977 111.669 90.1596 111.732C90.1216 111.795 90.0715 111.849 90.0121 111.892C89.9164 111.964 89.7999 112.003 89.6802 112.003Z"
      fill="white"
    />
  </svg>
);

const EmptyEvidence = () => (
  <div className="p-3 flex flex-col items-center justify-center gap-4">
    <SubmissionIllustration />
    <p className="text-center text-sm text-disable">
      Silakan mengunggah evidence dari misi yang sudah diberikan disamping
    </p>
    <Button className="bg-white text-primary">Edit Evidence</Button>
  </div>
);

const SubmissionCard = ({ evidence = {} }) => (
  <article className="py-4">
    <div className="mb-4 flex items-center justify-center">
      {evidence?.image ? (
        <img
          src={evidence.image}
          alt=""
          className="w-28 h-28 rounded-md bg-gray-100 object-cover"
        />
      ) : (
        <span className="my-10 text-disable text-sm">Screenshot tidak ada</span>
      )}
    </div>
    <div className="grid gap-2">
      <div className="inline-flex items-center justify-between">
        <span className="text-sm text-gray-400">Link:</span>
        <span className="max-w-[200px] text-sm text-ellipsis overflow-hidden whitespace-nowrap">
          {evidence?.url ? (
            <a href={evidence.url} className="text-blue-500 hover:underline">
              {evidence.url}
            </a>
          ) : (
            <span className="text-disable">-</span>
          )}
        </span>
      </div>
      <div className="inline-flex items-center justify-between">
        <span className="text-sm text-gray-400">Reach:</span>
        <span className="text-sm ">
          {evidence?.url
            ? new Intl.NumberFormat("id-ID").format(evidence.reach)
            : "-"}
        </span>
      </div>
      <div className="inline-flex items-center justify-between">
        <span className="text-sm text-gray-400">Impression:</span>
        <span className="text-sm ">
          {evidence?.url
            ? new Intl.NumberFormat("id-ID").format(evidence.impression)
            : "-"}
        </span>
      </div>
      <div className="inline-flex items-center justify-between">
        <span className="text-sm text-gray-400">Comments:</span>
        <span className="text-sm ">
          {evidence?.url
            ? new Intl.NumberFormat("id-ID").format(evidence.comment)
            : "-"}
        </span>
      </div>
      <div className="inline-flex items-center justify-between">
        <span className="text-sm text-gray-400">Likes:</span>
        <span className="text-sm ">
          {evidence?.url
            ? new Intl.NumberFormat("id-ID").format(evidence.like)
            : "-"}
        </span>
      </div>
      <div className="inline-flex items-center justify-between">
        <span className="text-sm text-gray-400">Share:</span>
        <span className="text-sm ">
          {evidence?.url
            ? new Intl.NumberFormat("id-ID").format(evidence.share)
            : "-"}
        </span>
      </div>
    </div>
  </article>
);
const EvidenceCard = ({ evidence }) => (
  <article className="py-4">
    <div className="mb-4 flex items-center justify-center">
      {evidence?.image ? (
        <img
          src={evidence.image}
          alt=""
          className="aspect-video rounded-md bg-gray-100 object-cover"
        />
      ) : (
        <span className="my-10 text-disable text-sm">Screenshot tidak ada</span>
      )}
    </div>
  </article>
);
;

function LaunchpadMissionDetailEvidenceSubmissionWidget({
  evidence = {},
  type = "",
}) {
  const evidenceIsEmpty = Object.keys(evidence).length === 0;

  return (
    <article className="p-4 rounded-md shadow-md bg-white flex flex-col">
      <header>
        <h3 className="text-lg font-bold">Submissions</h3>
      </header>
      {evidenceIsEmpty && <EmptyEvidence />}
      {!evidenceIsEmpty && type === "general" && (
        <SubmissionCard evidence={evidence} />
      )}
      {!evidenceIsEmpty && type === "webinar" && (
        <EvidenceCard evidence={evidence} />
      )}
    </article>
  );
}
export default LaunchpadMissionDetailEvidenceSubmissionWidget;
