function ScheduleIcon(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.6668 9.9043H2.84277C2.42877 9.9043 2.09277 9.5683 2.09277 9.1543C2.09277 8.7403 2.42877 8.4043 2.84277 8.4043H20.6668C21.0808 8.4043 21.4168 8.7403 21.4168 9.1543C21.4168 9.5683 21.0808 9.9043 20.6668 9.9043Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2013 13.8096C15.7873 13.8096 15.4473 13.4736 15.4473 13.0596C15.4473 12.6456 15.7783 12.3096 16.1923 12.3096H16.2013C16.6153 12.3096 16.9513 12.6456 16.9513 13.0596C16.9513 13.4736 16.6153 13.8096 16.2013 13.8096Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7638 13.8096C11.3498 13.8096 11.0098 13.4736 11.0098 13.0596C11.0098 12.6456 11.3408 12.3096 11.7548 12.3096H11.7638C12.1778 12.3096 12.5138 12.6456 12.5138 13.0596C12.5138 13.4736 12.1778 13.8096 11.7638 13.8096Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.31652 13.8096C6.90252 13.8096 6.56152 13.4736 6.56152 13.0596C6.56152 12.6456 6.89352 12.3096 7.30752 12.3096H7.31652C7.73052 12.3096 8.06652 12.6456 8.06652 13.0596C8.06652 13.4736 7.73052 13.8096 7.31652 13.8096Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2013 17.6963C15.7873 17.6963 15.4473 17.3603 15.4473 16.9463C15.4473 16.5323 15.7783 16.1963 16.1923 16.1963H16.2013C16.6153 16.1963 16.9513 16.5323 16.9513 16.9463C16.9513 17.3603 16.6153 17.6963 16.2013 17.6963Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7638 17.6963C11.3498 17.6963 11.0098 17.3603 11.0098 16.9463C11.0098 16.5323 11.3408 16.1963 11.7548 16.1963H11.7638C12.1778 16.1963 12.5138 16.5323 12.5138 16.9463C12.5138 17.3603 12.1778 17.6963 11.7638 17.6963Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.31652 17.6963C6.90252 17.6963 6.56152 17.3603 6.56152 16.9463C6.56152 16.5323 6.89352 16.1963 7.30752 16.1963H7.31652C7.73052 16.1963 8.06652 16.5323 8.06652 16.9463C8.06652 17.3603 7.73052 17.6963 7.31652 17.6963Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7939 5.791C15.3799 5.791 15.0439 5.455 15.0439 5.041V1.75C15.0439 1.336 15.3799 1 15.7939 1C16.2079 1 16.5439 1.336 16.5439 1.75V5.041C16.5439 5.455 16.2079 5.791 15.7939 5.791Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.71484 5.791C7.30084 5.791 6.96484 5.455 6.96484 5.041V1.75C6.96484 1.336 7.30084 1 7.71484 1C8.12884 1 8.46484 1.336 8.46484 1.75V5.041C8.46484 5.455 8.12884 5.791 7.71484 5.791Z"
        fill="white"
      />
      <mask
        id="mask0_705_6101"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x={2}
        y={2}
        width={20}
        height={21}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 2.5791H21.5V22.5H2V2.5791Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_705_6101)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.521 4.0791C4.928 4.0791 3.5 5.4621 3.5 7.9731V17.0221C3.5 19.5881 4.928 21.0001 7.521 21.0001H15.979C18.572 21.0001 20 19.6141 20 17.0981V7.9731C20.004 6.7381 19.672 5.7781 19.013 5.1181C18.335 4.4381 17.29 4.0791 15.988 4.0791H7.521ZM15.979 22.5001H7.521C4.116 22.5001 2 20.4011 2 17.0221V7.9731C2 4.6451 4.116 2.5791 7.521 2.5791H15.988C17.697 2.5791 19.11 3.0911 20.075 4.0581C21.012 4.9991 21.505 6.3521 21.5 7.9751V17.0981C21.5 20.4301 19.384 22.5001 15.979 22.5001Z"
          fill="white"
        />
      </g>
    </svg>
  );
}
export default ScheduleIcon;
