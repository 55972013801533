function LauncpadMissionListCardSkeleton(props) {
  return (
    <div className="p-4 border rounded-md bg-white flex flex-col gap-2 animate-pulse">
      <div className="w-full h-4 mb-2 bg-gray-100"></div>
      <div>
        <div className="w-16 h-3 bg-gray-100"></div>
        <div className="mt-1 flex flex-col gap-1 ">
          <div className="w-full h-3 bg-gray-100"></div>
          <div className="w-full h-3 bg-gray-100"></div>
          <div className="w-full h-3 bg-gray-100"></div>
          <div className="w-full h-3 bg-gray-100"></div>
        </div>
      </div>
      <div className="mt-2 flex items-center justify-between">
        <div className="w-16 h-6 rounded-xl bg-gray-100"></div>
        <div className="w-16 h-4 bg-gray-100"></div>
      </div>
    </div>
  );
}
export default LauncpadMissionListCardSkeleton;
