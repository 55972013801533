import moment from "moment";
import { useEffect, useState } from "react";
import Badge from "../../../components/Badge";
import { getLaunchpadsSubmissions } from "../../../services/launchpadService";
import useError from "../../../utils/useError";

function LaunchpadSubmissionWidgetCard({ submission }) {
  const deadline = moment(submission.dueDate);

  const [timeDiff, setTimeDiff] = useState({
    hour: deadline.diff(moment(), "h"),
    minute: deadline.diff(moment(), "m") - deadline.diff(moment(), "h") * 60,
    second: deadline.diff(moment(), "s") - deadline.diff(moment(), "m") * 60,
  });

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeDiff({
        hour: deadline.diff(moment(), "h"),
        minute:
          deadline.diff(moment(), "m") - deadline.diff(moment(), "h") * 60,
        second:
          deadline.diff(moment(), "s") - deadline.diff(moment(), "m") * 60,
      });
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const statusTexts = {
    waiting: "Waiting",
    new: "New",
    ongoing: "Ongoing",
    onprogress: "Ongoing",
    done: "Done",
  };

  const statusStyles = {
    waiting: "badge-primary",
    new: "badge-primary",
    ongoing: "badge-primary",
    onprogress: "badge-primary",
    done: "badge-success",
  };

  return (
    <article className="p-4 rounded-md shadow-md bg-white">
      <h3 className="mb-4 text-sm font-bold">{submission.title}</h3>
      <footer className="flex items-center justify-between">
        <p className="text-2xs text-disable">
          {/* TODO: submission done/uploaded state */}
          Deadline:{" "}
          <span className="ml-2 text-error">
            {timeDiff.hour} : {timeDiff.minute} : {timeDiff.second}
          </span>
        </p>
        <Badge className={statusStyles[submission.status]}>
          {statusTexts[submission.status] ?? ""}
        </Badge>
      </footer>
    </article>
  );
}

function LaunchpadSubmissionWidget(props) {
  const [isLoading, setisLoading] = useState();
  const { error, setError, isError } = useError();
  const [launchpadSubmissionsData, setLaunchpadSubmissionsData] = useState([]);

  useEffect(() => {
    getLaunchpadsSubmissionsData();
  }, []);

  const getLaunchpadsSubmissionsData = async () => {
    try {
      setisLoading(true);
      setError({ status: "ok" });
      setLaunchpadSubmissionsData(
        (await getLaunchpadsSubmissions()).launchpads
      );
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setisLoading(false);
    }
  };

  return (
    <article className="p-4 rounded-md shadow-md bg-white">
      <header className="mb-3">
        <h2 className="font-bold">Launchpad Submission</h2>
      </header>
      {isLoading && (
        <section className="flex flex-col gap-2.5">
          <div className="p-4 rounded-md border animate-pulse">
            <div className="w-full h-4 mb-4 bg-gray-100"></div>
            <div className="flex items-center justify-between">
              <div className="w-1/4 h-2.5 bg-gray-100"></div>
              <div className="w-20 h-6 rounded-2xl bg-gray-100"></div>
            </div>
          </div>
          <div className="p-4 rounded-md border animate-pulse">
            <div className="w-full h-4 mb-4 bg-gray-100"></div>
            <div className="flex items-center justify-between">
              <div className="w-1/4 h-2.5 bg-gray-100"></div>
              <div className="w-20 h-6 rounded-2xl bg-gray-100"></div>
            </div>
          </div>
        </section>
      )}
      {!isLoading && !isError && (
        <section className="flex flex-col gap-2.5">
          {launchpadSubmissionsData.map((submission) => (
            <LaunchpadSubmissionWidgetCard submission={submission} />
          ))}
        </section>
      )}
    </article>
  );
}
export default LaunchpadSubmissionWidget;
