import { useState } from "react";

function useModal(initialState = false) {
  const [isShowing, setIsShowing] = useState(initialState);

  const toggle = () => setIsShowing((x) => !x);

  return { isShowing, toggle };
}

export default useModal;
