import SidebarLeft from "../../components/SidebarLeft";
import Main from "../../components/Main";
import Header from "../../components/Header";
import Nav from "../../components/Nav";
import GridLayout from "../../components/GridLayout";
import SchedulesMonthViewHeader from "./components/SchedulesMonthViewHeader";
import { useEffect, useState } from "react";
import SchedulesMonthViewList from "./components/SchedulesMonthViewList";
import { useParams } from "react-router-dom";
import { getSchedulesMonth } from "../../services/scheduleService";
import useError from "../../utils/useError";
import SchedulesMonthViewSkeleton from "./components/SchedulesMonthViewSkeleton";

function SchedulesMonthView(props) {
  const { year, month } = useParams();
  const [schedulesData, setSchedulesData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { error, setError, isError } = useError();

  const getSchedulesData = async () => {
    try {
      setIsLoading(true);
      setError({ status: "ok" });
      setSchedulesData(await getSchedulesMonth({ year, month }));
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getSchedulesData();
  }, [useParams()]);

  return (
    <>
      <Header />
      <GridLayout>
        <SidebarLeft>
          <Nav />
        </SidebarLeft>
        {isLoading && (
          <Main className="md:col-span-full lg:col-span-10">
            <SchedulesMonthViewHeader />
            <SchedulesMonthViewSkeleton />
          </Main>
        )}
        {!isLoading && !isError && (
          <Main className="md:col-span-full lg:col-span-10">
            <SchedulesMonthViewHeader />
            <SchedulesMonthViewList schedulesData={schedulesData} />
          </Main>
        )}
      </GridLayout>
    </>
  );
}
export default SchedulesMonthView;
