import Button from "./Button";
import Portal from "./Portal";
import surveyNotCompletedIllustration from "../assets/survey-not-completed-illustration.svg";
import { matchPath, useNavigate } from "react-router-dom";

function SurveyNotCompletedPopup() {
  const navigate = useNavigate();

  // Do not show popup on survey page
  return !Boolean(
    matchPath({ path: "/survey", end: false }, window.location.pathname)
  ) ? (
    <Portal>
      <div
        data-testid={"exchange-point-popup-backdrop"}
        className="fixed inset-0 z-50 bg-black bg-opacity-30 flex items-center justify-center"
      >
        <article
          onClick={(event) => event.stopPropagation()}
          className="w-72 p-5 bg-white rounded-xl flex flex-col items-center justify-center gap-4"
        >
          <img
            src={surveyNotCompletedIllustration}
            alt="Survey not completed"
            className=""
          />
          <h1 className="text-text text-xl text-center font-bold">
            Lengkapi Data
          </h1>
          <p className="text-center leading-5">
            Kamu harus melengkapi data &#38; portofolio pada dashboard blogger
          </p>
          <Button
            onClick={() => navigate("/survey/1")}
            className="w-full font-bold"
          >
            Ok
          </Button>
        </article>
      </div>
    </Portal>
  ) : (
    <></>
  );
}
export default SurveyNotCompletedPopup;
