import axios from "axios";
import { GET_PROFILE_API_ROUTE } from "../constants";

async function getProfile() {
  let result = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: GET_PROFILE_API_ROUTE,
      method: "get",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    });
    result = response.data;
  } catch (error) {
    throw error;
  }

  return result;
}

export { getProfile };
