import { useNavigate, useParams } from "react-router-dom";
import SurveyHeader from "./components/SurveyHeader";
import SurveyTopBanner from "./components/SurveyTopBanner";
import SurveyTitle from "./components/SurveyTitle";
import SurveyQuestions from "./components/SurveyQuestions";
import { Helmet } from "react-helmet";
import { SurveyContext } from "../../contexts/SurveyContext";
import { useContext } from "react";
import { useEffect } from "react";

function Survey() {
  const {
    getSurveyQuestionsData,
    surveyQuestions,
    surveyIsError,
    surveyIsLoading,
    surveyIsCompleted,
  } = useContext(SurveyContext);
  const { step } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getSurveyQuestionsData();
    if (surveyIsCompleted) navigate("/survey/results", { replace: true });
  }, [surveyIsCompleted]);

  return (
    <>
      <Helmet>
        <title>Penakita Program Development</title>
      </Helmet>
      <article className="w-screen md:w-[720px] min-h-screen md:mx-auto md:my-6 md:rounded-md md:shadow-md bg-white flex flex-col">
        <SurveyHeader />
        <SurveyTopBanner />
        <SurveyTitle
          title={
            "Mohon Isi pertanyaan dibawah ini sebelum memulai Penakita Development Program, agar kami lebih tau blablabla"
          }
        />
        {!surveyIsLoading && !surveyIsError && Boolean(surveyQuestions) && (
          <>
            {step === "1" && (
              <SurveyQuestions
                questions={surveyQuestions.slice(
                  0,
                  Math.ceil(surveyQuestions.length / 4) * 1
                )}
              />
            )}
            {step === "2" && (
              <SurveyQuestions
                questions={surveyQuestions.slice(
                  Math.ceil(surveyQuestions.length / 4) * 1,
                  Math.ceil(surveyQuestions.length / 4) * 2
                )}
              />
            )}
            {step === "3" && (
              <SurveyQuestions
                questions={surveyQuestions.slice(
                  Math.ceil(surveyQuestions.length / 4) * 2,
                  Math.ceil(surveyQuestions.length / 4) * 3
                )}
              />
            )}
            {step === "4" && (
              <SurveyQuestions
                questions={surveyQuestions.slice(
                  Math.ceil(surveyQuestions.length / 4) * 3,
                  Math.ceil(surveyQuestions.length / 4) * 4
                )}
              />
            )}
          </>
        )}
      </article>
    </>
  );
}
export default Survey;
