import moment from "moment";
import { useEffect, useState } from "react";
import Badge from "../../../components/Badge";

const leadingZero = (num, places) => String(num).padStart(places, "0");

export function TasksSectionCard({ data }) {
  const deadline = moment().add(data.duration, "s");
  const [diffHour, setDiffHour] = useState(deadline.diff(moment(), "h"));
  const [diffMinute, setDiffMinute] = useState(
    deadline.diff(moment(), "m") - deadline.diff(moment(), "h") * 60
  );
  const [diffSecond, setDiffSecond] = useState(
    deadline.diff(moment(), "s") - deadline.diff(moment(), "m") * 60
  );

  const statusTexts = {
    waiting: "Waiting",
    new: "New",
    ongoing: "Ongoing",
    onprogress: "Ongoing",
    done: "Done",
  };

  const statusStyles = {
    waiting: "badge-primary",
    new: "badge-primary",
    ongoing: "badge-primary",
    onprogress: "badge-primary",
    done: "badge-success",
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setDiffHour(deadline.diff(moment(), "h"));
      setDiffMinute(
        deadline.diff(moment(), "m") - deadline.diff(moment(), "h") * 60
      );
      setDiffSecond(
        deadline.diff(moment(), "s") - deadline.diff(moment(), "m") * 60
      );
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  return (
    <article
      className={`w-60 lg:w-auto p-4 first:ml-9 last:mr-9 first:lg:m-0 last:lg:m-0  snap-start flex-shrink-0 border-2 ${
        diffHour < 2 ? "border-orange-600" : "border-white "
      } rounded-md overflow-hidden shadow-md`}
    >
      <div className="inline-flex items-start justify-between gap-4">
        <p className="mb-4 text-sm font-bold line-clamp-1">{data.title}</p>
        {diffHour < 2 && (
          <div className="flex-shrink-0">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.66699 9.99935C1.66699 5.40018 5.40033 1.66602 10.0003 1.66602C14.6087 1.66602 18.3337 5.40018 18.3337 9.99935C18.3337 14.6002 14.6087 18.3327 10.0003 18.3327C5.40033 18.3327 1.66699 14.6002 1.66699 9.99935ZM9.26699 6.84102C9.26699 6.44185 9.60033 6.10768 10.0003 6.10768C10.4003 6.10768 10.7253 6.44185 10.7253 6.84102V10.5243C10.7253 10.9252 10.4003 11.2493 10.0003 11.2493C9.60033 11.2493 9.26699 10.9252 9.26699 10.5243V6.84102ZM10.0087 13.9002C9.60033 13.9002 9.27533 13.5668 9.27533 13.1668C9.27533 12.7668 9.60033 12.4418 10.0003 12.4418C10.4087 12.4418 10.7337 12.7668 10.7337 13.1668C10.7337 13.5668 10.4087 13.9002 10.0087 13.9002Z"
                fill="#FF5934"
              />
            </svg>
          </div>
        )}
      </div>
      <div className="flex items-center">
        <div className="flex items-center w-full justify-between">
          {data.status && (
            <Badge className={statusStyles[data.status]}>
              {statusTexts[data.status]}
            </Badge>
          )}
          <span className="text-2xs text-gray-500">
            Deadline:{" "}
            <time dateTime={data.duration} className="text-red-600">
              {leadingZero(diffHour, 2)} : {leadingZero(diffMinute, 2)} :{" "}
              {leadingZero(diffSecond, 2)}
            </time>
          </span>
        </div>
      </div>
    </article>
  );
}
