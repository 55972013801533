import { Field, Form, Formik, useFormik } from "formik";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Button from "../../../components/Button";
import { postComment } from "../../../services/forumService";

function ForumPostCommentBox({ getForumPostData = () => {} }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const { forumPostId } = useParams();

  const initialValues = {
    comment: "",
  };

  const validate = (values) => {
    const errors = {};

    if (!values.comment) {
      errors.comment = "Comment cannot be empty.";
    }

    return errors;
  };

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      setIsError(false);
      setIsLoading(true);
      await postComment({ forumId: forumPostId, comment: values.comment });
      resetForm();
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
      getForumPostData();
    }
  };

  return (
    <div className="w-[calc(100%+40px)] mt-4 p-4 border-t relative -left-5">
      <Formik {...{ initialValues, validate, onSubmit }}>
        {() => (
          <Form
            className={`p-4 border ${
              isError ? "border-error" : "border-gray-50"
            } rounded-md bg-gray-50 flex items-stretch gap-4 divide-x divide-gray-400`}
          >
            <Field name="comment">
              {({ field, form: { touched, errors }, meta }) => (
                <textarea
                  {...field}
                  className="w-full h-20 outline-none bg-transparent text-black disabled:text-gray-400"
                  placeholder="Add a comment..."
                  disabled={isLoading}
                ></textarea>
              )}
            </Field>
            <div className="pl-4 self-stretch flex-shrink-0 flex items-center">
              <Button className="w-max" type="submit" disabled={isLoading}>
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-4 h-4 md:w-6 md:h-6"
                >
                  <path
                    d="M15.8325 8.17463L10.109 13.9592L3.59944 9.88767C2.66675 9.30414 2.86077 7.88744 3.91572 7.57893L19.3712 3.05277C20.3373 2.76963 21.2326 3.67283 20.9456 4.642L16.3731 20.0868C16.0598 21.1432 14.6512 21.332 14.0732 20.3953L10.106 13.9602"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
export default ForumPostCommentBox;
