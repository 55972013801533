import { useState } from "react";
import SearchBox from "../../../components/SearchBox";

function ForumHeader({
  keyword = "",
  setKeyword,
  sortBy = "new post",
  setSortBy,
}) {
  const [searchBoxIsFocus, setSearchBoxIsFocus] = useState(false);

  return (
    <header className="mb-4 flex items-center justify-between">
      <h1
        className={`${
          searchBoxIsFocus ? "hidden md:block" : ""
        } text-2xl font-bold`}
      >
        Forum
      </h1>
      <div
        className={`${
          searchBoxIsFocus ? "w-full md:w-max" : ""
        } flex md:flex-row-reverse items-center justify-end gap-2 md:gap-4`}
      >
        <select
          className={`${
            searchBoxIsFocus ? "hidden md:block" : ""
          } h-10 p-1.5 md:py-1 md:px-3 text-sm text-disable2 bg-white border border-gray-300 rounded-md`}
          onChange={(event) => setSortBy(event.target.value)}
        >
          <option value="new post">Latest</option>
          <option value="popular">Popular</option>
        </select>
        <button
          className={`p-1 rounded-md ${
            searchBoxIsFocus ? "sm:hidden" : "hidden"
          } text-gray-400 hover:bg-gray-200`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            style={{ fill: "currentColor", transform: "", msfilter: "" }}
          >
            <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z" />
          </svg>
        </button>
        <SearchBox
          labelClassName="w-full"
          keyword={keyword}
          setKeyword={setKeyword}
          searchBoxIsFocus={searchBoxIsFocus}
          setSearchBoxIsFocus={setSearchBoxIsFocus}
        />
      </div>
    </header>
  );
}
export default ForumHeader;
