import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getBloggerTaskPreview } from "../services/bloggerCourseService";
import HomeworkPreviewWidgetCourseItem from "./HomeworkPreviewWidgetCourseItem";

function HomeworkPreviewWidget(props) {
  const [homeworkData, setHomeworkData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const getHomeworkData = async () => {
    try {
      setIsLoading(true);
      setHomeworkData((await getBloggerTaskPreview()).tasks);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getHomeworkData();
  }, []);

  return (
    <article className="h-min p-4 bg-white rounded-lg shadow-md flex flex-col gap-2">
      <header className="flex items-center justify-between">
        <h3 className="text-lg text-gray-800 font-bold">Homework</h3>
      </header>
      {homeworkData.map((x) => (
        <HomeworkPreviewWidgetCourseItem courseItem={x} />
      ))}
    </article>
  );
}
export default HomeworkPreviewWidget;
