import socialMediaMarketingIllustration from "../../../assets/landing-page-courses-materi-ungguluan-illustration-social-media-marketing.png"
import seoIllustration from "../../../assets/landing-page-courses-materi-ungguluan-illustration-seo.png"
import photographyIllustration from "../../../assets/landing-page-courses-materi-ungguluan-illustration-photography.png"
import journalismIllustration from "../../../assets/landing-page-courses-materi-ungguluan-illustration-journalism.png"
import copywritingIllustration from "../../../assets/landing-page-courses-materi-ungguluan-illustration-copywriting.png";

function LandingPageCourseMateriUnggulan(props) {
  const materiUnggulanContents = [
    {
      img: socialMediaMarketingIllustration,
      title: "Social Media Marketing",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas placerat purus et mi commodo, vel fringilla tellus varius. In hac habitasse platea dictumst. Donec a euismod quam, in aliquam tellus.",
    },
    {
      img: seoIllustration,
      title: "Search Engine Optimization (SEO)",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas placerat purus et mi commodo, vel fringilla tellus varius. In hac habitasse platea dictumst. Donec a euismod quam, in aliquam tellus.",
    },
    {
      img: photographyIllustration,
      title: "Photography",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas placerat purus et mi commodo, vel fringilla tellus varius. In hac habitasse platea dictumst. Donec a euismod quam, in aliquam tellus.",
    },
    {
      img: journalismIllustration,
      title: "Journalism",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas placerat purus et mi commodo, vel fringilla tellus varius. In hac habitasse platea dictumst. Donec a euismod quam, in aliquam tellus.",
    },
    {
      img: copywritingIllustration,
      title: "Copywriting",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas placerat purus et mi commodo, vel fringilla tellus varius. In hac habitasse platea dictumst. Donec a euismod quam, in aliquam tellus.",
    },
  ];

  return (
    <section className="px-6 py-16 relative z-50">
      <div className="max-w-full md:max-w-lg lg:max-w-5xl mx-auto">
        <article className="p-8 lg:p-24 shadow-md rounded-3xl bg-white font-sans-landing flex flex-col gap-16">
          <h2 className="mb-4 text-text text-3xl text-center font-bold">
            Materi Unggulan
          </h2>
          {materiUnggulanContents.map(item => <div className="flex flex-col lg:flex-row lg:even:flex-row-reverse items-center gap-6 lg:gap-10">
            <img
              src={item.img}
              alt={item.title}
              className="w-48 h-48 flex-shrink-0"
            />
            <div className="text-center lg:text-left flex flex-col gap-3">
              <p className="text-xl lg:text-3xl font-bold text-primary">
                {item.title}
              </p>
              <p className="text-xs lg:text-sm text-black">
                {item.desc}
              </p>
            </div>
          </div>)}
        </article>
      </div>
    </section>
  );
}
export default LandingPageCourseMateriUnggulan;
