import moment from "moment";

export default function CourseDetailHomeworkWidgetCard({ task }) {
  const deadline = moment().add(task.duration, "s").fromNow(true);

  return (
    <article
      className="h-min p-4 bg-white rounded-lg shadow-md"
    >
      <h4
        data-testid={`homework-widget-card-${task.courseTaskId}`}
        className="pb-3 text-[14px] font-bold"
      >
        {task.title}
      </h4>
      <p className="text-[14px] text-disable2">
        Deadline:{" "}
        <span className="ml-2 text-primary font-bold">{deadline}</span>
      </p>
    </article>
  );
}
