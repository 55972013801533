function LaunchpadMissionDetailContent({
  content = {
    bloggerLaunchpadId: "",
    status: "",
    title: "",
    image: "",
    dueDate: "",
    description: "",
    type: "",
    evidence: {
      bloggerLaunchpadEvidenceId: 0,
      image: "",
      url: "",
      reach: 0,
      impression: 0,
      comment: 0,
      like: 0,
      share: 0,
      date: "",
    },
  },
}) {
  return (
    <section className="max-w-xl mx-auto overflow-visible">
      {content.image && <img className="w-full h-80 mb-5 bg-zinc-300 object-contain rounded-md shadow-md" src={content.image} alt={content.title} />}
      <article className="">
        <h2 className="text-primary text-lg font-semibold">Objective</h2>
        <p>{content.description}</p>
      </article>
    </section>
  );
}
export default LaunchpadMissionDetailContent;
