export const categories = [
  {
    categoryId: 1,
    category: "Writing",
  },
  {
    categoryId: 2,
    category: "Digital Marketing",
  },
];
