function TimeIcon(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99967 2.3335C4.87501 2.3335 2.33301 4.8755 2.33301 8.00016C2.33301 11.1248 4.87501 13.6668 7.99967 13.6668C11.1243 13.6668 13.6663 11.1248 13.6663 8.00016C13.6663 4.8755 11.1243 2.3335 7.99967 2.3335ZM7.99967 14.6668C4.32367 14.6668 1.33301 11.6762 1.33301 8.00016C1.33301 4.32416 4.32367 1.3335 7.99967 1.3335C11.6757 1.3335 14.6663 4.32416 14.6663 8.00016C14.6663 11.6762 11.6757 14.6668 7.99967 14.6668Z"
        fill="#FF9D25"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2868 10.4618C10.1994 10.4618 10.1114 10.4391 10.0308 10.3918L7.51744 8.89247C7.36677 8.8018 7.27344 8.63847 7.27344 8.46247V5.23047C7.27344 4.95447 7.49744 4.73047 7.77344 4.73047C8.0501 4.73047 8.27344 4.95447 8.27344 5.23047V8.17847L10.5434 9.5318C10.7801 9.6738 10.8581 9.98047 10.7168 10.2178C10.6228 10.3745 10.4568 10.4618 10.2868 10.4618Z"
        fill="#FF9D25"
      />
    </svg>
  );
}
export default TimeIcon;
