import SidebarLeft from "../../components/SidebarLeft";
import Main from "../../components/Main";
import SidebarRight from "../../components/SidebarRight";
import GridLayout from "../../components/GridLayout";
import Header from "../../components/Header";
import Nav from "../../components/Nav";
import Helmet from "react-helmet";
import ForumHeader from "./components/ForumHeader";
import ForumCreatePostButton from "./components/ForumCreatePostButton";
import ForumFeed from "./components/ForumFeed";
import ForumPinnedPostsWidget from "./components/ForumPinnedPostsWidget";
import ForumTermsAndConditionsWidget from "./components/ForumTermsAndConditionsWidget";
import { useState } from "react";

function Forum(props) {
  const [keyword, setKeyword] = useState("");
  const [sortBy, setSortBy] = useState("new post");

  return (
    <>
      <Helmet>
        <title>Forum</title>
      </Helmet>
      <Header />
      <GridLayout>
        <SidebarLeft>
          <Nav />
        </SidebarLeft>
        <Main>
          <ForumHeader setKeyword={setKeyword} setSortBy={setSortBy} />
          <ForumCreatePostButton />
          <ForumFeed keyword={keyword} sortBy={sortBy} />
        </Main>
        <SidebarRight>
          <ForumPinnedPostsWidget />
          <ForumTermsAndConditionsWidget />
        </SidebarRight>
      </GridLayout>
    </>
  );
}
export default Forum;
