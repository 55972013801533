import moment from "moment";
import { Link } from "react-router-dom";
import Badge from "../../../components/Badge";

function LaunchpadMissionListCard({ mission }) {
  const statusStyles = {
    waiting: "badge-primary",
    new: "badge-primary",
    done: "badge-success",
    expired: "badge-error",
  };

  const statusTexts = {
    waiting: "Waiting",
    new: "New",
    done: "Done",
    expired: "Expired",
  };
  return (
    <article className="p-4 shadow-md rounded-md bg-white flex flex-col gap-2">
      <h3 className="font-bold">{mission.title ?? ""}</h3>
      {mission.description && (
        <article>
          <h6 className="text-primary text-xs font-bold">Objective</h6>
          <p className="text-xs line-clamp-4">{mission.description}</p>
        </article>
      )}
      <footer className="mt-auto flex items-center justify-between">
        {mission.status === "ongoing" ? (
          <>
            <p className="text-xs">
              <span>Due Date: </span>
              <span className="ml-1 text-error font-bold">
                {moment(mission.dueDate).format("DD MMM YYYY")}
              </span>
            </p>
          </>
        ) : (
          <Badge className={`${statusStyles[mission.status] ?? ""}`}>
            {statusTexts[mission.status] ?? ""}
          </Badge>
        )}
        <Link
          to={`${mission.bloggerLaunchpadId}`}
          className="p-1 pl-2.5 rounded-md hover:bg-gray-100 flex items-center"
        >
          <span className="text-xs">Lihat Detail</span>
          <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.96967 19.5303C7.7034 19.2641 7.6792 18.8474 7.89705 18.5538L7.96967 18.4697L14.439 12L7.96967 5.53033C7.7034 5.26406 7.6792 4.8474 7.89705 4.55379L7.96967 4.46967C8.23594 4.2034 8.6526 4.1792 8.94621 4.39705L9.03033 4.46967L16.0303 11.4697C16.2966 11.7359 16.3208 12.1526 16.1029 12.4462L16.0303 12.5303L9.03033 19.5303C8.73744 19.8232 8.26256 19.8232 7.96967 19.5303Z"
              fill="#FF9D25"
            />
          </svg>
        </Link>
      </footer>
    </article>
  );
}
export default LaunchpadMissionListCard;
