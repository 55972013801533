import Button from "../../../components/Button";
import TextField from "../../../components/TextField";
import { useFormik } from "formik";

function HomeworkDetailSubmitEvidenceModal({ handleClose, handleSubmit }) {
  const formik = useFormik({
    initialValues: {
      linkPost: "",
      tanggalPost: "",
      insightReach: 0,
      insightImpression: 0,
      jumlahKomen: 0,
      jumlahLike: 0,
      jumlahShare: 0,
      img: "",
    },
    validate: (values) => {},
    onSubmit: handleSubmit,
  });

  return (
    <>
      <div
        onClick={handleClose}
        className="p-10 fixed inset-0 z-50 bg-black bg-opacity-30 flex items-center justify-center overflow-y-auto"
      >
        <article
          onClick={(e) => e.stopPropagation()}
          className="w-[544px] p-6 overflow-y-auto rounded-3xl bg-white flex flex-col justify-center gap-4"
        >
          <header>
            <h1 className="text-2xl text-center text-text font-bold">
              Edit Evidence
            </h1>
          </header>
          <form
            action={handleSubmit}
            className="flex flex-col gap-4 items-center"
          >
            <div className="w-full flex gap-4 items-center">
              <label className="w-28 h-28 cursor-pointer bg-gray-300 hover:bg-gray-400 rounded-md text-[0px] flex items-center justify-center">
                <input
                  className="file:bg-transparent file:border-0 file:w-0 file:h-0"
                  type="file"
                  accept="image/*"
                  onChange={(event) => {
                    console.log(new FileReader(event.currentTarget.files[0]));
                  }}
                />
                <svg
                  width={32}
                  height={32}
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="relative right-2"
                >
                  <mask
                    id="mask0_823_6868"
                    style={{ maskType: "alpha" }}
                    maskUnits="userSpaceOnUse"
                    x={2}
                    y={2}
                    width={28}
                    height={28}
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M2.66797 2.66675H29.272V29.2719H2.66797V2.66675Z"
                      fill="white"
                    />
                  </mask>
                  <g mask="url(#mask0_823_6868)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10.2026 4.6665C6.8413 4.6665 4.66797 6.97184 4.66797 10.5385V21.3998C4.66797 24.9678 6.8413 27.2718 10.2026 27.2718H21.7306C25.0973 27.2718 27.272 24.9678 27.272 21.3998V10.5385C27.2746 8.72117 26.72 7.20384 25.6693 6.15184C24.6986 5.17984 23.34 4.6665 21.7373 4.6665H10.2026ZM21.7306 29.2718H10.2026C5.69597 29.2718 2.66797 26.1078 2.66797 21.3998V10.5385C2.66797 5.8305 5.69597 2.6665 10.2026 2.6665H21.7373C23.8813 2.6665 25.7306 3.3825 27.084 4.7385C28.4986 6.1545 29.2746 8.2145 29.272 10.5398V21.3998C29.272 26.1078 26.2413 29.2718 21.7306 29.2718Z"
                      fill="#FF9D25"
                    />
                  </g>
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.8096 10.2527C11.0043 10.2527 10.3496 10.9074 10.3496 11.714C10.3496 12.5194 11.0043 13.174 11.8109 13.174C12.6163 13.174 13.2723 12.5194 13.2723 11.7154C13.2709 10.9087 12.6149 10.254 11.8096 10.2527ZM11.8109 15.174C9.90161 15.174 8.34961 13.622 8.34961 11.714C8.34961 9.80469 9.90161 8.25269 11.8109 8.25269C13.7189 8.25402 15.2709 9.80602 15.2723 11.7127V11.714C15.2723 13.622 13.7203 15.174 11.8109 15.174Z"
                    fill="#FF9D25"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.99858 26.815C4.83325 26.815 4.66525 26.7736 4.51058 26.687C4.02791 26.4163 3.85858 25.807 4.12791 25.3256C4.20791 25.1816 6.12125 21.799 8.22658 20.0656C9.89591 18.6923 11.6932 19.447 13.1412 20.0563C13.9932 20.415 14.7986 20.7536 15.5719 20.7536C16.2812 20.7536 17.1706 19.5003 17.9572 18.395C19.0492 16.8536 20.2892 15.1096 22.1052 15.1096C24.9986 15.1096 27.4959 17.691 28.8386 19.0776L28.9932 19.2376C29.3772 19.6336 29.3679 20.267 28.9719 20.6523C28.5786 21.0376 27.9452 21.0283 27.5586 20.631L27.4012 20.4683C26.2652 19.2936 24.1506 17.1096 22.1052 17.1096C21.3212 17.1096 20.4012 18.407 19.5866 19.5523C18.4692 21.1256 17.3132 22.7536 15.5719 22.7536C14.3946 22.7536 13.3159 22.3003 12.3652 21.899C10.8532 21.2616 10.1679 21.0576 9.49725 21.6096C7.67858 23.1083 5.88925 26.2736 5.87191 26.3043C5.68925 26.631 5.34925 26.815 4.99858 26.815Z"
                    fill="#FF9D25"
                  />
                </svg>
              </label>
              <TextField
                required
                type="text"
                placeholder="Link Post"
                label="Link Post"
                className="flex-grow"
              />
            </div>
            <div className="w-full grid grid-cols-2 gap-4">
              <TextField
                placeholder="Tanggal Post"
                label="Tanggal Post"
                type="date"
                required
              />
              <TextField
                type="text"
                placeholder="Insight reach"
                label="Insight reach"
              />
              <TextField
                type="text"
                placeholder="Insight impression"
                label="Insight impression"
              />
              <TextField
                type="text"
                placeholder="Jumlah komen"
                label="Jumlah komen"
              />
              <TextField
                type="text"
                placeholder="Jumlah Like"
                label="Jumlah Like"
              />
              <TextField
                type="text"
                placeholder="Jumlah Share"
                label="Jumlah Share"
              />
            </div>
            <div className="flex gap-4">
              <Button
                type="button"
                onClick={handleClose}
                className="bg-white text-primary"
              >
                Batal
              </Button>
              <Button type="submit">Simpan</Button>
            </div>
          </form>
        </article>
      </div>
    </>
  );
}
export default HomeworkDetailSubmitEvidenceModal;
