import { Link } from "react-router-dom";

function HomeworkDetailHomeworkWidget(props) {
  return (
    <article className="h-min p-4 bg-white rounded-lg shadow-md flex flex-col gap-2">
      <header className="flex items-center justify-between">
        <h3 className="text-lg text-gray-800 font-bold">Homework</h3>
        <Link to="/homework" className="text-sm text-primary font-bold">
          View All
        </Link>
      </header>
      <div className="flex flex-col gap-4">
        {[0, 0, 0, 0].map((item) => (
          <article className="h-min p-4 bg-white rounded-lg shadow-md">
            <h4 className="pb-3 text-[14px] font-bold">Home Work 1</h4>
            <p className="text-[14px] text-disable2">
              Deadline:{" "}
              <span className="ml-2 text-primary font-bold">1 week</span>
            </p>
          </article>
        ))}
      </div>
    </article>
  );
}
export default HomeworkDetailHomeworkWidget;
