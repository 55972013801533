import penakitaCoursePageIllustration from "../../../assets/penakita-course-page-header-illustration.svg";
import bannerBg from "../../../assets/banner-bg.svg";

function SurveyTopBanner(props) {
  return (
    <article
      className="h-28 m-4 mt-0 rounded-md shadow-md bg-primary flex gap-4 overflow-hidden"
      style={{
        background: `url(${bannerBg})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <div className="w-1/2 p-4">
        <p className="mb-2 text-xs text-white">Welcome to</p>
        <h1 className="text-white font-bold leading-4">
          Penakita Development Program
        </h1>
      </div>
      <div className="flex-1 flex items-end justify-end relative top-2">
        <img src={penakitaCoursePageIllustration} alt="" className="h-24" />
      </div>
    </article>
  );
}
export default SurveyTopBanner;
