import axios from "axios";
import {
  GET_UPCOMING_SCHEDULES_API_ROUTE,
  GET_SCHEDULES_API_ROUTE,
  GET_SCHEDULES_ITEM_DETAIL,
} from "../constants";

async function getSchedulesItemDetail({type = "", scheduleId = ""}) {
  let result = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: GET_SCHEDULES_ITEM_DETAIL(type, scheduleId),
      method: "get",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    });
    result = response.data.data.schedule;
  } catch (error) {
    throw error;
  }

  return result;
}

async function getUpcomingSchedules() {
  let result = [
    {
      scheduleId: 0,
      title: "",
      dueDate: "",
      location: "",
    },
  ];

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: GET_UPCOMING_SCHEDULES_API_ROUTE,
      method: "get",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    });
    console.log({ response });
    result = response.data.data.schedules;
  } catch (error) {
    throw error;
  }

  return result;
}

async function getSchedulesDay({ year, month, day }) {
  let result;

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: GET_SCHEDULES_API_ROUTE,
      params: { date: `${year}-${month}-${day}`, type: "day" },
      method: "get",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    });
    result = response.data.data.schedules;
  } catch (error) {
    throw error;
  }

  return result;
}

async function getSchedulesMonth({ year, month }) {
  let result = {};

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url: GET_SCHEDULES_API_ROUTE,
      params: { date: `${year}-${month}`, type: "month" },
      method: "get",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(window.localStorage.getItem("token")) ?? ""
        }`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    });
    result = response.data.data.schedules;
  } catch (error) {
    throw error;
  }

  return result;
}

export {
  getUpcomingSchedules,
  getSchedulesDay,
  getSchedulesMonth,
  getSchedulesItemDetail,
};
