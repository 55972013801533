import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import landingPageTestimonialCardBg from "../../../assets/landing-page-testimonial-card-bg.svg";
import emptyProfilePicture from "../../../assets/empty-profile-picture.svg";
import "swiper/css";
import "swiper/css/pagination";

SwiperCore.use([Pagination]);

function LandingPageMainTestimonialsRatingStars({ ratingOutOfFive = 0 }) {
  return (
    <div className="mb-4 flex gap-2">
      {Array(ratingOutOfFive)
        .fill(0)
        .map((x) => (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.9457 9.54675C11.773 9.71408 11.6937 9.95608 11.733 10.1934L12.3257 13.4734C12.3757 13.7514 12.2583 14.0327 12.0257 14.1934C11.7977 14.3601 11.4943 14.3801 11.2457 14.2467L8.29299 12.7067C8.19032 12.6521 8.07632 12.6227 7.95965 12.6194H7.77899C7.71632 12.6287 7.65499 12.6487 7.59899 12.6794L4.64565 14.2267C4.49965 14.3001 4.33432 14.3261 4.17232 14.3001C3.77765 14.2254 3.51432 13.8494 3.57899 13.4527L4.17232 10.1727C4.21165 9.93341 4.13232 9.69008 3.95965 9.52008L1.55232 7.18675C1.35099 6.99141 1.28099 6.69808 1.37299 6.43341C1.46232 6.16941 1.69032 5.97675 1.96565 5.93341L5.27899 5.45275C5.53099 5.42675 5.75232 5.27341 5.86565 5.04675L7.32565 2.05341C7.36032 1.98675 7.40499 1.92541 7.45899 1.87341L7.51899 1.82675C7.55032 1.79208 7.58632 1.76341 7.62632 1.74008L7.69899 1.71341L7.81232 1.66675H8.09299C8.34365 1.69275 8.56432 1.84275 8.67965 2.06675L10.159 5.04675C10.2657 5.26475 10.473 5.41608 10.7123 5.45275L14.0257 5.93341C14.3057 5.97341 14.5397 6.16675 14.6323 6.43341C14.7197 6.70075 14.6443 6.99408 14.439 7.18675L11.9457 9.54675Z"
              fill="#FFBF1A"
            />
          </svg>
        ))}
      {Array(5 - ratingOutOfFive)
        .fill(0)
        .map((x) => (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.9457 9.54675C11.773 9.71408 11.6937 9.95608 11.733 10.1934L12.3257 13.4734C12.3757 13.7514 12.2583 14.0327 12.0257 14.1934C11.7977 14.3601 11.4943 14.3801 11.2457 14.2467L8.29299 12.7067C8.19032 12.6521 8.07632 12.6227 7.95965 12.6194H7.77899C7.71632 12.6287 7.65499 12.6487 7.59899 12.6794L4.64565 14.2267C4.49965 14.3001 4.33432 14.3261 4.17232 14.3001C3.77765 14.2254 3.51432 13.8494 3.57899 13.4527L4.17232 10.1727C4.21165 9.93341 4.13232 9.69008 3.95965 9.52008L1.55232 7.18675C1.35099 6.99141 1.28099 6.69808 1.37299 6.43341C1.46232 6.16941 1.69032 5.97675 1.96565 5.93341L5.27899 5.45275C5.53099 5.42675 5.75232 5.27341 5.86565 5.04675L7.32565 2.05341C7.36032 1.98675 7.40499 1.92541 7.45899 1.87341L7.51899 1.82675C7.55032 1.79208 7.58632 1.76341 7.62632 1.74008L7.69899 1.71341L7.81232 1.66675H8.09299C8.34365 1.69275 8.56432 1.84275 8.67965 2.06675L10.159 5.04675C10.2657 5.26475 10.473 5.41608 10.7123 5.45275L14.0257 5.93341C14.3057 5.97341 14.5397 6.16675 14.6323 6.43341C14.7197 6.70075 14.6443 6.99408 14.439 7.18675L11.9457 9.54675Z"
              fill="gray"
            />
          </svg>
        ))}
    </div>
  );
}

function LandingPageMainTestimonialsCard({
  name = "",
  profilePicture,
  link = "",
  testimonialContent = "",
  ratingOutOfFive = 4,
}) {
  return (
    <article
      className="pb-12 p-6 lg:p-16 rounded-3xl shadow-md bg-white flex flex-col items-center lg:items-stretch"
      style={{
        background: `url(${landingPageTestimonialCardBg})`,
        backgroundColor: "white",
        backgroundRepeat: "no-repeat",
        backgroundSize: "110%",
        backgroundPosition: "top right",
      }}
    >
      <div className="mb-10 flex flex-col lg:flex-row items-center gap-4">
        <img
          src={profilePicture ?? emptyProfilePicture}
          alt={name ?? "Profile Picture"}
          className="w-16 h-16 rounded-full"
        />
        <div className="lg:mr-auto flex flex-col text-center lg:text-left">
          <p className="text-2xl text-text font-bold">{name}</p>
          <a href={link} className="text-primary hover:underline">
            {link}
          </a>
        </div>
        <LandingPageMainTestimonialsRatingStars
          ratingOutOfFive={ratingOutOfFive}
        />
      </div>

      <article className="text-center lg:text-left">
        <p>{testimonialContent}</p>
      </article>
    </article>
  );
}

function LandingPageMainTestimonials(props) {
  return (
    <>
      <section id="testimonials" className="mb-24">
        <div className="max-w-full md:max-w-lg lg:max-w-5xl mx-auto">
          <div className="p-6 lg:p-16 flex flex-col font-sans-landing item-center gap-10">
            <h2 className="mb-4 text-2xl text-white text-center font-bold">
              Menjadi Bisnis Partner Penakita
            </h2>
            <div className="">
              <Swiper
                pagination={true}
                modules={[Pagination]}
                className="mySwiper"
                spaceBetween={16}
              >
                {Array(9)
                  .fill(0)
                  .map((testimonial) => (
                    <SwiperSlide>
                      <LandingPageMainTestimonialsCard
                        name="Rach Alida Bahaweres"
                        link="https://lidahbahaweres.com"
                        profilePicture="https://picsum.photos/id/237/200/300"
                        testimonialContent="Penakita juga sangat aktif menggandeng teman-teman blogger daerah sehingga tak hanya Jabodetabek saja. Ini Karena banyak teman-teman blogger yang diluar Jabodetabek juga punya karya dan selalu berusaha yang terbaik. Untuk pembayaran menurut saya tidak ada masalah karena penakita selalu membayar sebelum digaji. Fee yang diberikanpun transparan"
                      />
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default LandingPageMainTestimonials;
