import MultiLines from "../../../components/MultiLines";

function ForumPostContent({ post }) {
  return (
    <div className="mb-4">
      {post.images && (
        <div className="mb-2 flex items-center gap-2 overflow-auto">
          {post.images.map((image, i) =>
            i <= 4 ? (
              <img
                src={image}
                alt={`Image ${i + 1}`}
                className="w-20 h-20 bg-disable flex-shrink-0 rounded-md overflow-hidden"
              />
            ) : (
              <></>
            )
          )}
        </div>
      )}
      <article className="mb-2 text-sm leading-5">
        <MultiLines className="mb-4" inputString={post.message} />
      </article>
    </div>
  );
}
export default ForumPostContent;
