function UpdatesIcon(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.21653 16.0029C7.05653 16.0029 6.89553 15.9519 6.75953 15.8479C6.43153 15.5949 6.36953 15.1239 6.62253 14.7959L9.61553 10.9059C9.73753 10.7469 9.91853 10.6439 10.1165 10.6189C10.3185 10.5929 10.5165 10.6489 10.6735 10.7739L13.4935 12.9889L15.9605 9.80587C16.2145 9.47687 16.6845 9.41587 17.0125 9.67187C17.3405 9.92587 17.4005 10.3969 17.1465 10.7239L14.2165 14.5039C14.0945 14.6619 13.9145 14.7649 13.7165 14.7889C13.5165 14.8159 13.3185 14.7579 13.1605 14.6349L10.3425 12.4209L7.81153 15.7099C7.66353 15.9019 7.44153 16.0029 7.21653 16.0029Z"
        fill="white"
      />
      <mask
        id="mask0_705_6104"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x={17}
        y={2}
        width={6}
        height={6}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.2949 2H22.6389V7.3449H17.2949V2Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_705_6104)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.9669 3.5C19.3209 3.5 18.7949 4.025 18.7949 4.672C18.7949 5.318 19.3209 5.845 19.9669 5.845C20.6129 5.845 21.1389 5.318 21.1389 4.672C21.1389 4.025 20.6129 3.5 19.9669 3.5ZM19.9669 7.345C18.4939 7.345 17.2949 6.146 17.2949 4.672C17.2949 3.198 18.4939 2 19.9669 2C21.4409 2 22.6389 3.198 22.6389 4.672C22.6389 6.146 21.4409 7.345 19.9669 7.345Z"
          fill="white"
        />
      </g>
      <mask
        id="mask1_705_6104"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x={2}
        y={2}
        width={20}
        height={21}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 2.84204H21.8619V22.703H2V2.84204Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_705_6104)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.233 22.703H7.629C4.262 22.703 2 20.338 2 16.818V8.73604C2 5.21104 4.262 2.84204 7.629 2.84204H14.897C15.311 2.84204 15.647 3.17804 15.647 3.59204C15.647 4.00604 15.311 4.34204 14.897 4.34204H7.629C5.121 4.34204 3.5 6.06604 3.5 8.73604V16.818C3.5 19.523 5.082 21.203 7.629 21.203H16.233C18.741 21.203 20.362 19.482 20.362 16.818V9.77904C20.362 9.36504 20.698 9.02904 21.112 9.02904C21.526 9.02904 21.862 9.36504 21.862 9.77904V16.818C21.862 20.338 19.6 22.703 16.233 22.703Z"
          fill="white"
        />
      </g>
    </svg>
  );
}
export default UpdatesIcon;
