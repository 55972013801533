import { useState, useEffect } from "react";

function useError(
  initialState = {
    code: 200,
    status: "ok",
    data: "",
    message: "",
  }
) {
  const [errorState, setErrorState] = useState(initialState);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsError(errorState.status.toLowerCase() !== "ok");
  }, [errorState]);

  const setError = (
    nextError = {
      code: 200,
      status: "ok",
      data: "",
      message: "",
    }
  ) => setErrorState((prevError) => ({ ...prevError, ...nextError }));

  return {
    error: errorState,
    setError,
    isError,
  };
}

export default useError;
