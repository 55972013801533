import { Link } from "react-router-dom";

function CoursesMyCourseListCard({ data }) {
  return (
    <Link
      to={`/courses/me/${data.bloggerCourseId}`}
      data-testid={`popular-course-card-${data.bloggerCourseId}`}
      className="h-48 hover:bg-gray-50 rounded-md overflow-hidden shadow-md aspect-auto"
    >
      <img
        src={data.image}
        alt={"Thumbnail " + data.title}
        className="h-24 w-full bg-gray-200 object-cover"
      ></img>
      <div className="h-24 p-3 flex flex-col justify-between">
        <p className="text-xs line-clamp-2">{data.title}</p>
        <footer className="flex flex-col">
          <div className="flex items-center justify-between">
            <div className="text-3xs text-disable">Progress</div>
            <div className="text-3xs">
              {data.progress.tasks.done + data.progress.materials.done}/
              {data.progress.tasks.total + data.progress.materials.total}
            </div>
          </div>
          <div className="mt-0.5 bg-soft rounded-md overflow-hidden">
            <div
              className="py-1 bg-primary rounded-md overflow-hidden"
              style={{
                width: `${
                  ((data.progress.tasks.done + data.progress.materials.done) /
                    (data.progress.tasks.total +
                      data.progress.materials.total)) *
                  100
                }%`,
              }}
            ></div>
          </div>
        </footer>
      </div>
    </Link>
  );
}
export default CoursesMyCourseListCard;
