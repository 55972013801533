import Button from "../../../components/Button";
import HomeworkDetailEvidenceCard from "./HomeworkDetailEvidenceCard";
import HomeworkDetailSubmitEvidenceIllustration from "./HomeworkDetailSubmitEvidenceIllustration";

function HomeworkDetailSubmitEvidenceSection({ isEvidenceEmpty, handleModal }) {
  return (
    <section className="">
      <h2 className="mb-2 font-bold">Evidence</h2>
      {true && (
        <div className="py-7 flex flex-col gap-4 items-center justify-center">
          <HomeworkDetailSubmitEvidenceIllustration />
          <Button onClick={handleModal}>Submit Evidence</Button>
        </div>
      )}
      {false && (
        <div className="py-4 flex flex-col gap-2 items-stretch justify-center">
          {[0,0,0,0].map(item => (
            <HomeworkDetailEvidenceCard />
          ))}
        </div>
      )}
    </section>
  );
}
export default HomeworkDetailSubmitEvidenceSection;
