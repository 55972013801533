import { useContext } from "react";
import { Navigate, matchPath } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import { SurveyContext } from "../contexts/SurveyContext";
import SurveyNotCompletedPopup from "./SurveyNotCompletedPopup";

function RequireAuth({ element }) {
  const { isAuth, authIsLoading } = useContext(AuthContext);
  const { surveyIsCompleted } = useContext(SurveyContext);
  return !authIsLoading ? (
    isAuth ? (
      // Also checks if survey has been done before
      <>
        {element}
        {surveyIsCompleted ? <></> : <SurveyNotCompletedPopup />}
      </>
    ) : (
      <Navigate replace to="/" />
    )
  ) : (
    // Placeholder components when loading
    <>
      <div className="w-screen h-screen overflow-y-hidden">
        <header className="h-20 px-4 bg-white border-b sticky top-0 z-40">
          <div className="mx-auto max-w-4xl h-20 flex flex-row justify-between items-center">
            <div className="w-12 h-12 animate-pulse bg-gray-100 lg:hidden"></div>
            <div className="w-28 h-12 animate-pulse bg-gray-100"></div>
            <div className="hidden lg:flex h-20  ">
              <nav className="">
                <div className="h-20 flex flex-row gap-8 justify-end items-center">
                  <div className="w-24 h-4 animate-pulse bg-gray-100"></div>
                  <div className="w-24 h-4 animate-pulse bg-gray-100"></div>
                  <div className="w-24 h-4 animate-pulse bg-gray-100"></div>
                  <div className="w-24 h-4 animate-pulse bg-gray-100"></div>
                  <div className="w-24 h-4 animate-pulse bg-gray-100"></div>
                </div>
              </nav>
            </div>
          </div>
        </header>
        <div className="max-w-7xl p-4 mx-auto md:grid md:grid-cols-12 items-start gap-4 overflow-y-hidden">
          <div className="lg:col-span-2 hidden lg:block">
            <div className="p-4 rounded-lg bg-white border text-white flex flex-col gap-2">
              <div className="p-3 rounded-md">
                <div className="w-24 h-4 bg-gray-100 animate-pulse"></div>
              </div>
              <div className="p-3 rounded-md">
                <div className="w-24 h-4 bg-gray-100 animate-pulse"></div>
              </div>
              <div className="p-3 rounded-md">
                <div className="w-24 h-4 bg-gray-100 animate-pulse"></div>
              </div>
              <div className="p-3 rounded-md">
                <div className="w-24 h-4 bg-gray-100 animate-pulse"></div>
              </div>
              <div className="p-3 rounded-md">
                <div className="w-24 h-4 bg-gray-100 animate-pulse"></div>
              </div>
              <div className="p-3 rounded-md">
                <div className="w-24 h-4 bg-gray-100 animate-pulse"></div>
              </div>
            </div>
          </div>
          <div className="h-screen md:p-5 md:col-span-8 lg:col-span-7 overflow-visible md:rounded-md md:border md:bg-white">
            <div className="animate-pulse">
              <div className="w-48 h-4 mb-4 bg-gray-100"></div>
              <div className="w-64 h-8 mb-4 bg-gray-100"></div>
              <div className="w-24 h-5 mb-2 bg-gray-100"></div>
              <div className="mb-4">
                <div className="w-full h-4 mb-2 bg-gray-100"></div>
                <div className="w-full h-4 mb-2 bg-gray-100"></div>
                <div className="w-full h-4 mb-2 bg-gray-100"></div>
                <div className="w-2/3 h-4 mb-2 bg-gray-100"></div>
              </div>
            </div>
          </div>
          <div className="mt-4 md:mt-0 md:col-span-4 lg:col-span-3 flex flex-col gap-4">
            <div className="lg:col-span-2 hidden lg:block">
              <div className="h-96 p-4 rounded-lg bg-white border text-white flex flex-col gap-2">
                <div className="w-24 h-4 bg-gray-100 animate-pulse"></div>
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default RequireAuth;
