import landingPagePartnershipIllustration_1 from "../../../assets/landing-page-partnership-illustration-1.svg";
import landingPagePartnershipIllustration_2 from "../../../assets/landing-page-partnership-illustration-2.svg";
import landingPagePartnershipIllustration_3 from "../../../assets/landing-page-partnership-illustration-3.svg";

function LandingPageMainPartnership({ className = "" }) {
  return (
    <section id="partnership" className={className}>
      <div className="max-w-full md:max-w-lg lg:max-w-5xl mx-auto">
        <div className="p-6 flex flex-col font-sans-landing item-center lg:items-start">
          <h2 className="mb-4 lg:mb-10 text-text text-2xl lg:text-4xl text-center font-bold">
            Menjadi Bisnis Partner Penakita
          </h2>
          <article className="lg:w-[512px] p-6 rounded-2xl shadow-md bg-white flex flex-col items-stretch gap-4">
            <div className="flex items-center justify-start gap-3 font-sans-landing">
              <div className="flex-shrink-0 flex flex-col items-center justify-center">
                <img
                  src={landingPagePartnershipIllustration_1}
                  alt=""
                  className="h-8 lg:h-14"
                />
              </div>
              <div className="flex flex-col justify-center">
                <h3 className="text-base lg:text-xl text-primary font-bold">
                  Course
                </h3>
                <p className="text-xs lg:text-sm">
                  Ikuti semua course yang direkomendasikan untuk meningkatkan
                  pengetahuan kamu
                </p>
              </div>
            </div>
            <div className="flex items-center justify-start gap-3 font-sans-landing">
              <div className="flex-shrink-0 flex flex-col items-center justify-center">
                <img
                  src={landingPagePartnershipIllustration_2}
                  alt=""
                  className="h-8 lg:h-14"
                />
              </div>
              <div className="flex flex-col justify-center">
                <h3 className="text-base lg:text-xl text-primary font-bold">
                  Business Environment
                </h3>
                <p className="text-xs lg:text-sm">
                  Mendapatkan lingkungan bisnis yang profesional punya
                </p>
              </div>
            </div>
            <div className="flex items-center justify-start gap-3 font-sans-landing">
              <div className="flex-shrink-0 flex flex-col items-center justify-center">
                <img
                  src={landingPagePartnershipIllustration_3}
                  alt=""
                  className="h-8 lg:h-14"
                />
              </div>
              <div className="flex flex-col justify-center">
                <h3 className="text-base lg:text-xl text-primary font-bold">
                  Colaboration
                </h3>
                <p className="text-xs lg:text-sm">
                  Kolaborasi dengan UMKM dan komunitas
                </p>
              </div>
            </div>
          </article>
        </div>
      </div>
    </section>
  );
}
export default LandingPageMainPartnership;
