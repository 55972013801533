import landingPageBenefitsIllustration1 from "../../../assets/landing-page-benefits-illustration-1.svg";

function LandingPageMainBenefits({ className = "" }) {
  return (
    <div className={`max-w-full md:max-w-lg lg:max-w-5xl mx-auto ${className}`}>
      <div className="mb-14 p-6 text-text text-center lg:text-left font-sans-landing flex flex-col lg:flex-row items-center">
        <img
          src={landingPageBenefitsIllustration1}
          alt=""
          className="h-72 lg:h-96"
        />
        <div className="">
          <h2 className="mb-4 text-2xl lg:text-4xl font-bold">Benefits</h2>
          <p className="lg:text-xl">
            Menjadi Mitra Bisnis Penakita kamu bisa mendapat pengalaman bekerja
            sebagai profesional blogger influencer serta belajar bagaimana cara
            mengembangkan aset digital yang kamu punya
          </p>
        </div>
        <div className="mb-36"></div>
      </div>
    </div>
  );
}
export default LandingPageMainBenefits;
