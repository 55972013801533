import { Link } from "react-router-dom";
import Button from "../../../components/Button";

export default function MyCourseDetailMaterialsCard({ material, disabled }) {
  return (
    <article
      className={`p-4 rounded-lg shadow-md bg-white flex gap-4 items-center justify-between text-disable2`}
    >
      <div className="mr-auto">
        <h3 className="mb-2 font-bold">{material.title}</h3>
        <p className="text-[14px] line-clamp-3">{material.description}</p>
      </div>
      <div>
        {/* {!disabled && (
          <Link
            to={`homeworks/${task.courseTaskId}`}
            className={`px-3 py-2 text-white hover:shadow-md rounded-3xl bg-primary`}
          >
            View
          </Link>
        )} */}
      </div>
    </article>
  );
}
