function UpdatesFeedSkeleton(props) {
  return (
    <div className="p-4 rounded-md border bg-white flex gap-4 animate-pulse">
      <div className="w-24 h-24 bg-gray-100 rounded-md flex-shrink-0"></div>
      <div className="w-full flex flex-col">
        <div className="w-full h-6 mb-2 bg-gray-100"></div>
        <div className="w-24 h-4 mb-3 bg-gray-100"></div>
        <div className="w-full h-3 mb-1 bg-gray-100"></div>
        <div className="w-full h-3 bg-gray-100"></div>
      </div>
    </div>
  );
}
export default UpdatesFeedSkeleton;
