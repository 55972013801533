export const GET_COURSES_API_ROUTE = "/blogger/courses";
export const GET_COURSE_DETAIL_API_ROUTE = (courseId) =>
  `/blogger/courses/${courseId}`;
export const ENROLL_COURSE_DETAIL_API_ROUTE = (courseId) =>
  `/blogger/courses/${courseId}`;
export const GET_COURSE_CATEGORIES = "/blogger/course-categories";

export const GET_PROFILE_API_ROUTE = "/blogger/profile";

export const GET_BLOGGER_COURSES_API_ROUTE = "/blogger/blogger-courses";
export const GET_BLOGGER_COURSE_DETAIL_API_ROUTE = (bloggerCourseId) =>
  `/blogger/blogger-courses/${bloggerCourseId}`;
export const GET_BLOGGER_TASKS_PREVIEW_API_ROUTE =
  "/blogger/blogger-courses/tasks/preview";
export const GET_BLOGGER_TASK_DETAIL_API_ROUTE = (bloggerCourseTaskId) =>
  `/blogger/blogger-courses/tasks/${bloggerCourseTaskId}`;
export const POST_BLOGGER_TASK_EVIDENCE_API_ROUTE = (bloggerCourseTaskId) =>
  `/blogger/blogger-courses/tasks/evidence/${bloggerCourseTaskId}`;
export const GET_BLOGGER_TASK_LIST = (bloggerCourseId) =>
  `/blogger/blogger-courses/tasks/list/${bloggerCourseId}`;
export const VIEW_MATERIAL = (bloggerCourseMaterialId) =>
  `/blogger/blogger-courses/material/${bloggerCourseMaterialId}`;

export const GET_LAUNCHPADS_API_ROUTE = "/blogger/launchpads";
export const GET_LAUNCHPADS_SUBMISSIONS_API_ROUTE =
  "/blogger/launchpads/submission";
export const GET_LAUNCHPAD_DETAIL_API_ROUTE = (bloggerLaunchpadId) =>
  `/blogger/launchpads/${bloggerLaunchpadId}`;
export const POST_LAUNCHPAD_EVIDENCE_API_ROUTE = (bloggerLaunchpadId) =>
  `/blogger/launchpads/${bloggerLaunchpadId}`;

export const GET_FORUMS_API_ROUTE = "/blogger/forums";
export const POST_FORUM_API_ROUTE = "/blogger/forums";
export const GET_FORUM_DETAIL_API_ROUTE = (forumId) =>
  `/blogger/forums/${forumId}`;
export const POST_FORUM_COMMENT_API_ROUTE = (forumId) =>
  `/blogger/forums/${forumId}/comment`;

export const GET_UPCOMING_SCHEDULES_API_ROUTE = "/blogger/schedules/upcoming";
export const GET_SCHEDULES_API_ROUTE = "/blogger/schedules";
export const GET_SCHEDULES_ITEM_DETAIL = (type, scheduleId) =>
  `/blogger/schedules/${type}/${scheduleId}`;

export const GET_SURVEY_QUESTIONS_API_ROUTE = "/blogger/survey/questions";
export const POST_ANSWERS_API_ROUTE = "/blogger/survey/questions/answer";
