function LandingPageMissionHero(props) {
  return (
    <>
      <section className="px-6 py-16 pb-48">
        <div className="max-w-full md:max-w-lg lg:max-w-5xl mx-auto">
          <article className="p-8 shadow-md rounded-3xl bg-white font-sans-landing">
            <h2 className="mb-4 text-text text-2xl font-bold">Tittle</h2>
            <p className="mb-4 text-sm">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eius, rem. Libero repellendus atque nesciunt iste voluptate pariatur! Minus neque consectetur unde, incidunt, quos harum, ullam beatae est optio fugiat delectus? Debitis quisquam molestiae repellendus suscipit natus culpa, necessitatibus architecto eligendi maxime nam, exercitationem aperiam odio provident doloribus impedit nulla cum odit alias, quibusdam rerum. Doloribus minima, id dicta impedit perspiciatis officiis recusandae placeat neque quas!</p>
            <p className="mb-4 text-sm">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eius, rem. Libero repellendus atque nesciunt iste voluptate pariatur! Minus neque consectetur unde, incidunt, quos harum, ullam beatae est optio fugiat delectus? Debitis quisquam molestiae repellendus suscipit natus culpa, necessitatibus architecto eligendi maxime nam, exercitationem aperiam odio provident doloribus impedit nulla cum odit alias, quibusdam rerum. Doloribus minima, id dicta impedit perspiciatis officiis recusandae placeat neque quas!</p>
          </article>
        </div>
      </section>
    </>
  );
}
export default LandingPageMissionHero;
