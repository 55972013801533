import { omit } from "lodash";
import { useEffect, useState } from "react";
import Pagination from "../../../components/Pagination";
import { categories } from "../../../constants/categories";
import { getBloggerCourses } from "../../../services/bloggerCourseService";
import useReactiveSearchParams from "../../../utils/useReactiveSearchParams";
import CoursesAllCourseListCard from "./CoursesAllCourseListCard";
import CoursesCategoryDropdown from "./CoursesCategoryDropdown";
import CoursesMyCourseListCard from "./CoursesMyCourseListCard";

function CoursesMyCourseList(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [searchBoxIsFocus, setSearchBoxIsFocus] = useState(false);
  const [myCoursesData, setMyCoursesData] = useState([]);
  const [searchParams, setSearchParams] = useReactiveSearchParams();
  const [meta, setMeta] = useState({});
  const page = Number.parseInt(searchParams.page || "1");
  const sortBy = searchParams.sortBy || "Popular";
  const keyword = searchParams.keyword || "";

  useEffect(() => {
    getMyCourseData();
  }, [searchParams]);

  const getMyCourseData = async () => {
    try {
      setIsError(false);
      setIsLoading(true);
      setMyCoursesData(
        (await getBloggerCourses({ page: 1, sortBy: "Popular", ...searchParams })).courses
      );
      setMeta((await getBloggerCourses({ page, sortBy, keyword })).meta);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="py-4">
      <div className="flex gap-4 items-center">
        <select
          name="Sort"
          id="sort-menu"
          className={`${
            searchBoxIsFocus ? "hidden sm:block" : ""
          } h-8 p-1.5 md:py-1 md:px-3 text-sm text-disable2 bg-white border border-gray-300 rounded-md`}
          onChange={(event) => {
            setSearchParams({ ...searchParams, sortBy: event.target.value });
          }}
        >
          <option value="Popular" data-testid="sort-option-popular">
            Popular
          </option>
          <option value="Latest" data-testid="sort-option-new">
            New
          </option>
          <option value="Oldest" data-testid="sort-option-old">
            Old
          </option>
        </select>
        <CoursesCategoryDropdown searchBoxIsFocus={searchBoxIsFocus} />
        <div
          className={`p-1 rounded-md ${
            searchBoxIsFocus ? "sm:hidden" : "hidden"
          } text-gray-400 hover:bg-gray-200`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            style={{ fill: "currentColor", transform: "", msfilter: "" }}
          >
            <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z" />
          </svg>
        </div>

        <label className="px-3 pl-4 py-2 ml-auto rounded-3xl border inline-flex items-center">
          <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="7.84394"
              cy="7.84442"
              r="5.99237"
              stroke="#AEAEAE"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12.0117 12.3235L14.3611 14.6667"
              stroke="#AEAEAE"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <input
            type="search"
            name="Search Course"
            id="search-course"
            placeholder="Cari Course"
            onChange={(e) =>
              setSearchParams({ ...searchParams, keyword: e.target.value })
            }
            onFocus={() => setSearchBoxIsFocus(true)}
            onBlur={() => setSearchBoxIsFocus(false)}
            className={"w-0 sm:w-full focus:w-full ml-2 outline-none"}
          />
        </label>
      </div>
      {isError && (
        <div className="h-48 flex items-center justify-center">
          <p className="text-red-800 text-sm">Gagal Memperoleh Informasi</p>
        </div>
      )}
      {isLoading && (
        <div className="pt-4 grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
          {[0, 0, 0, 0, 0, 0, 0, 0].map((x) => (
            <div
              data-testid="popular-course-section-skeleton"
              className="flex-shrink-0 h-48 animate-pulse rounded-md overflow-hidden border aspect-auto"
            >
              <div className="h-24 w-full bg-gray-200 object-cover"></div>
              <div className="h-24 p-3 flex flex-col justify-between">
                <div className="flex flex-wrap gap-1">
                  <span className="w-full p-2 bg-gray-200"></span>
                  <span className="w-1/4 p-2 bg-gray-200"></span>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {!isError && !isLoading && (
        <>
          <div className="pt-4 grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
            {myCoursesData.map((data, i) => (
              <CoursesMyCourseListCard key={i} data={data} />
            ))}
          </div>
          <Pagination
            page={page}
            maxPage={Math.ceil(meta.total / meta.limit)}
          />
        </>
      )}
    </section>
  );
}
export default CoursesMyCourseList;
