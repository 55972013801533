import landingPageRoadmapsIllustration_1 from "../../../assets/landing-page-roadmaps-illustration-1.svg";
import landingPageRoadmapsIllustration_2 from "../../../assets/landing-page-roadmaps-illustration-2.png";
import landingPageRoadmapsIllustration_3 from "../../../assets/landing-page-roadmaps-illustration-3.png";
import landingPageRoadmapsIllustration_4 from "../../../assets/landing-page-roadmaps-illustration-4.png";
import landingPageRoadmapsBg from "../../../assets/landing-page-roadmaps-bg.svg";

function LandingPageMainRoadmaps({className = ""}) {
  return (
    <>
      <section id="roadmaps" className={`py-44 sm:py-56 lg:py-64 bg-primary ${className}`} >
        <div className="max-w-full md:max-w-lg lg:max-w-5xl mx-auto relative z-50">
          <div className="p-6 font-sans-landing flex flex-col lg:flex-row items-center gap-6">
            <div className="flex-shrink-0 flex flex-col">
              <h2 className="mb-4 text-2xl text-center lg:text-left text-white font-bold">
                Roadmap Penakita
              </h2>
              <img
                src={landingPageRoadmapsIllustration_1}
                alt=""
                className="h-96"
              />
            </div>
            <div className="flex flex-col gap-8">
              <article className="p-5 flex-1 rounded-2xl shadow-md bg-white flex gap-4">
                <div className="flex-shrink-0 flex flex-col items-center justify-center">
                  <img
                    src={landingPageRoadmapsIllustration_2}
                    alt=""
                    className="w-10 h-10"
                  />{" "}
                </div>
                <div className="flex flex-col justify-center">
                  <h3 className="text-text lg:text-2xl font-bold uppercase">
                    Enroll
                  </h3>
                  <p className="text-xs lg:text-base">
                    Sign up di platform penakita.id dan isi beberapa data
                    terkait aset digital yang kamu punya
                  </p>
                </div>
              </article>
              <article className="p-5 flex-1 rounded-2xl shadow-md bg-white flex gap-4">
                <div className="flex-shrink-0 flex flex-col items-center justify-center">
                  <img
                    src={landingPageRoadmapsIllustration_3}
                    alt=""
                    className="w-10 h-10"
                  />{" "}
                </div>
                <div className="flex flex-col justify-center">
                  <h3 className="text-text lg:text-2xl font-bold uppercase">
                    Course
                  </h3>
                  <p className="text-xs lg:text-base">
                    Ikuti semua course yang direkomendasikan untuk meningkatkan
                    pengetahuan kamu
                  </p>
                </div>
              </article>
              <article className="p-5 flex-1 rounded-2xl shadow-md bg-white flex gap-4">
                <div className="flex-shrink-0 flex flex-col items-center justify-center">
                  <img
                    src={landingPageRoadmapsIllustration_4}
                    alt=""
                    className="w-10 h-10"
                  />{" "}
                </div>
                <div className="flex flex-col justify-center">
                  <h3 className="text-text lg:text-2xl font-bold uppercase">
                    Mission
                  </h3>
                  <p className="text-xs lg:text-base">
                    Ikuti semua mission yang ada untuk meningkatkan skill kamu
                  </p>
                </div>
              </article>
            </div>
            <div className="mb-36"></div>
          </div>
        </div>
      </section>
    </>
  );
}
export default LandingPageMainRoadmaps;
