import Breadcrumb from "../../../components/Breadcrumb";

function SchedulesItemDetailHeader({data}) {
  return (
    <header className="mb-4">
      <Breadcrumb
        hierarchy={[
          {
            depth: 1,
            isCurrent: false,
            name: "Schedules",
            url: "/schedules",
          },
          {
            depth: 2,
            isCurrent: true,
            name: "Detail",
            url: window.location.pathname,
          },
        ]}
      />
      <h1 className="mt-2 text-text text-2xl font-bold">{data.title ?? ""}</h1>
    </header>
  );
}
export default SchedulesItemDetailHeader;
