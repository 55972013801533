import "../../styles/landing.css";
import LandingPageHeader from "../../components/LandingPageHeader";
import LandingPageMainHero from "./components/LandingPageMainHero";
import landingPageMainHeroBg from "../../assets/landing-page-hero-bg.svg";
import LandingPageMainAbout from "./components/LandingPageMainAbout";
import LandingPageMainWhoCanJoin from "./components/LandingPageMainWhoCanJoin";
import LandingPageMainBenefits from "./components/LandingPageMainBenefits";
import LandingPageMainRoadmaps from "./components/LandingPageMainRoadmaps";
import LandingPageMainPartnership from "./components/LandingPageMainPartnership";
import LandingPageFooter from "../../components/LandingPageFooter";
import LandingPageMainTestimonials from "./components/LandingPageMainTestimonials";
import { Helmet } from "react-helmet";

function LandingPageMain(props) {
  return (
    <>
      <Helmet>
        <style>
          {`
            body {
              background: url(../assets/landing-page-light-large-bg.svg);
              background-size: 100%;
            }
          `}
        </style>
      </Helmet>
      <div
        style={{
          height: 851,
          background: `url(${landingPageMainHeroBg})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "auto 100%",
        }}
        className="bg-primary"
      >
        <LandingPageHeader />
        <LandingPageMainHero />
      </div>
      <LandingPageMainAbout />
      <LandingPageMainWhoCanJoin />
      <LandingPageMainBenefits />
      <LandingPageMainRoadmaps className="-mb-48 relative z-50" />
      <LandingPageMainPartnership className="-mb-24" />
      <svg
        width={1080}
        height={71}
        viewBox="0 0 1080 71"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="w-full h-auto"
      >
        <path
          d="M0 71C0 71 189.5 0 540 0C890.5 0 1080 71 1080 71H0Z"
          fill="#FF9D25"
        />
      </svg>
      <div className="bottom-section bg-primary">
        <LandingPageMainTestimonials />
        <LandingPageFooter />
      </div>
    </>
  );
}
export default LandingPageMain;
