import landingPageLeaderboardTabelIllustration from "../../../assets/landing-page-leaderboard-tabel-illustration.svg"

function LandingPageLeaderboardTabelPoin(props) {
  return (
    <>
      <section className="relative z-10">
        <div className="max-w-full md:max-w-lg lg:max-w-5xl mx-auto p-6">
          <article className="py-6 px-4 lg:p-12 shadow-md rounded-3xl bg-white font-sans-landing flex flex-col items-center lg:grid lg:grid-cols-3 gap-8">
            <div className="lg:self-start flex flex-col gap-4">
              <h1 className="text-2xl text-text font-bold">Tabel Poin</h1>
              <select
                name="tabel-poin-filter"
                id="tabel-poin-filter"
                className="w-48 h-8 p-1.5 md:py-1 md:px-3 text-sm text-disable2 bg-white border border-gray-300 rounded-md"
              >
                <option value="all">Semua</option>
              </select>
              <img
                src={landingPageLeaderboardTabelIllustration}
                alt=""
                className="w-52 mt-16"
              />
            </div>
            <div className="w-full lg:col-span-2 flex flex-col items-stretch gap-4">
              <div className="p-1 flex items-stretch justify-start gap-2">
                <div className="w-8 flex-shrink-0 flex items-center justify-center">
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.5303 16.0303C19.2641 16.2966 18.8474 16.3208 18.5538 16.1029L18.4697 16.0303L12 9.561L5.53033 16.0303C5.26406 16.2966 4.8474 16.3208 4.55379 16.1029L4.46967 16.0303C4.2034 15.7641 4.1792 15.3474 4.39705 15.0538L4.46967 14.9697L11.4697 7.96967C11.7359 7.7034 12.1526 7.6792 12.4462 7.89705L12.5303 7.96967L19.5303 14.9697C19.8232 15.2626 19.8232 15.7374 19.5303 16.0303Z"
                      fill="#27AE60"
                    />
                  </svg>
                </div>
                <div className="px-1 flex-shrink-0 flex items-center justify-center">
                  <p className="text-xs text-text">1</p>
                </div>
                <div className="w-8 h-8 bg-white border rounded-full uppercase text-primary text-xs font-bold flex-shrink-0 flex items-center justify-center">
                  IN
                </div>
                <div className="h-8 flex-grow flex items-center justify-start">
                  <p className="text-xs font-semibold">Indra Nurwahyudi </p>
                </div>
                <div className="h-8 flex items-center justify-center">
                  <p className="text-xs text-primary font-semibold">
                    12.000 pts{" "}
                  </p>
                </div>
              </div>
              <div className="p-1 flex items-stretch justify-start gap-2">
                <div className="w-8 flex-shrink-0 flex items-center justify-center">
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.46967 7.96967C4.73594 7.7034 5.1526 7.6792 5.44621 7.89705L5.53033 7.96967L12 14.439L18.4697 7.96967C18.7359 7.7034 19.1526 7.6792 19.4462 7.89705L19.5303 7.96967C19.7966 8.23594 19.8208 8.6526 19.6029 8.94621L19.5303 9.03033L12.5303 16.0303C12.2641 16.2966 11.8474 16.3208 11.5538 16.1029L11.4697 16.0303L4.46967 9.03033C4.17678 8.73744 4.17678 8.26256 4.46967 7.96967Z"
                      fill="#EB5757"
                    />
                  </svg>
                </div>
                <div className="px-1 flex-shrink-0 flex items-center justify-center">
                  <p className="text-xs text-text">2</p>
                </div>
                <div className="w-8 h-8 bg-white border rounded-full uppercase text-primary text-xs font-bold flex-shrink-0 flex items-center justify-center">
                  IN
                </div>
                <div className="h-8 flex-grow flex items-center justify-start">
                  <p className="text-xs font-semibold">Indra Nurwahyudi </p>
                </div>
                <div className="h-8 flex items-center justify-center">
                  <p className="text-xs text-primary font-semibold">
                    12.000 pts{" "}
                  </p>
                </div>
              </div>
              <div className="p-1 flex items-stretch justify-start gap-2">
                <div className="w-8 flex-shrink-0 flex items-center justify-center">
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20 12.2743C20 12.654 19.7178 12.9678 19.3518 13.0174L19.25 13.0243L4.25 13.0243C3.83579 13.0243 3.5 12.6885 3.5 12.2743C3.5 11.8946 3.78215 11.5808 4.14823 11.5311L4.25 11.5243L19.25 11.5243C19.6642 11.5243 20 11.8601 20 12.2743Z"
                      fill="#828282"
                    />
                  </svg>
                </div>
                <div className="px-1 flex-shrink-0 flex items-center justify-center">
                  <p className="text-xs text-text">3</p>
                </div>
                <div className="w-8 h-8 bg-white border rounded-full uppercase text-primary text-xs font-bold flex-shrink-0 flex items-center justify-center">
                  IN
                </div>
                <div className="h-8 flex-grow flex items-center justify-start">
                  <p className="text-xs font-semibold">Indra Nurwahyudi </p>
                </div>
                <div className="h-8 flex items-center justify-center">
                  <p className="text-xs text-primary font-semibold">
                    12.000 pts{" "}
                  </p>
                </div>
              </div>
              <div className="p-1 flex items-stretch rounded-md bg-gray-100 justify-start gap-2">
                <div className="w-8 flex-shrink-0 flex items-center justify-center">
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.5303 16.0303C19.2641 16.2966 18.8474 16.3208 18.5538 16.1029L18.4697 16.0303L12 9.561L5.53033 16.0303C5.26406 16.2966 4.8474 16.3208 4.55379 16.1029L4.46967 16.0303C4.2034 15.7641 4.1792 15.3474 4.39705 15.0538L4.46967 14.9697L11.4697 7.96967C11.7359 7.7034 12.1526 7.6792 12.4462 7.89705L12.5303 7.96967L19.5303 14.9697C19.8232 15.2626 19.8232 15.7374 19.5303 16.0303Z"
                      fill="#27AE60"
                    />
                  </svg>
                </div>
                <div className="px-1 flex-shrink-0 flex items-center justify-center">
                  <p className="text-xs text-text">1</p>
                </div>
                <div className="w-8 h-8 bg-white border rounded-full uppercase text-primary text-xs font-bold flex-shrink-0 flex items-center justify-center">
                  IN
                </div>
                <div className="h-8 flex-grow flex items-center justify-start">
                  <p className="text-xs font-semibold">Indra Nurwahyudi </p>
                </div>
                <div className="h-8 flex items-center justify-center">
                  <p className="text-xs text-primary font-semibold">
                    12.000 pts{" "}
                  </p>
                </div>
              </div>
            </div>
          </article>
        </div>
      </section>
    </>
  );
}
export default LandingPageLeaderboardTabelPoin;
