import moment from "moment";
import { useParams } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";

function LaunchpadMissionDetailHeader({
  data = {},
}) {
  const { bloggerLaunchpadId } = useParams();

  return (
    <header className="mb-5 flex flex-col">
      <div className="mb-3 flex items-center justify-between gap-4">
        <Breadcrumb
          hierarchy={[
            {
              depth: 1,
              name: "Launchpad",
              url: "/launchpad",
              isCurrent: false,
            },
            {
              depth: 2,
              name: data?.title ?? "Launchpad Mission",
              url: `${bloggerLaunchpadId}`,
              isCurrent: true,
            },
          ]}
        />
        {data?.title && <p className="text-xs text-text">
          Due Date: <span className="ml-2 font-semibold text-error">{moment(data.dueDate).format("DD MMM YYYY")}</span>
        </p>}
      </div>
      {data?.title && <h1 className="text-2xl font-bold">{data.title}</h1>}
    </header>
  );
}
export default LaunchpadMissionDetailHeader;
