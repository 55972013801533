import { useEffect, useState } from "react";
import ForumFeedCard from "./ForumFeedCard";
import ForumFeedCardSkeleton from "./ForumFeedCardSkeleton";
import Pagination from "../../../components/Pagination";
import { getForums } from "../../../services/forumService";
import useReactiveSearchParams from "../../../utils/useReactiveSearchParams";

function ForumFeed({ sortBy = "new post", keyword = "" }) {
  const [searchParams, setSearchParams] = useReactiveSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [meta, setMeta] = useState({});
  const [forumData, setForumData] = useState([
    {
      forumId: 0,
      message: "",
      images: [""],
      bloggerName: "",
      photoProfile: "",
      totalComment: 0,
      createdAt: "",
    },
  ]);
  const page = Number.parseInt(searchParams.page || "1");

  const getForumData = async () => {
    try {
      setIsLoading(true);
      setForumData((await getForums({ page, sortBy, keyword })).forums);
      setMeta((await getForums({ page, sortBy, keyword })).meta);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getForumData();
  }, [, page, sortBy, keyword]);

  return (
    <>
      {isLoading && (
        <div className="flex flex-col gap-4">
          {[0, 0, 0].map((x, i) => (
            <ForumFeedCardSkeleton key={i} />
          ))}
        </div>
      )}
      {!isLoading && !isError && (
        <>
          <div className="flex flex-col gap-4">
            {forumData.map((post, i) => (
              <ForumFeedCard key={i} post={post} />
            ))}
          </div>
          <Pagination
            page={page}
            maxPage={Math.ceil(meta.total / meta.limit)}
          />
        </>
      )}
      {isError && (
        <div className="h-72 flex items-center justify-center">
          <p className="mx-auto text-red-800 text-sm text-center">
            Gagal memperoleh informasi
          </p>
        </div>
      )}
    </>
  );
}

export default ForumFeed;
