import SidebarLeft from "../../components/SidebarLeft";
import Main from "../../components/Main";
import SidebarRight from "../../components/SidebarRight";
import Header from "../../components/Header";
import Nav from "../../components/Nav";
import GridLayout from "../../components/GridLayout";
import SchedulesDayViewHeader from "./components/SchedulesDayViewHeader";
import SchedulesDayViewList from "./components/SchedulesDayViewList";
import { useParams } from "react-router-dom";
import useError from "../../utils/useError";
import { useEffect, useState } from "react";
import { getSchedulesDay } from "../../services/scheduleService";
import UpcomingScheduleWidget from "../../components/UpcomingScheduleWidget";
import SchedulesDayViewSkeleton from "./components/SchedulesDayViewSkeleton";

function SchedulesDayView(props) {
  const { year, month, day } = useParams();
  const [schedulesData, setSchedulesData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { error, setError, isError } = useError();

  const getSchedulesData = async () => {
    try {
      setIsLoading(true);
      setError({ status: "ok" });
      setSchedulesData(await getSchedulesDay({ year, month, day }));
    } catch (error) {
      setError({
        code: error.response.status,
        message: error.response.data.message,
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getSchedulesData();
  }, [useParams()]);

  return (
    <>
      <Header />
      <GridLayout>
        <SidebarLeft>
          <Nav />
        </SidebarLeft>
        {isLoading && (
          <Main>
            <SchedulesDayViewHeader />
            <SchedulesDayViewSkeleton />
          </Main>
        )}
        {!isLoading && !isError && (
          <Main>
            <SchedulesDayViewHeader />
            <SchedulesDayViewList schedulesData={schedulesData} />
          </Main>
        )}
        <SidebarRight>
          <UpcomingScheduleWidget limit={4} />
        </SidebarRight>
      </GridLayout>
    </>
  );
}
export default SchedulesDayView;
