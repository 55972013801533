function LocationIcon(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.16764 5.66699C7.52431 5.66699 7.00098 6.19033 7.00098 6.83433C7.00098 7.47766 7.52431 8.00033 8.16764 8.00033C8.81098 8.00033 9.33431 7.47766 9.33431 6.83433C9.33431 6.19033 8.81098 5.66699 8.16764 5.66699ZM8.16764 9.00033C6.97298 9.00033 6.00098 8.02899 6.00098 6.83433C6.00098 5.63899 6.97298 4.66699 8.16764 4.66699C9.36231 4.66699 10.3343 5.63899 10.3343 6.83433C10.3343 8.02899 9.36231 9.00033 8.16764 9.00033Z"
        fill="#FF9D25"
      />
      <mask
        id="mask0_681_6014"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x={2}
        y={1}
        width={12}
        height={14}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.66699 1.3335H13.6667V14.3335H2.66699V1.3335Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_681_6014)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.16699 2.3335C5.68566 2.3335 3.66699 4.3715 3.66699 6.8755C3.66699 10.0615 7.41633 13.1655 8.16699 13.3308C8.91766 13.1648 12.667 10.0608 12.667 6.8755C12.667 4.3715 10.6483 2.3335 8.16699 2.3335ZM8.16699 14.3335C6.97099 14.3335 2.66699 10.6322 2.66699 6.8755C2.66699 3.8195 5.13433 1.3335 8.16699 1.3335C11.1997 1.3335 13.667 3.8195 13.667 6.8755C13.667 10.6322 9.36299 14.3335 8.16699 14.3335Z"
          fill="#FF9D25"
        />
      </g>
    </svg>
  );
}
export default LocationIcon;
