function MyCourseDetailSkeleton(props) {
  return (
    <div className="flex flex-col animate-pulse">
      <div className="mt-2 w-48 h-4 mb-4 bg-gray-100"></div>
      <div className="flex items-center justify-between">
        <div className="w-36 h-6 mb-4 bg-gray-100"></div>
        <div className="w-24 h-10 mb-4 rounded-3xl bg-gray-100"></div>
      </div>
      <div className="w-24 h-5 mb-2 bg-gray-100"></div>
      <div className="mb-4">
        <div className="w-full h-4 mb-2 bg-gray-100"></div>
        <div className="w-full h-4 mb-2 bg-gray-100"></div>
        <div className="w-full h-4 mb-2 bg-gray-100"></div>
        <div className="w-2/3 h-4 mb-2 bg-gray-100"></div>
      </div>
      {[0, 0, 0].map((x, i) => (
        <div key={i} className="mb-4 p-4 rounded-md border bg-white flex items-center justify-start gap-4">
          <div className="flex-1">
            <div className="w-24 h-5 mb-2 bg-gray-100"></div>
            <div className="">
              <div className="w-full h-4 mb-2 bg-gray-100"></div>
              <div className="w-full h-4 mb-2 bg-gray-100"></div>
              <div className="w-2/3 h-4 bg-gray-100"></div>
            </div>
          </div>
          <div className="flex items-center justify-center">
            <div className="w-24 h-10 mb-4 rounded-3xl bg-gray-100"></div>
          </div>
        </div>
      ))}
    </div>
  );
}
export default MyCourseDetailSkeleton;
