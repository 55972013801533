function HomeworkDetailObjective({ homeworkObjective }) {
  return (
    <div className="mb-4">
      <h2 className="mb-2 font-bold">Objective</h2>
      <article className="prose prose-neutral max-w-none leading-5">
        {homeworkObjective}
      </article>
    </div>
  );
}
export default HomeworkDetailObjective;
