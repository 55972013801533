import landingPageWhoCanJoinIllustration1 from "../../../assets/landing-page-who-can-join-illustration-1.svg";
import landingPageWhoCanJoinIllustration2 from "../../../assets/landing-page-who-can-join-illustration-2.svg";
import landingPageWhoCanJoinIllustration3 from "../../../assets/landing-page-who-can-join-illustration-3.svg";

function LandingPageMainWhoCanJoin(props) {
  return (
    <div className="max-w-full md:max-w-lg lg:max-w-5xl mx-auto">
      <div className="mb-14 p-6 text-text text-center font-sans-landing flex flex-col items-center">
        <h2 className="mb-9 text-2xl lg:text-4xl font-bold">Who Can Join</h2>
        <div className="flex flex-col lg:flex-row items-stretch justify-center gap-6 lg:gap-24">
          <article className="p-5 flex-1 rounded-2xl shadow-md bg-white flex flex-col items-center gap-4">
            <img
              src={landingPageWhoCanJoinIllustration1}
              alt=""
              className="h-48"
            />
            <h3 className="text-primary lg:text-2xl font-bold">
              Social Media Enthusiast
            </h3>
            <p>
              Kamu yang aktif dan tertarik menjadi influencer di social media
            </p>
          </article>
          <article className="p-5 flex-1 rounded-2xl shadow-md bg-white flex flex-col items-center gap-4">
            <img
              src={landingPageWhoCanJoinIllustration2}
              alt=""
              className="h-48"
            />
            <h3 className="text-primary lg:text-2xl font-bold">
              Content Creator
            </h3>
            <p>
              Kamu yang hobi bikin konten di social media dan platform digital
            </p>
          </article>
          <article className="p-5 flex-1 rounded-2xl shadow-md bg-white flex flex-col items-center gap-4">
            <img
              src={landingPageWhoCanJoinIllustration3}
              alt=""
              className="h-48"
            />
            <h3 className="text-primary lg:text-2xl font-bold">Blogger</h3>
            <p>
              Kamu yang hobi menulis dan aktif ngeblog bisa jadi bagian dari
              program ini
            </p>
          </article>
        </div>
      </div>
    </div>
  );
}
export default LandingPageMainWhoCanJoin;
