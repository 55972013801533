import { useState, useEffect } from "react";
import { getCourseCategories } from "../../../services/courseService";
import useReactiveSearchParams from "../../../utils/useReactiveSearchParams";
import { omit } from "lodash";

function CoursesCategoryDropdown({ searchBoxIsFocus }) {
  const [searchParams, setSearchParams] = useReactiveSearchParams();
  const [isError, setIsError] = useState();
  const [isLoading, setIsLoading] = useState();
  const [categories, setCategories] = useState([]);

  const getCategories = async () => {
    try {
      setIsError(false);
      setIsLoading(true);
      setCategories(await getCourseCategories());
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCategories()
  }, []);

  return (
    <select
      name="Category"
      id="category-menu"
      className={`${
        searchBoxIsFocus ? "hidden sm:block" : ""
      } h-8 p-1.5 md:py-1 md:px-3 text-sm text-disable2 bg-white border border-gray-300 rounded-md`}
      onChange={(event) => {
        if (event.target.value !== "Semua Kategori") {
          setSearchParams({
            ...searchParams,
            categoryId: event.target.value,
          });
        } else {
          setSearchParams(omit(searchParams, ["categoryId"]));
        }
      }}
    >
      <option data-testid="category-option-kategori">Semua Kategori</option>
      {categories?.map(({ category, categoryId }) => (
        <option
          key={categoryId}
          data-testid="category-option-kategori"
          value={categoryId}
          selected={searchParams.categoryId == categoryId}
        >
          {category}
        </option>
      ))}
    </select>
  );
}
export default CoursesCategoryDropdown;
